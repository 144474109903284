import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadImage } from "../../../helpers";
import type {
	RespInfoCertifications,
	ResponseActivities,
	ResponseMaterials,
	ResponseWorks,
} from "../../../models";

export const getActivities = createAsyncThunk(
	"editInstallation/getActivities",
	async (meter: string) => {
		const { data } = await api.get<ResponseActivities>(
			`/field-service-activities/activities/?page=1&size=50&=measurement_type=${meter}`,
		);
		return data.items;
	},
);

export const getCategoriesMaterial = createAsyncThunk(
	"editInstallation/getCategoriesMaterial",
	async () => {
		const { data } = await api.get<ResponseMaterials>(
			`/inventory/inventories/?page=1&size=50`,
		);

		const filteredCategories: string[] = [];
		data.items.filter(({ categoría }) => {
			if (!filteredCategories.includes(categoría)) {
				filteredCategories.push(categoría);
				return true;
			}
			return false;
		});
		return filteredCategories;
	},
);

export const getWorks = createAsyncThunk(
	"editInstallation/getWorks",
	async () => {
		const { data } = await api.get<ResponseWorks>(`/field-service/works/`);
		return data.items;
	},
);

export const getFormData = createAsyncThunk(
	"editInstallation/getFormData",
	async ({ id, order_id }: { id: string; order_id: string }) => {
		const { data } = await api.get<RespInfoCertifications>(
			`service-orders-certificates/certificates?assignee_id=${id}&order_id=${order_id}`,
		);

		return data.items
			.filter(({ certificate_type }) => certificate_type.code !== "HAZRW")
			.slice(-1)[0];
	},
);

export const getDocument = createAsyncThunk(
	"editInstallation/getDocument",
	async ({ id, order_id }: { id: string; order_id: string }) => {
		const { data } = await api.get<RespInfoCertifications>(
			`service-orders-certificates/certificates?assignee_id=${id}&order_id=${order_id}`,
		);

		const document_url = data.items
			.filter(({ certificate_type }) => certificate_type.code !== "HAZRW")
			.slice(-1)[0].document_url;

		const url = await loadImage(document_url ?? "");
		return url;
	},
);
