import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataSealsForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const SealsForm = ({ xs, fieldName }: DynamicFieldData) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: fieldName,
	});

	const addSeals = () => {
		append({
			...(["main_meter_retired", "optional_meter_retired"].some((name) =>
				fieldName.includes(name),
			) && {
				serial_number: "",
			}),
			...([
				"main_meter_installed",
				"terminals_installed",
				"optional_meter_installed",
				"voltage_transformer_installed",
				"electrical_boxes_installed",
				"current_transformer_installed",
			].some((name) => fieldName.includes(name)) && {
				serial_number: {
					value: "",
					picture_url: "",
				},
			}),
			...([
				"optional_meter_installed",
				"main_meter_installed",
				"main_meter_retired",
			].some((name) => fieldName.includes(name)) && {
				location: "",
			}),
			color: "",
			ownership: "",
			type_: "",
			action: "",
		});
	};

	const [openDeleteSeal, setOpenDeleteSeal] = useState(false);
	const handleOpenDeleteSeal = () => setOpenDeleteSeal(true);
	const handleCloseDeleteSeal = () => setOpenDeleteSeal(false);
	const removeSeal = (index: number) => {
		remove(index);
	};

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldsSeal = dataSealsForm(`${fieldName}.${index}`);

				return (
					<Grid key={item.id} item container xs={xs}>
						<Box width="100%" className="box-certification-edit" p={4}>
							<Grid item width="100%">
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography sx={{ fontWeight: "bold", color: "#53358E" }}>
										Sello {index + 1}
									</Typography>
									<Button
										variant="contained"
										className="delete-button"
										onClick={handleOpenDeleteSeal}
									>
										<DeleteIcon />
									</Button>
									<DeleteItemModal
										open={openDeleteSeal}
										handleClose={handleCloseDeleteSeal}
										deleteItem={removeSeal}
										index={index}
										name="el sello"
									/>
								</Box>
							</Grid>
							<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
								{fieldsSeal.map((d, i) => {
									return <DynamicControl {...d} key={i.toString()} />;
								})}
							</Grid>
						</Box>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={xs}>
				<Button onClick={addSeals} variant="outlined" startIcon={<AddIcon />}>
					Agregar sello
				</Button>
			</Grid>
		</Grid>
	);
};
