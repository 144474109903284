import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Typography,
} from "@enerbit/base";
import { useFormContext } from "react-hook-form";
import type { DynamicFieldData } from "../../../../../models";

export const ItemCheckGroup = ({
	lg,
	md,
	xs,
	fieldName,
	label,
	options = [],
}: DynamicFieldData) => {
	const { watch, setValue } = useFormContext();
	const currentData = watch(fieldName);

	const onChange = (value: string | number) => {
		if (currentData.includes(value)) {
			return setValue(
				fieldName,
				currentData.filter((s: string) => s !== value),
				{ shouldValidate: true },
			);
		}
		return setValue(fieldName, [...currentData, value], {
			shouldValidate: true,
		});
	};

	return (
		<Grid item md={md} xs={xs} lg={lg}>
			<Typography className="title-input-edition">{label}</Typography>
			<FormGroup row>
				{options.map(({ value, label }, index) => (
					<Grid item md={6} xs={12} lg={4} key={index}>
						<FormControlLabel
							label={label}
							control={
								<Checkbox
									checked={watch(fieldName)?.includes(label)}
									sx={{
										"&.Mui-checked": {
											color: "#FF7705",
										},
									}}
								/>
							}
							onChange={() => onChange(value as string | number)}
						/>
					</Grid>
				))}
			</FormGroup>
		</Grid>
	);
};
