import type { StepperFormData } from "../../../../models";

export const dataComercialData: StepperFormData = {
	name: "comercial_data",
	title: "Información comercial",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "feasibility_number",
			inputType: "number",
			label: "Número de factibilidad",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "project_name",
			inputType: "text",
			label: "Nombre del proyecto",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "municipality",
			inputType: "text",
			label: "Municipio",
			required: true,
		},
		{
			xl: 6,
			xs: 12,
			fieldName: "network_operator",
			inputType: "text",
			label: "Operador de red",
			required: true,
		},
		{
			xl: 6,
			xs: 12,
			fieldName: "or_reference_number",
			inputType: "text",
			label: "Número de radicado del OR",
			required: true,
		},
	],
};
