import type { DynamicFieldData, StepperFormData } from "../../../../models";

export const dataElectricalConductorsRetired: (
	meter: string,
) => StepperFormData = (meter) => {
	return {
		name: "electrical_conductors_retired",
		title: "Conductores retirados",
		items: [
			{
				md: 6,
				xs: 12,
				fieldName: `current_signal`,
				inputType: "conductor-form",
				label: "Conductores señal de corriente",
			},
			{
				...(meter === "indirecta" && {
					md: 6,
					xs: 12,
					fieldName: `voltage_signal`,
					inputType: "conductor-form",
					label: "Conductores señal de tensión",
				}),
			} as DynamicFieldData,
		],
	};
};
