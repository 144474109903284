import * as yup from "yup";

export const schemaDiagrams = yup.object().shape({
	connection_diagram: yup.object().shape({
		picture_url: yup.string().required("Este campo es obligatorio."),
	}),
	line_diagram: yup.object().shape({
		picture_url: yup.string().required("Este campo es obligatorio."),
	}),
});
