import type { StepperFormData } from "../../../../models";

export const dataTerminalsInstalled: StepperFormData = {
	name: "terminals_installed",
	title:
		"Bornera de prueba o elemento similar principal/otra encontrada/instalada",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "terminals_installed",
			inputType: "terminal-form",
			label:
				"Bornera de prueba o elemento similar principal/otra encontrada/instalada",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "terminals_retired",
			inputType: "switch-step",
			label: "¿Se retirará bornera?",
		},
	],
};
