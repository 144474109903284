import * as yup from "yup";

export const schemaGeneralData = yup.object().shape({
	power_transformer_serial_number: yup
		.string()
		.required("Este campo es obligatorio."),
	voltage_level: yup
		.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required("Este campo es obligatorio.")
		.min(0, "Debe ser un numero mayor o igual a cero."),
	power_capacity_contracted: yup.object().shape({
		value: yup
			.number()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Este campo es obligatorio.")
			.min(0, "Debe ser un numero mayor o igual a cero."),
		picture_url: yup.string().required("Este campo es obligatorio."),
	}),
	power_transformer_primary_voltage: yup
		.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required("Este campo es obligatorio.")
		.min(0, "Debe ser un numero mayor o igual a cero."),
	power_transformer_secundary_voltage: yup
		.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required("Este campo es obligatorio.")
		.min(0, "Debe ser un numero mayor o igual a cero."),
	power_transformer_phase_number: yup
		.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required("Este campo es obligatorio.")
		.min(0, "Debe ser un numero mayor o igual a cero."),
	power_transformer_usage: yup.string().required("Este campo es obligatorio."),
	power_transformer_node: yup.string().required("Este campo es obligatorio."),
	power_transformer_location: yup
		.string()
		.required("Este campo es obligatorio."),
	connection: yup.string().required("Este campo es obligatorio."),
	multiplier_factor: yup
		.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required("Este campo es obligatorio.")
		.min(0, "Debe ser un numero mayor o igual a cero."),
	power_transformer_feature_plate_picture: yup.object().shape({
		picture_url: yup.string().required("Este campo es obligatorio."),
	}),
	measurement_system_picture: yup.object().shape({
		picture_url: yup.string().required("Este campo es obligatorio."),
	}),
});
