import type { DynamicFieldData } from "../../../models";

export const dataTransformer = (
	newFieldName: string,
	r_phase: boolean,
	s_phase: boolean,
	t_phase: boolean,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.transformation_relationship`,
			inputType: "number",
			label: "Relación de transformación",
			required: true,
		},
		{
			...(r_phase && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.r_phase`,
				inputType: "phase-transform-form",
				label: "Fase R",
			}),
		} as DynamicFieldData,
		{
			...(s_phase && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.s_phase`,
				inputType: "phase-transform-form",
				label: "Fase S",
			}),
		} as DynamicFieldData,
		{
			...(t_phase && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.t_phase`,
				inputType: "phase-transform-form",
				label: "Fase T",
			}),
		} as DynamicFieldData,
	];
};
