import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataListCabinetForm } from "../../../../../helpers/edition/services/dataListCabinetForm";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const ListCabinetForm = ({ xs, fieldName }: DynamicFieldData) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: fieldName,
	});

	const addListCabinets = () => {
		append({});
	};

	const [openDeleteCabinet, setOpenDeleteCabinet] = useState(false);
	const handleOpenDeleteCabinet = () => setOpenDeleteCabinet(true);
	const handleCloseDeleteCabinet = () => setOpenDeleteCabinet(false);
	const removeCabinet = (index: number) => {
		remove(index);
	};

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldsCabinet = dataListCabinetForm(`${fieldName}.${index}`);

				return (
					<Grid key={item.id} item container xs={xs}>
						<Box width="100%" className="box-certification-edit" p={4}>
							<Grid item width="100%">
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography sx={{ fontWeight: "bold", color: "#53358E" }}>
										Gabinete {index + 1}
									</Typography>
									<Button
										variant="contained"
										className="delete-button"
										onClick={handleOpenDeleteCabinet}
									>
										<DeleteIcon />
									</Button>
									<DeleteItemModal
										open={openDeleteCabinet}
										handleClose={handleCloseDeleteCabinet}
										deleteItem={removeCabinet}
										index={index}
										name="el gabinete"
									/>
								</Box>
							</Grid>
							<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
								{fieldsCabinet.map((d, i) => {
									return <DynamicControl {...d} key={i.toString()} />;
								})}
							</Grid>
						</Box>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={12}>
				<Button
					fullWidth
					onClick={addListCabinets}
					variant="outlined"
					startIcon={<AddIcon />}
				>
					Agregar gabinete
				</Button>
			</Grid>
		</Grid>
	);
};
