import type { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import type { ControlType, DynamicFieldData } from "../../../../models";
import {
	ActivitiesForm,
	AnnexesForm,
	BoxesForm,
	ConductorsForm,
	ForUsForm,
	ImageGroup,
	ItemCheckGroup,
	ItemDatePicker,
	ItemFileImage,
	ItemGeo,
	ItemInput,
	ItemSealImage,
	ItemSelect,
	ItemSelectActivity,
	ItemSelectMaterial,
	ItemSwicthCheckGroup,
	ItemSwitch,
	ItemSwitchForm,
	ItemSwitchInput,
	ItemSwitchValidationTesting,
	ItemTextArea,
	ItemTitle,
	ListCabinetForm,
	MaterialsForm,
	PhaseForm,
	PhasesTransformerForm,
	SealsForm,
	SwitchCalibrationDate,
	SwitchPhaseCurrentForm,
	SwitchPhaseVoltageForm,
	SwitchStep,
	TerminalForm,
	TowersForm,
	TransformerForm,
} from "./items";
import { PrimaryNominalForm } from "./items/PrimaryNominalForm";

const inputTypeToComponent: Record<
	ControlType,
	(props: DynamicFieldData) => ReactElement | null
> = {
	text: ItemInput,
	number: ItemInput,
	select: ItemSelect,
	switch: ItemSwitch,
	"checkbox-group": ItemCheckGroup,
	geo: ItemGeo,
	"file-image": ItemFileImage,
	"seals-form": SealsForm,
	"seal-image": ItemSealImage,
	"boxes-form": BoxesForm,
	"image-group": ImageGroup,
	"for-us-form": ForUsForm,
	"text-area": ItemTextArea,
	"switch-input": ItemSwitchInput,
	"switch-form": ItemSwitchForm,
	"annexes-form": AnnexesForm,
	"switch-step": SwitchStep,
	"terminal-form": TerminalForm,
	date: ItemDatePicker,
	"switch-phase-current-form": SwitchPhaseCurrentForm,
	"switch-phase-voltage-form": SwitchPhaseVoltageForm,
	"phase-form": PhaseForm,
	"transformer-form": TransformerForm,
	"phase-transform-form": PhasesTransformerForm,
	"activity-form": ActivitiesForm,
	"select-activity": ItemSelectActivity,
	"material-form": MaterialsForm,
	"select-material": ItemSelectMaterial,
	"calibration-form": SwitchCalibrationDate,
	"conductor-form": ConductorsForm,
	"swicth-check-group": ItemSwicthCheckGroup,
	"primary-nominal": PrimaryNominalForm,
	title: ItemTitle,
	towers: TowersForm,
	list_cabinet: ListCabinetForm,
	"switch-validation-testing": ItemSwitchValidationTesting,
};

export const DynamicControl = (dynamicFieldData: DynamicFieldData) => {
	const { watch } = useFormContext();
	const { inputType, fieldName } = dynamicFieldData;

	if (watch(fieldName) === undefined && inputType === "title") {
		return <></>;
	}

	const renderComponent = inputTypeToComponent[inputType];

	return renderComponent ? renderComponent(dynamicFieldData) : <></>;
};
