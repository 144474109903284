import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataTerminalsForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const TerminalForm = ({ xs, fieldName }: DynamicFieldData) => {
	const { control, watch } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: fieldName.includes("installed")
			? "terminals_installed"
			: "terminals_retired",
	});

	const addTerminals = () => {
		append({
			...(fieldName.includes("installed") && {
				manufacturer: { value: "", picture_url: "" },
				seals: [],
				is_installed: "",
			}),
			type_: "",
			usage: "",
		});
	};

	const [openDeleteTerminal, setOpenDeleteTerminal] = useState(false);
	const handleOpenDeleteTerminal = () => setOpenDeleteTerminal(true);
	const handleCloseDeleteTerminal = () => setOpenDeleteTerminal(false);
	const removeTerminal = (index: number) => {
		remove(index);
	};

	const renderManufacturerField = (
		manufacturerFieldName: string,
		manufacturerValue: string | { value: string; picture_url: string },
	) => {

		if (typeof manufacturerValue === "string") {
			return (
				<DynamicControl
					xs={12}
					md={6}
					fieldName={manufacturerFieldName}
					inputType="text"
					label="Fabricante"
					required
				/>
			);
		}
		return (
			<>
				<DynamicControl
					xs={12}
					md={6}
					fieldName={`${manufacturerFieldName}.value`}
					inputType="text"
					label="Fabricante"
					required
				/>
				<DynamicControl
					xs={12}
					md={6}
					fieldName={`${manufacturerFieldName}.picture_url`}
					inputType="file-image"
					label="Fabricante"
					required
				/>
			</>
		);
	};

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldNameWithIndex = `${fieldName.includes("installed") ? "terminals_installed" : "terminals_retired"}.${index}`;
				const fieldsTerminal = dataTerminalsForm(fieldNameWithIndex);

				return (
					<Grid item xs={12} key={item.id}>
						<Box sx={{ borderRadius: "16px", border: "1px solid #53358E" }}>
							<Grid item width="100%">
								<Box
									sx={{
										backgroundColor: "#53358E",
										borderRadius: "16px 16px 0px 0px",
										px: 4,
										py: 2,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography sx={{ fontWeight: "bold", color: "white" }}>
										Bornera {index + 1}
									</Typography>
									<Button
										variant="contained"
										className="delete-button"
										onClick={handleOpenDeleteTerminal}
									>
										<DeleteIcon />
									</Button>
								</Box>
								<DeleteItemModal
									open={openDeleteTerminal}
									handleClose={handleCloseDeleteTerminal}
									deleteItem={removeTerminal}
									index={index}
									name="la bornera"
								/>
							</Grid>
							<Grid
								item
								container
								xs={xs}
								rowSpacing={3}
								columnSpacing={5}
								p={4}
							>
								{fieldsTerminal.map((d, i) => (
									<DynamicControl {...d} key={i.toString()} />
								))}
								{renderManufacturerField(
									`${fieldNameWithIndex}.manufacturer`,
									watch(`${fieldNameWithIndex}.manufacturer`),
								)}
								<Grid item xs={12}>
									<DynamicControl
										fieldName={`${fieldNameWithIndex}.seals`}
										inputType="seals-form"
										label="Sello de seguridad"
									/>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={xs}>
				<Button
					onClick={addTerminals}
					variant="outlined"
					startIcon={<AddIcon />}
				>
					Agregar bornera
				</Button>
			</Grid>
		</Grid>
	);
};
