import * as yup from "yup";

export const schemaElectricalBoxesInstalled = yup.array().of(
	yup.object().shape({
		is_installed: yup.string().required("Este campo es obligatorio."),
		usage: yup.string().required("Este campo es obligatorio."),
		manufacturer: yup.object().shape({
			value: yup.string().nullable().required("Este campo es obligatorio."),
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		manufacture_year: yup.object().shape({
			value: yup
				.number()
				.transform((value) => (isNaN(value) ? undefined : value))
				.required("Este campo es obligatorio.")
				.min(1000, "Debe ser un numero minimo de 4 digitos.")
				.max(9999, "Debe ser un numero maximo de 4 digitos.")
				.integer("Debe ser un numero entero."),
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		state: yup.string().required("Este campo es obligatorio."),
		seals: yup
			.array()
			.nullable()
			.of(
				yup.object().shape({
					serial_number: yup.object().shape({
						value: yup.string().required("Este campo es obligatorio."),
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
					type_: yup.string().required("Este campo es obligatorio."),
					color: yup.string().required("Este campo es obligatorio."),
					ownership: yup.string().required("Este campo es obligatorio."),
					action: yup.string().required("Este campo es obligatorio."),
				}),
			),
	}),
);
