import * as yup from "yup";

export const schemaMetersTechnicalTest = yup.object().shape({
	main_meter: yup.object().shape({
		instantaneus_data: yup.object().shape({
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		phase_diagram: yup.object().shape({
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		accuracy_active_energy: yup.object().shape({
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		accuracy_reactive_energy: yup.object().shape({
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		registry_active_energy: yup.object().shape({
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		registry_reactive_energy: yup.object().shape({
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
	}),
});
