import type { StepperFormData } from "../../../../models";

export const dataDiagrams: StepperFormData = {
	name: "diagrams",
	title: "Diagramas",
	items: [
		{
			xs: 12,
			fieldName: "connection_diagram.picture_url",
			inputType: "file-image",
			label: "Diagrama de conexión",
			required: true,
		},
		{
			xs: 12,
			fieldName: "line_diagram.picture_url",
			inputType: "file-image",
			label: "Diagrama unifilar",
			required: true,
		},
	],
};
