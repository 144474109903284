import type { DynamicFieldData } from "../../../models";
import {
	MeterSealActions,
	MeterSealLocations,
	Ownership,
	Type,
} from "./common";
import { enumOptions, enumOptionsLowerCase } from "./optionsForm";

export const dataSealsForm = (newFieldName: string): DynamicFieldData[] => {
	return [
		{
			...(["main_meter_retired", "optional_meter_retired"].some((name) =>
				newFieldName.includes(name),
			) && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.serial_number`,
				inputType: "text",
				label: "Numero de serie del sello de seguridad",
				required: true,
			}),
		} as DynamicFieldData,
		{
			...([
				"main_meter_installed",
				"terminals_installed",
				"optional_meter_installed",
				"voltage_transformer_installed",
				"electrical_boxes_installed",
				"current_transformer_installed",
			].some((name) => newFieldName.includes(name)) && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.serial_number.value`,
				inputType: "text",
				label: "Numero de serie del sello de seguridad",
				required: true,
			}),
		} as DynamicFieldData,
		{
			...([
				"optional_meter_installed",
				"main_meter_installed",
				"main_meter_retired",
			].some((name) => newFieldName.includes(name)) && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.location`,
				inputType: "select",
				label: `Ubicación del sello de seguridad`,
				options: enumOptions(MeterSealLocations),
				required: true,
			}),
		} as DynamicFieldData,
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.color`,
			inputType: "text",
			label: `Color del sello de seguridad`,
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.ownership`,
			inputType: "select",
			label: `Propiedad del sello de seguridad`,
			options: enumOptionsLowerCase(Ownership),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.type_`,
			inputType: "select",
			label: `Tipo de constructivo del sello de seguridad`,
			options: enumOptions(Type),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.action`,
			inputType: "select",
			label: `Movimiento del sello de seguridad`,
			options: enumOptions(MeterSealActions),
			required: true,
		},
		{
			...([
				"main_meter_installed",
				"terminals_installed",
				"optional_meter_installed",
				"voltage_transformer_installed",
				"electrical_boxes_installed",
				"current_transformer_installed",
			].some((name) => newFieldName.includes(name)) && {
				xs: 12,
				fieldName: `${newFieldName}.serial_number.picture_url`,
				inputType: "seal-image",
				label: `Serie del sello`,
				required: true,
			}),
		} as DynamicFieldData,
	];
};
