import type { DynamicFieldData } from "../../../models";

export const dataGroupImage = (newFieldName: string): DynamicFieldData[] => {
	return [
		{
			xs: 12,
			fieldName: `${newFieldName}.instantaneus_data.picture_url`,
			inputType: "file-image",
			label: "Datos instantáneos o multímetro",
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.phase_diagram.picture_url`,
			inputType: "file-image",
			label: "Diagrama fasorial",
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.accuracy_active_energy.picture_url`,
			inputType: "file-image",
			label: "Exactitud energía activa",
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.accuracy_reactive_energy.picture_url`,
			inputType: "file-image",
			label: "Exactitud energía reactiva",
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.registry_active_energy.picture_url`,
			inputType: "file-image",
			label: "Registro energía activa",
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.registry_reactive_energy.picture_url`,
			inputType: "file-image",
			label: "Registro energía reactiva",
		},
	];
};
