import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { dataIrregularities } from "../../../../helpers";
import { useAppSelector } from "../../../../store";
import {
	DataMainMeterInstalled,
	dataActivities,
	dataComercialData,
	dataCurrentTransformerInstalled,
	dataCurrentTransformerRetired,
	dataDiagrams,
	dataElectricalBoxesInstalled,
	dataElectricalBoxesRetired,
	dataElectricalConductorsInstalled,
	dataElectricalConductorsRetired,
	dataGeneralData,
	dataMainMeterComunicationsInstalled,
	dataMainMeterComunicationsRetired,
	dataMainMeterRetired,
	dataMaterials,
	dataMetersTechnicalTest,
	dataObservations,
	dataObservationsTR,
	dataOptionalMeterComunicationsInstalled,
	dataOptionalMeterComunicationsRetired,
	dataOptionalMeterInstalled,
	dataOptionalMeterRetired,
	dataPhotographicAnnexes,
	dataRequestTechnicalSupport,
	dataTechnicalValidationTesting,
	dataTerminalsInstalled,
	dataTerminalsRetired,
	dataTransformersTechnicalTest,
	dataVoltageTransformerInstalled,
	dataVoltageTransformerRetired,
} from "../../../../store/slices/editInstallation";
import { dataMeasurementSystemData } from "../../../../store/slices/editInstallation/data/dataMeasurementSystemData";
import { StepItem } from "../StepItem";

export const StepperContent = () => {
	const {
		certificationType,
		showMainMeterRetired,
		showCurrentTransformerInstalled,
		showCurrentTransformerRetired,
		showVoltageTransformerInstalled,
		showVoltageTransformerRetired,
		currentMeter,
		showElectricalConductorsInstalled,
		showElectricalConductorsRetired,
		showOptionalMeterInstalled,
		showOptionalMeterRetired,
		showElectricalBoxesRetired,
		showTerminalsInstalled,
		showTerminalsRetired,
		showMainMeterCommunicationsInstalled,
		showMainMeterComunicationsRetired,
		showTransformersTechnicalTest,
		showOptionalMeterComunicationsInstalled,
		showOptionalMeterComunicationsRetired,
	} = useAppSelector((state) => state.editInstallation);

	const { setValue, watch } = useFormContext();

	useEffect(() => {
		if (showElectricalConductorsRetired && currentMeter === "semidirecta") {
			setValue("electrical_conductors_retired.voltage_signal", null, {
				shouldValidate: true,
			});
		}
	}, [showElectricalConductorsRetired]);

	useEffect(() => {
		if (showTransformersTechnicalTest && currentMeter === "semidirecta") {
			setValue("transformers_technical_test.voltage_transformer", null, {
				shouldValidate: true,
			});
		}
	}, [showTransformersTechnicalTest]);

	useEffect(() => {
		if (
			showCurrentTransformerRetired &&
			!watch("current_transformer_retired")
		) {
			setValue("current_transformer_retired", null);
		} else if (
			showCurrentTransformerRetired &&
			watch("current_transformer_retired")
		) {
			if (
				!watch("current_transformer_retired.r_phase.manufacturer") &&
				!watch("current_transformer_retired.r_phase.serial_number.value")
			) {
				setValue("current_transformer_retired.r_phase", null);
			}
			if (
				!watch("current_transformer_retired.s_phase.manufacturer") &&
				!watch("current_transformer_retired.s_phase.serial_number.value")
			) {
				setValue("current_transformer_retired.s_phase", null);
			}
			if (
				!watch("current_transformer_retired.t_phase.manufacturer") &&
				!watch("current_transformer_retired.t_phase.serial_number.value")
			) {
				setValue("current_transformer_retired.t_phase", null);
			}
		}
	}, [showCurrentTransformerRetired, watch("current_transformer_retired")]);

	useEffect(() => {
		if (
			showVoltageTransformerRetired &&
			!watch("voltage_transformer_retired")
		) {
			setValue("voltage_transformer_retired", null);
		} else if (
			showVoltageTransformerRetired &&
			watch("voltage_transformer_retired")
		) {
			if (
				!watch("voltage_transformer_retired.r_phase.manufacturer") &&
				!watch("voltage_transformer_retired.r_phase.serial_number.value")
			) {
				setValue("voltage_transformer_retired.r_phase", null);
			}
			if (
				!watch("voltage_transformer_retired.s_phase.manufacturer") &&
				!watch("voltage_transformer_retired.s_phase.serial_number.value")
			) {
				setValue("voltage_transformer_retired.s_phase", null);
			}
			if (
				!watch("voltage_transformer_retired.t_phase.manufacturer") &&
				!watch("voltage_transformer_retired.t_phase.serial_number.value")
			) {
				setValue("voltage_transformer_retired.t_phase", null);
			}
		}
	}, [showVoltageTransformerRetired, watch("voltage_transformer_retired")]);

	return (
		<>
			{certificationType?.code !== "TECH_RECEIPT" ? (
				<>
					<StepItem index={0} stepperFormItem={dataRequestTechnicalSupport} />
					<StepItem index={1} stepperFormItem={dataGeneralData} />
					<StepItem index={2} stepperFormItem={DataMainMeterInstalled} />
					{showMainMeterRetired && (
						<StepItem index={3} stepperFormItem={dataMainMeterRetired} />
					)}
					{showOptionalMeterInstalled && (
						<StepItem index={4} stepperFormItem={dataOptionalMeterInstalled} />
					)}
					{showOptionalMeterRetired && (
						<StepItem index={5} stepperFormItem={dataOptionalMeterRetired} />
					)}
					{showCurrentTransformerInstalled && (
						<StepItem
							index={6}
							stepperFormItem={dataCurrentTransformerInstalled}
						/>
					)}
					{showCurrentTransformerRetired && (
						<StepItem
							index={7}
							stepperFormItem={dataCurrentTransformerRetired}
						/>
					)}
					{showVoltageTransformerInstalled && (
						<StepItem
							index={8}
							stepperFormItem={dataVoltageTransformerInstalled}
						/>
					)}
					{showVoltageTransformerRetired && (
						<StepItem
							index={9}
							stepperFormItem={dataVoltageTransformerRetired}
						/>
					)}
					{showElectricalConductorsInstalled && (
						<StepItem
							index={10}
							stepperFormItem={dataElectricalConductorsInstalled(currentMeter)}
						/>
					)}
					{showElectricalConductorsRetired && (
						<StepItem
							index={11}
							stepperFormItem={dataElectricalConductorsRetired(currentMeter)}
						/>
					)}
					<StepItem index={12} stepperFormItem={dataElectricalBoxesInstalled} />
					{showElectricalBoxesRetired && (
						<StepItem index={13} stepperFormItem={dataElectricalBoxesRetired} />
					)}
					{showTerminalsInstalled && (
						<StepItem index={14} stepperFormItem={dataTerminalsInstalled} />
					)}
					{showTerminalsRetired && (
						<StepItem index={15} stepperFormItem={dataTerminalsRetired} />
					)}
					{showMainMeterCommunicationsInstalled && (
						<StepItem
							index={16}
							stepperFormItem={dataMainMeterComunicationsInstalled}
						/>
					)}
					{showMainMeterComunicationsRetired && (
						<StepItem
							index={17}
							stepperFormItem={dataMainMeterComunicationsRetired}
						/>
					)}
					{showOptionalMeterComunicationsInstalled && (
						<StepItem
							index={18}
							stepperFormItem={dataOptionalMeterComunicationsInstalled}
						/>
					)}
					{showOptionalMeterComunicationsRetired && (
						<StepItem
							index={19}
							stepperFormItem={dataOptionalMeterComunicationsRetired}
						/>
					)}
					<StepItem index={20} stepperFormItem={dataObservations} />
					<StepItem index={21} stepperFormItem={dataMetersTechnicalTest} />
					{showTransformersTechnicalTest && (
						<StepItem
							index={22}
							stepperFormItem={dataTransformersTechnicalTest(currentMeter)}
						/>
					)}
					<StepItem index={23} stepperFormItem={dataActivities} />
					<StepItem index={24} stepperFormItem={dataMaterials} />
					<StepItem index={25} stepperFormItem={dataPhotographicAnnexes} />
					<StepItem index={26} stepperFormItem={dataDiagrams} />
					<StepItem index={27} stepperFormItem={dataIrregularities} />
				</>
			) : (
				<>
					<StepItem index={0} stepperFormItem={dataComercialData} />
					<StepItem index={1} stepperFormItem={dataMeasurementSystemData} />
					<StepItem
						index={2}
						stepperFormItem={dataTechnicalValidationTesting}
					/>
					<StepItem index={3} stepperFormItem={dataObservationsTR} />
				</>
			)}
		</>
	);
};
