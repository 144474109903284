import * as yup from "yup";

export const schemaMainMeterRetired = yup
	.object()
	.nullable()
	.shape({
		meter_serial: yup
			.object()
			.nullable()
			.shape({
				value: yup.string().nullable().required("Este campo es obligatorio."),
				picture_url: yup.string().required("Este campo es obligatorio."),
			}),
		meter_brand: yup.string().nullable().required("Este campo es obligatorio."),
		measurement_type: yup
			.string()
			.nullable()
			.required("Este campo es obligatorio."),
		active_energy_imported: yup
			.object()
			.nullable()
			.shape({
				value: yup
					.number()
					.nullable()
					.transform((value) => (isNaN(value) ? undefined : value))
					.min(0, "Debe ser un numero mayor o igual a cero.")
					.test(
						"requiredIf",
						"Este campo es obligatorio si existe la imagen.",
						function (value) {
							const calleValue = this.parent.picture_url;
							if (calleValue && calleValue.trim().length > 0) {
								return !!value;
							}
							return true;
						},
					),
				picture_url: yup
					.string()
					.nullable()
					.test(
						"requiredIf",
						"Este campo es obligatorio si existe el valor.",
						function (value) {
							const calleValue = this.parent.value;
							if (calleValue) {
								return !!value;
							}
							return true;
						},
					),
			}),
		active_energy_exported: yup
			.object()
			.nullable()
			.shape({
				value: yup
					.number()
					.nullable()
					.transform((value) => (isNaN(value) ? undefined : value))
					.min(0, "Debe ser un numero mayor o igual a cero.")
					.test(
						"requiredIf",
						"Este campo es obligatorio si existe la imagen.",
						function (value) {
							const calleValue = this.parent.picture_url;
							if (calleValue && calleValue.trim().length > 0) {
								return !!value;
							}
							return true;
						},
					),
				picture_url: yup
					.string()
					.nullable()
					.test(
						"requiredIf",
						"Este campo es obligatorio si existe el valor.",
						function (value) {
							const calleValue = this.parent.value;
							if (calleValue) {
								return !!value;
							}
							return true;
						},
					),
			}),
		reactive_energy_imported: yup
			.object()
			.nullable()
			.shape({
				value: yup
					.number()
					.nullable()
					.transform((value) => (isNaN(value) ? undefined : value))
					.min(0, "Debe ser un numero mayor o igual a cero.")
					.test(
						"requiredIf",
						"Este campo es obligatorio si existe la imagen.",
						function (value) {
							const calleValue = this.parent.picture_url;
							if (calleValue && calleValue.trim().length > 0) {
								return !!value;
							}
							return true;
						},
					),
				picture_url: yup
					.string()
					.nullable()
					.test(
						"requiredIf",
						"Este campo es obligatorio si existe el valor.",
						function (value) {
							const calleValue = this.parent.value;
							if (calleValue) {
								return !!value;
							}
							return true;
						},
					),
			}),
		reactive_energy_exported: yup
			.object()
			.nullable()
			.shape({
				value: yup
					.number()
					.nullable()
					.transform((value) => (isNaN(value) ? undefined : value))
					.min(0, "Debe ser un numero mayor o igual a cero.")
					.test(
						"requiredIf",
						"Este campo es obligatorio si existe la imagen.",
						function (value) {
							const calleValue = this.parent.picture_url;
							if (calleValue && calleValue.trim().length > 0) {
								return !!value;
							}
							return true;
						},
					),
				picture_url: yup
					.string()
					.nullable()
					.test(
						"requiredIf",
						"Este campo es obligatorio si existe el valor.",
						function (value) {
							const calleValue = this.parent.value;
							if (calleValue) {
								return !!value;
							}
							return true;
						},
					),
			}),
		ownership: yup.string().nullable().required("Este campo es obligatorio."),
		seals: yup.array().of(
			yup.object().shape({
				serial_number: yup.string().required("Este campo es obligatorio."),
				type_: yup.string().required("Este campo es obligatorio."),
				color: yup.string().required("Este campo es obligatorio."),
				location: yup.string().required("Este campo es obligatorio."),
				action: yup.string().required("Este campo es obligatorio."),
				ownership: yup.string().required("Este campo es obligatorio."),
			}),
		),
	});
