import * as yup from "yup";

export const schemaOptionalMeterIInstalled = yup
	.object()
	.nullable()
	.shape({
		is_installed: yup
			.string()
			.nullable()
			.required("Este campo es obligatorio."),
		meter_serial: yup.object().shape({
			value: yup.string().nullable().required("Este campo es obligatorio."),
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		usage: yup.string().nullable().required("Este campo es obligatorio."),
		meter_location: yup
			.string()
			.nullable()
			.required("Este campo es obligatorio."),
		ownership: yup.string().nullable().required("Este campo es obligatorio."),
		meter_brand: yup.string().nullable().required("Este campo es obligatorio."),
		meter_model: yup.string().nullable().required("Este campo es obligatorio."),
		manufacture_year: yup
			.number()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Este campo es obligatorio.")
			.min(1000, "Debe ser un numero minimo de 4 digitos.")
			.max(9999, "Debe ser un numero maximo de 4 digitos.")
			.integer("Debe ser un numero entero."),
		number_meter_elements: yup
			.string()
			.nullable()
			.required("Este campo es obligatorio."),
		number_connection_elements: yup
			.string()
			.nullable()
			.required("Este campo es obligatorio."),

		wires_count: yup
			.number()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Este campo es obligatorio.")
			.min(0, "Debe ser un numero mayor o igual a cero.")
			.integer("Debe ser un numero entero."),
		connection_phases_count: yup
			.number()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Este campo es obligatorio.")
			.min(0, "Debe ser un numero mayor o igual a cero.")
			.integer("Debe ser un numero entero."),
		active_class_index_percentage: yup
			.string()
			.nullable()
			.required("Este campo es obligatorio."),
		reactive_class_index_percentage: yup
			.string()
			.nullable()
			.required("Este campo es obligatorio."),
		calibration_date: yup
			.object()
			.nullable()
			.shape({
				value: yup.string().nullable().required("Este campo es obligatorio."),
				picture_url: yup.string().required("Este campo es obligatorio."),
			}),
		seals: yup.array().of(
			yup.object().shape({
				serial_number: yup.object().shape({
					value: yup.string().required("Este campo es obligatorio."),
					picture_url: yup.string().required("Este campo es obligatorio."),
				}),
				type_: yup.string().required("Este campo es obligatorio."),
				color: yup.string().required("Este campo es obligatorio."),
				location: yup.string().required("Este campo es obligatorio."),
				action: yup.string().required("Este campo es obligatorio."),
				ownership: yup.string().required("Este campo es obligatorio."),
			}),
		),
	});
