import type React from "react";
import { type ReactNode, createContext, useContext } from "react";

// Definición del tipo para el valor del contexto
interface IdContextType {
	id: string;
}

// Creación del contexto con un valor undefined inicialmente
const IdContext = createContext<IdContextType | undefined>(undefined);

// Props del proveedor, incluyendo 'id' como prop
interface IdProviderProps {
	children: ReactNode;
	id: string; // Asegurándose de que el id se pasa como prop al proveedor
}

// Componente proveedor
export const IdProvider: React.FC<IdProviderProps> = ({ children, id }) => {
	return <IdContext.Provider value={{ id }}>{children}</IdContext.Provider>;
};

// Hook personalizado para usar el contexto
export const useId = (): IdContextType => {
	const context = useContext(IdContext);
	if (context === undefined) {
		throw new Error("useId must be used within an IdProvider");
	}
	return context;
};
