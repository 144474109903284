import { FormControlLabel, Grid, Switch, Typography } from "@enerbit/base";
import type React from "react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import type { DynamicFieldData } from "../../../../../models";
import { useAppSelector } from "../../../../../store";
import { DynamicControl } from "../DynamicControl";

export const ItemSwitchForm = ({
	fieldName,
	label,
	additionalFields = [],
	certificationList,
	measurements,
	isNewService,
}: DynamicFieldData) => {
	const { setValue, getValues } = useFormContext();

	const currentMeter = useAppSelector(
		(state) => state.editInstallation.currentMeter,
	);

	const certificationCode = useAppSelector(
		(state) => state.editInstallation.certificationType?.code,
	);

	const orderType = useAppSelector((state) => state.editInstallation.orderType);

	const [checked, setChecked] = useState(false);

	const isDisplayable =
		!(
			certificationList && !certificationList.includes(certificationCode ?? "")
		) &&
		!(measurements && !measurements.includes(currentMeter ?? "")) &&
		!(
			isNewService &&
			["NSG", "NS", "NSDG", "NSDI"].includes(orderType?.code ?? "")
		);

	useEffect(() => {
		if (isDisplayable) {
			setChecked(!!getValues(fieldName));
		}
	}, [getValues, fieldName]);

	const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		setValue(fieldName, event.target.checked, { shouldValidate: true });
	};

	return isDisplayable ? (
		<Grid item xs={12}>
			<Typography className="title-input-edition">{label}</Typography>
			<FormControlLabel
				control={
					<Switch
						color="secondary"
						checked={checked}
						onChange={switchHandler}
					/>
				}
				label={checked ? "Si" : "No"}
			/>
			{checked && (
				<Grid container spacing={3}>
					{additionalFields.map((field, index) => (
						<DynamicControl key={index} {...field} />
					))}
				</Grid>
			)}
		</Grid>
	) : null;
};
