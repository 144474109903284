import {
	Box,
	Button,
	DeleteOutlineIcon,
	Grid,
	IconButton,
	RemoveRedEyeOutlinedIcon,
	Typography,
	UpdateIcon,
} from "@enerbit/base";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { get, useFormContext } from "react-hook-form";
import { Asterisk, StyledTooltip, errorAlert } from "../../../../../common";
import {
	loadImage,
	uploadImage,
} from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteImageModal, PreviewImageModal } from "../../Modals";

export const ItemSealImage = ({
	lg,
	md,
	xs,
	fieldName,
	required,
	label,
}: DynamicFieldData) => {
	const {
		watch,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useFormContext();
	const [previewImage, setPreviewImage] = useState("");

	const [openPreviewImage, setOpenPreviewImage] = useState(false);
	const handleOpenPreviewImage = async () => {
		if (typeof getValues(fieldName) === "string") {
			setPreviewImage(await loadImage(getValues(fieldName)));
			setOpenPreviewImage(true);
		} else {
			setPreviewImage(URL.createObjectURL(getValues(fieldName)));
			setOpenPreviewImage(true);
		}
	};
	const handleClosePreviewImage = () => setOpenPreviewImage(false);

	const [openDeleteImage, setOpenDeleteImage] = useState(false);
	const handleOpenDeleteImage = () => setOpenDeleteImage(true);
	const handleCloseDeleteImage = () => setOpenDeleteImage(false);
	const deleteImage = () => {
		setValue(fieldName, "", { shouldValidate: true });
	};

	const setImage = async (acceptedFile: File) => {
		const prevImage = getValues(fieldName);
		setOpenDeleteImage(false);
		setValue(fieldName, acceptedFile, { shouldValidate: true });

		await uploadImage(acceptedFile)
			.then((image) => {
				setValue(fieldName, image, { shouldValidate: true });
			})
			.catch(() => {
				errorAlert("No se pudo subir la imagen");
				setValue(fieldName, prevImage, { shouldValidate: true });
			});
	};

	return (
		<Grid item md={md} xs={xs} lg={lg}>
			{watch(fieldName) ? (
				<Grid container p={1} columnSpacing={2} className="container-img-seals">
					<Grid
						container
						item
						columnSpacing={2}
						xs={6}
						display="flex"
						alignItems="center"
					>
						<Box className="img-seals">IMG</Box>
						<Typography
							className="item-box-certification-edit"
							sx={{ fontSize: "16px", px: 1 }}
						>
							Imagen - {label}
							{required ? <Asterisk /> : ""}
						</Typography>
					</Grid>
					<Grid
						container
						item
						columnSpacing={1}
						xs={6}
						display="flex"
						justifyContent="end"
					>
						<Grid item>
							<StyledTooltip title="Haz click para visualizar la imagen">
								<IconButton onClick={handleOpenPreviewImage}>
									<RemoveRedEyeOutlinedIcon />
								</IconButton>
							</StyledTooltip>
							<PreviewImageModal
								open={openPreviewImage}
								handleClose={handleClosePreviewImage}
								previewImage={previewImage}
								label={label}
							/>
						</Grid>
						<Grid item>
							<Dropzone
								multiple={false}
								accept={{
									"image/*": [],
								}}
								onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
							>
								{({ getRootProps, getInputProps }) => (
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<StyledTooltip title="Haz click para actualizar la imagen">
											<IconButton>
												<UpdateIcon />
											</IconButton>
										</StyledTooltip>
									</div>
								)}
							</Dropzone>
						</Grid>
						<Grid item>
							<StyledTooltip title="Haz click para eliminar la imagen">
								<IconButton onClick={handleOpenDeleteImage}>
									<DeleteOutlineIcon />
								</IconButton>
							</StyledTooltip>
							<DeleteImageModal
								open={openDeleteImage}
								handleClose={handleCloseDeleteImage}
								deleteImage={deleteImage}
							/>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<>
					<Typography className="title-input-edition">
						Imagen - {label}
						{required ? <Asterisk /> : ""}
					</Typography>
					<Dropzone
						multiple={false}
						accept={{
							"image/*": [],
						}}
						onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
					>
						{({ getRootProps, getInputProps }) => (
							<Box>
								<div {...getRootProps({ className: "border-file" })}>
									<input {...getInputProps()} />
									<Grid
										p={4}
										container
										columnSpacing={2}
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<Grid item>
											<Typography>Arrastra aquí o</Typography>
										</Grid>
										<Grid item>
											<Button variant="contained" className="orange-button">
												Adjunte la imagen
											</Button>
										</Grid>
									</Grid>
								</div>
							</Box>
						)}
					</Dropzone>
					{!!get(errors, fieldName) && !isValid && (
						<Typography color="error" fontSize={"0.75rem"}>
							{get(errors, fieldName).message}
						</Typography>
					)}
				</>
			)}
		</Grid>
	);
};
