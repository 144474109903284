import * as yup from "yup";

export const schemaElectricalConductorsInstalled = yup
	.object()
	.nullable()
	.shape({
		is_installed: yup.string().required("Este campo es obligatorio."),
		voltage_signal: yup
			.object()
			.nullable()
			.shape({
				manufacturer: yup.string().required("Este campo es obligatorio."),
				gauge: yup.object().shape({
					value: yup.string().required("Este campo es obligatorio."),
					picture_url: yup.string().required("Este campo es obligatorio."),
				}),
				length: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero.")
					.integer("Debe ser un numero entero."),
				count: yup.string().required("Este campo es obligatorio."),
				material: yup.string().required("Este campo es obligatorio."),
				denomination: yup.string().required("Este campo es obligatorio."),
			}),
		current_signal: yup
			.object()
			.nullable()
			.shape({
				manufacturer: yup.string().required("Este campo es obligatorio."),
				gauge: yup.object().shape({
					value: yup.string().required("Este campo es obligatorio."),
					picture_url: yup.string().required("Este campo es obligatorio."),
				}),
				length: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero.")
					.integer("Debe ser un numero entero."),
				count: yup.string().required("Este campo es obligatorio."),
				material: yup.string().required("Este campo es obligatorio."),
				denomination: yup.string().required("Este campo es obligatorio."),
			}),
	});
