import {
	Box,
	Button,
	DeleteOutlineIcon,
	Grid,
	RemoveRedEyeOutlinedIcon,
	Typography,
	UpdateIcon,
} from "@enerbit/base";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { get, useFormContext } from "react-hook-form";
import { Asterisk, errorAlert } from "../../../../../common";
import {
	loadImage,
	uploadImage,
} from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteImageModal, PreviewImageModal } from "../../Modals";

export const ItemFileImage = ({
	fieldName,
	required,
	label,
}: DynamicFieldData) => {
	const {
		watch,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();
	const [previewImage, setPreviewImage] = useState("");
	const [openPreviewImage, setOpenPreviewImage] = useState(false);
	const handleOpenPreviewImage = async () => {
		if (typeof getValues(fieldName) === "string") {
			await loadImage(getValues(fieldName))
				.then((image) => {
					setPreviewImage(image);
					setOpenPreviewImage(true);
				})
				.catch(() => errorAlert("No se pudo cargar la imagen"));
		} else {
			setPreviewImage(URL.createObjectURL(getValues(fieldName)));
			setOpenPreviewImage(true);
		}
	};
	const handleClosePreviewImage = () => setOpenPreviewImage(false);

	const [openDeleteImage, setOpenDeleteImage] = useState(false);
	const handleOpenDeleteImage = () => setOpenDeleteImage(true);
	const handleCloseDeleteImage = () => setOpenDeleteImage(false);
	const deleteImage = () => {
		setValue(fieldName, "", { shouldValidate: true });
	};

	const setImage = async (acceptedFile: File) => {
		const prevImage = getValues(fieldName);
		setOpenDeleteImage(false);
		setValue(fieldName, acceptedFile, { shouldValidate: true });

		await uploadImage(acceptedFile)
			.then((image) => {
				setValue(fieldName, image, { shouldValidate: true });
			})
			.catch((error) => {
				console.log(error);
				errorAlert("No se pudo subir la imagen");
				setValue(fieldName, prevImage, { shouldValidate: true });
			});
	};

	return (
		<Grid item xs={12}>
			{watch(fieldName) ? (
				<Box width="100%" className="box-certification-edit">
					<Box width={"100%"} p={"16px 32px"}>
						<Grid container spacing={2}>
							<Grid item xs={4} xl={6} display="flex" alignItems="center">
								<Typography sx={{ fontWeight: "bold", color: "#53358E" }}>
									Imagen - {label}
									{required ? <Asterisk /> : ""}
								</Typography>
							</Grid>
							<Grid
								container
								xs={8}
								xl={6}
								item
								spacing={2}
								display="flex"
								justifyContent="end"
							>
								<Grid item>
									<Button
										variant="contained"
										startIcon={<RemoveRedEyeOutlinedIcon />}
										className="edit-button"
										onClick={handleOpenPreviewImage}
									>
										Ver foto
									</Button>
									<PreviewImageModal
										open={openPreviewImage}
										handleClose={handleClosePreviewImage}
										previewImage={previewImage}
										label={label}
									/>
								</Grid>
								<Grid item>
									<Dropzone
										multiple={false}
										accept={{
											"image/*": [],
										}}
										onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
									>
										{({ getRootProps, getInputProps }) => (
											<Box>
												<div {...getRootProps()}>
													<input {...getInputProps()} />
													<Button
														variant="contained"
														startIcon={<UpdateIcon />}
														className="orange-button"
													>
														Actualizar
													</Button>
												</div>
											</Box>
										)}
									</Dropzone>
								</Grid>
								<Grid item>
									<Button
										onClick={handleOpenDeleteImage}
										variant="contained"
										startIcon={<DeleteOutlineIcon />}
										className="delete-button"
									>
										Eliminar
									</Button>
									<DeleteImageModal
										open={openDeleteImage}
										handleClose={handleCloseDeleteImage}
										deleteImage={deleteImage}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Box>
			) : (
				<>
					<Typography className="title-input-edition">
						Imagen - {label}
						{required ? <Asterisk /> : ""}
					</Typography>
					<Dropzone
						multiple={false}
						accept={{
							"image/*": [],
						}}
						onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
					>
						{({ getRootProps, getInputProps }) => (
							<Box>
								<div {...getRootProps({ className: "border-file" })}>
									<input {...getInputProps()} />
									<Grid
										p={4}
										container
										columnSpacing={2}
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<Grid item>
											<Typography>Arrastra aquí o</Typography>
										</Grid>
										<Grid item>
											<Button variant="contained" className="orange-button">
												Adjunte la imagen
											</Button>
										</Grid>
									</Grid>
								</div>
							</Box>
						)}
					</Dropzone>
				</>
			)}
			{!!get(errors, fieldName) && (
				<Typography color="error" fontSize={"0.75rem"}>
					{get(errors, fieldName).message}
				</Typography>
			)}
		</Grid>
	);
};
