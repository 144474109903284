import { type FC, useEffect } from "react";
import type { ItemGetOrders, OrderEntityGetOrders } from "../../models/orders";
import { getFormData, resetForm } from "../../store";
import { useAppDispatch } from "../../store/store";
import { CertificationsMain } from "./components/Certifications";

interface Props {
	order: ItemGetOrders;
	mainLead: OrderEntityGetOrders;
}

export const Certifications: FC<Props> = ({ order, mainLead }) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(resetForm());
		dispatch(
			getFormData({
				id: mainLead.assignee_id,
				order_id: order.id,
			}),
		);
	}, []);

	return <CertificationsMain order={order} mainLead={mainLead} />;
};
