import type { StepperFormData } from "../../../../models/";

export const dataElectricalBoxesInstalled: StepperFormData = {
	name: "electrical_boxes_installed",
	title: "Paneles o cajas de seguridad encontradas/instaladas",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "",
			inputType: "boxes-form",
			label: "Paneles o caja de seguridad",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "electrical_boxes_retired",
			inputType: "switch-step",
			label: "Paneles o cajas de seguridad retiradas",
		},
	],
};
