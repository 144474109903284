import * as yup from "yup";

export const schemaMainMeterComunicationsRetired = yup
	.object()
	.nullable()
	.shape({
		serial_number: yup.object().shape({
			value: yup.string().nullable().required("Este campo es obligatorio."),
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		brand: yup.string().nullable().required("Este campo es obligatorio."),
	});
