import * as yup from "yup";

export const schemaRequestTechnicalSupport = yup.object().shape({
	minutes: yup
		.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.test(
			"minutes-validation",
			"Este campo es obligatorio y debe ser un número entero mayor o igual a cero.",
			function (value) {
				const { requested } = this.parent;

				if (requested) {
					if (value === undefined || value < 0 || !Number.isInteger(value)) {
						return false;
					}
				}

				return true;
			},
		),
	requested: yup.boolean(),
});
