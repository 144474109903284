import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataBoxesForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const BoxesForm = ({ xs, fieldName }: DynamicFieldData) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: fieldName.includes("installed")
			? "electrical_boxes_installed"
			: "electrical_boxes_retired",
	});

	const addBoxes = () => {
		append({
			...(fieldName.includes("installed") && {
				is_installed: "",
			}),
			usage: "",
			manufacturer: {
				value: "",
				picture_url: "",
			},
			...(fieldName.includes("installed") && {
				manufacture_year: {
					value: "",
					picture_url: "",
				},
			}),
			state: "",
			...(fieldName.includes("installed") && {
				seals: [],
			}),
		});
	};

	const [openDeleteBox, setOpenDeleteBox] = useState(false);
	const handleOpenDeleteBox = () => setOpenDeleteBox(true);
	const handleCloseDeleteBox = () => setOpenDeleteBox(false);
	const removeBox = (index: number) => {
		remove(index);
	};

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldsBox = dataBoxesForm(
					`${fieldName.includes("installed") ? "electrical_boxes_installed" : "electrical_boxes_retired"}.${index}`,
				);

				return (
					<Grid item xs={12} key={item.id}>
						<Box sx={{ borderRadius: "16px", border: "1px solid #53358E" }}>
							<Grid item width="100%">
								<Box
									sx={{
										backgroundColor: "#53358E",
										borderRadius: "16px 16px 0px 0px",
										px: 4,
										py: 2,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography sx={{ fontWeight: "bold", color: "white" }}>
										Caja {index + 1}
									</Typography>
									<Button
										variant="contained"
										className="delete-button"
										onClick={handleOpenDeleteBox}
									>
										<DeleteIcon />
									</Button>
								</Box>
							</Grid>
							<Grid
								item
								container
								xs={xs}
								rowSpacing={3}
								columnSpacing={5}
								p={4}
							>
								{fieldsBox.map((d, i) => {
									return <DynamicControl {...d} key={i} />;
								})}
							</Grid>
						</Box>
						<DeleteItemModal
							open={openDeleteBox}
							handleClose={handleCloseDeleteBox}
							deleteItem={removeBox}
							index={index}
							name="la caja"
						/>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={xs}>
				<Button onClick={addBoxes} variant="outlined" startIcon={<AddIcon />}>
					Agregar caja / Panel
				</Button>
			</Grid>
		</Grid>
	);
};
