import {
	CarrierOperatorItems,
	booleanOptionsInstalled,
	enumOptions,
} from "../../../../helpers";
import type { StepperFormData } from "../../../../models";

export const dataMainMeterComunicationsInstalled: StepperFormData = {
	name: "main_meter_comunications_installed",
	title: "Comunicaciones medidor principal encontrada/instalada",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "is_installed",
			inputType: "select",
			label: "El módem es:",
			options: booleanOptionsInstalled(),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "serial_number.value",
			inputType: "text",
			label: "Serie del módem",
			required: true,
			disabled: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "brand",
			inputType: "text",
			label: "Marca del módem",
			required: true,
		},
		{
			xs: 12,
			fieldName: "serial_number.picture_url",
			inputType: "file-image",
			label: "Serie del módem",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "imei",
			inputType: "text",
			label: "IMEI",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "operator",
			inputType: "select",
			label: "Operador",
			options: enumOptions(CarrierOperatorItems),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "network",
			inputType: "text",
			label: "Medio de comunicación",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "main_meter_comunications_retired",
			inputType: "switch-step",
			label: "¿Se retirará módem?",
		},
	],
};
