import type { StepperFormData } from "../../../../models";

export const dataRequestTechnicalSupport: StepperFormData = {
	name: "request_technical_support",
	title:
		"Derecho a solicitar la asesoría o participación de un técnico particular...",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "requested",
			inputType: "switch",
			label: "¿El subscriptor hace uso de su derecho?",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "minutes",
			inputType: "number",
			label: "Tiempo máximo para hacerse presente en el sitio",
			required: true,
		},
	],
};
