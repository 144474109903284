import { Box, Grid, Typography } from "@enerbit/base";
import { useEffect, useState } from "react";
import { dataGroupImage } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DynamicControl } from "../DynamicControl";

export const ImageGroup = ({ xs, fieldName, label }: DynamicFieldData) => {
	const [fieldsImage, setFieldsImage] = useState<DynamicFieldData[]>([]);

	useEffect(() => {
		setFieldsImage(dataGroupImage(fieldName));
	}, []);

	return (
		<Grid container item xs={xs}>
			<Grid item xs={xs}>
				<Box width="100%">
					<Typography className="title-input-edition" textAlign="center">
						{label}
					</Typography>
				</Box>
			</Grid>
			<Grid container item rowSpacing={3} columnSpacing={5}>
				{fieldsImage.map((d, i) => {
					return <DynamicControl {...d} key={i} />;
				})}
			</Grid>
		</Grid>
	);
};
