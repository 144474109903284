import type { DynamicFieldData } from "../../../models";
import { ElectricalBoxStates, ElectricalBoxUsage } from "./common";
import { booleanOptionsInstalled, enumOptions } from "./optionsForm";

export const dataBoxesForm = (newFieldName: string): DynamicFieldData[] => {
	return [
		{
			...(newFieldName.includes("electrical_boxes_installed") && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.is_installed`,
				inputType: "select",
				label: "La Caja/Panel es:",
				options: booleanOptionsInstalled(),
				required: true,
			}),
		} as DynamicFieldData,
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.usage`,
			inputType: "select",
			label: "Elemento al que corresponde",
			options: enumOptions(ElectricalBoxUsage),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.manufacturer.value`,
			inputType: "text",
			label: "Fabricante",
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.manufacturer.picture_url`,
			inputType: "file-image",
			label: "Fabricante",
			required: true,
		},
		{
			...(newFieldName.includes("electrical_boxes_installed") && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.manufacture_year.value`,
				inputType: "number",
				label: "Año de fabricación",
				required: true,
			}),
		} as DynamicFieldData,
		{
			...(newFieldName.includes("electrical_boxes_installed") && {
				xs: 12,
				fieldName: `${newFieldName}.manufacture_year.picture_url`,
				inputType: "file-image",
				label: "Año de fabricación",
				required: true,
			}),
		} as DynamicFieldData,
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.state`,
			inputType: "select",
			label: "Estado fisico visual de la caja",
			options: enumOptions(ElectricalBoxStates),
			required: true,
		},
		{
			...(newFieldName.includes("electrical_boxes_installed") && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.seals`,
				inputType: "seals-form",
				label: "Movimiento del sello de seguridad",
			}),
		} as DynamicFieldData,
	];
};
