import type { StepperFormData } from "../../../../models";

export const dataMetersTechnicalTest: StepperFormData = {
	name: "meters_technical_test",
	title: "Pruebas tecnicas medidores",
	items: [
		{
			xs: 12,
			fieldName: "main_meter",
			inputType: "image-group",
			label: "Medidor principal",
		},
	],
};
