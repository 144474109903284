import type { StepperFormData } from "../../../models";

export const dataIrregularities: StepperFormData = {
	name: "irregularities",
	title: "Irregularidades",
	items: [
		{
			fieldName: "use_other_than_contracted",
			inputType: "switch-form",
			label: "¿Uso distinto al contratado?",
			certificationList: ["normalization"],
			additionalFields: [
				{
					fieldName: "irregularities.use_other_than_contracted.picture_url",
					label:
						"Foto del exterior del predio donde se evidencie que corresponde a un negocio",
					inputType: "file-image",
				},
				{
					fieldName: "irregularities.use_other_than_contracted.local_name",
					label: "Nombre del local",
					inputType: "text",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.use_other_than_contracted.local_activity_economy",
					label: "Actividad  económica del local",
					inputType: "select",
					xs: 12,
					md: 6,
					options: [
						{
							label: "Comercial",
							value: "comercial",
						},
						{
							label: "Residencial",
							value: "residencial",
						},
					],
				},
				{
					fieldName: "irregularities.use_other_than_contracted.area_extension",
					label: "Extensión de área para cada uso",
					inputType: "title",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.use_other_than_contracted.area_extension",
					label: "Extensión de área para uso comercial (m2)",
					inputType: "number",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.use_other_than_contracted.census",
					label: "Censo de carga",
					inputType: "title",
					xs: 12,
					md: 6,
				},
				//load census
				{
					fieldName: "irregularities.use_other_than_contracted.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
					md: 6,
				},
			],
		},
		{
			fieldName: "load_increase",
			inputType: "switch-form",
			label: "¿Aumento de carga o capacidad instalada sin autorización?",
			xs: 12,
			certificationList: ["INST", "normalization"],
			additionalFields: [
				{
					fieldName: "irregularities.load_increase.picture_url",
					label:
						"Foto donde se evidencia la capacidad del transformador de potencia o la medición de la corriente según aplique",
					inputType: "file-image",
				},
				{
					fieldName: "irregularities.load_increase.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
		},
		{
			fieldName: "electric_connection",
			label: "¿Acometida derivada?",
			inputType: "switch-form",
			additionalFields: [
				{
					fieldName:
						"irregularities.electric_connection.current_electric_connection",
					label: "Corriente acometida derivada",
					inputType: "text",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.electric_connection.picture_url",
					label:
						"Foto de la derivación antes de retirar el cable o normalizar y foto de la medición de la corriente si la derivación tiene carga en uso",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				//load-census
				{
					fieldName: "irregularities.electric_connection.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "unauthorized_electric_connection",
			label: "¿Acometida no autorizada o servicio directo?",
			inputType: "switch-form",
			additionalFields: [
				{
					fieldName:
						"irregularities.unauthorized_electric_connection.direct_current",
					label: "Corriente acometida directa",
					inputType: "text",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.unauthorized_electric_connection.picture_url",
					label:
						"Foto que evidencie la acometida no autorizada y la medición de la corriente si hay carga en uso",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.unauthorized_electric_connection.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "multiplier_factor_error",
			label: "¿Error en el factor de liquidación externo?",
			inputType: "switch-form",
			isNewService: true,
			measurements: ["semidirecta", "indirecta"],
			additionalFields: [
				{
					fieldName: "irregularities.multiplier_factor_error.picture_url",
					label:
						"Foto a la placa de características de uno de los transformadores de corriente y uno de los transformadores de tensión (estos últimos si los hay)",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.multiplier_factor_error.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "unauthorized_reconnection",
			label: "¿Reconexión no autorizada?",
			inputType: "switch-form",
			certificationList: ["normalization"],
			additionalFields: [
				{
					fieldName: "irregularities.unauthorized_reconnection.picture_url",
					label:
						"Foto que evidencie la irregularidad si la conexión no se hizo a través del medidor",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},

				{
					fieldName: "irregularities.unauthorized_reconnection.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "meter_broken_seals",
			label:
				"¿Tapa principal del medidor con sellos de seguridad rotos, adulterados o retirados?",
			inputType: "switch-form",
			isNewService: true,
			certificationList: ["normalization", "INST"],
			additionalFields: [
				{
					fieldName: "irregularities.meter_broken_seals.picture_url",
					label:
						"Foto del porta sello de la tapa principal del medidor, donde se observe la serie del medidor",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.meter_broken_seals.serial",
					label: "Número del serial de la bolsa de seguridad",
					inputType: "number",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.meter_broken_seals.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "terminal_broken_seals",
			label:
				"¿Bornera de conexiones del medidor con sellos de seguridad rotos, adulterados o retirados?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			additionalFields: [
				{
					fieldName: "irregularities.terminal_broken_seals.picture_url",
					label:
						"Foto del porta sello o de los sellos de la tapa de la bornera de conexiones del medidor, antes de ser normalizados",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.terminal_broken_seals.serial",
					label: "Número del serial de la bolsa de seguridad",
					inputType: "number",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.terminal_broken_seals.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "test_block_broken_seals",
			label:
				"¿Bloque de pruebas con sellos de seguridad rotos, adulterados o retirados?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			measurements: ["semidirecta", "indirecta"],
			additionalFields: [
				{
					fieldName: "irregularities.test_block_broken_seals.picture_url",
					label:
						"Foto del porta sello o de los sellos de la tapa del bloque de conexiones, antes de ser normalizados",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.test_block_broken_seals.serial",
					label: "Número del serial de la bolsa de seguridad",
					inputType: "number",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.test_block_broken_seals.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "current_transformer_broken_seals",
			label:
				"¿Transformadores de corriente con sellos de seguridad rotos, adulterados o retirados?",
			inputType: "switch-form",
			certificationList: ["normalization", "INST"],
			isNewService: true,
			measurements: ["semidirecta", "indirecta"],
			additionalFields: [
				{
					fieldName:
						"irregularities.current_transformer_broken_seals.picture_url",
					label:
						"Foto del porta sello o de los sellos de la tapa de conexiones, antes de ser normalizados",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.current_transformer_broken_seals.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "voltage_transformer_broken_seals",
			label:
				"¿Transformadores de tensión con sellos de seguridad rotos, adulterados o retirados?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			measurements: ["indirecta"],
			isNewService: true,
			additionalFields: [
				{
					fieldName:
						"irregularities.voltage_transformer_broken_seals.picture_url",
					label:
						"Foto del porta sello o de los sellos de la tapa de conexiones, antes de ser normalizados",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.voltage_transformer_broken_seals.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "terminal_meter_connection_altered",
			label:
				"¿Bornera de conexiones del medidor con conexiones alteradas o dañadas?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			additionalFields: [
				{
					fieldName:
						"irregularities.terminal_meter_connection_altered.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.terminal_meter_connection_altered.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "meter_connection_terminal_bridge",
			label: "¿Bornera de conexiones del medidor con puente?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			additionalFields: [
				{
					fieldName:
						"irregularities.meter_connection_terminal_bridge.picture_url",
					label: "Foto que evidencie la irregularidad sin quitar el puente",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},

				{
					fieldName: "irregularities.meter_connection_terminal_bridge.serial",
					label: "Número del serial de la bolsa de seguridad",
					inputType: "number",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.meter_connection_terminal_bridge.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "block_test_connection_altered",
			label: "¿Bloque de pruebas con conexiones alteradas o dañadas?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			measurements: ["semidirecta", "indirecta"],
			additionalFields: [
				{
					fieldName: "irregularities.block_test_connection_altered.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.block_test_connection_altered.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "transformers_connection_altered",
			label: "¿Transformador de medida con conexiones alteradas o dañadas?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			measurements: ["semidirecta", "indirecta"],
			additionalFields: [
				{
					fieldName:
						"irregularities.transformers_connection_altered.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.transformers_connection_altered.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "bad_test_block",
			label:
				"¿Bloque de pruebas en mal estado, sulfatado, partido o fogoneado?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			measurements: ["semidirecta", "indirecta"],
			additionalFields: [
				{
					fieldName: "irregularities.bad_test_block.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.bad_test_block.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "damaged_main_cover",
			label: "¿Tapa principal del medidor dañada o perforada?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			additionalFields: [
				{
					fieldName: "irregularities.damaged_main_cover.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.damaged_main_cover.serial",
					label: "Número del serial de la bolsa de seguridad",
					inputType: "number",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.damaged_main_cover.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "meter_connection_terminal_block_without_cover",
			label: "¿Bornera de conexiones del medidor sin tapa?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			additionalFields: [
				{
					fieldName:
						"irregularities.meter_connection_terminal_block_without_cover.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.meter_connection_terminal_block_without_cover.serial",
					label: "Número del serial de la bolsa de seguridad",
					inputType: "number",
					xs: 12,
					md: 6,
				},
				{
					fieldName:
						"irregularities.meter_connection_terminal_block_without_cover.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "burnt_meter",
			label:
				"¿Medidor quemado o con bornera de conexiones sulfatada o fogoneada?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			additionalFields: [
				{
					fieldName: "irregularities.burnt_meter.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.burnt_meter.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "meter_reading_not_readable",
			label:
				"¿Lectura del medidor no legible por display del medidor apagado, con segmentos quemados o registro en mal estado?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			additionalFields: [
				{
					fieldName: "irregularities.meter_reading_not_readable.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.meter_reading_not_readable.serial",
					label: "Número del serial de la bolsa de seguridad",
					inputType: "number",
					xs: 12,
				},
				{
					fieldName: "irregularities.meter_reading_not_readable.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		{
			fieldName: "meter_with_moisture",
			label: "¿Medidor con humedad o partículas internas?",
			inputType: "switch-form",
			certificationList: ["INST", "normalization"],
			isNewService: true,
			additionalFields: [
				{
					fieldName: "irregularities.meter_with_moisture.picture_url",
					label: "Foto que evidencie la irregularidad",
					inputType: "file-image",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.meter_with_moisture.serial",
					label: "Número del serial de la bolsa de seguridad",
					inputType: "number",
					xs: 12,
					md: 6,
				},
				{
					fieldName: "irregularities.meter_with_moisture.observations",
					label: "Observaciones",
					inputType: "text-area",
					xs: 12,
				},
			],
			xs: 12,
		},
		// {
		//   fieldName: "braking_meter",
		//   label: "¿Medidor frenado o con pruebas técnicas no conformes?",
		//   inputType: "switch-form",
		//   additionalFields: [],
		//   xs: 12,
		// },
	],
};
