import * as yup from "yup";

export const schemaCurrentTransformerInstalled = yup
	.object()
	.nullable()
	.shape({
		is_installed: yup.string().required("Este campo es obligatorio."),
		location: yup.object().shape({
			value: yup.string().required("Este campo es obligatorio."),
			picture_url: yup.string().required("Este campo es obligatorio."),
		}),
		r_phase: yup
			.object()
			.nullable()
			.shape({
				serial_number: yup.object().shape({
					value: yup.string().nullable().required("Este campo es obligatorio."),
					picture_url: yup.string().required("Este campo es obligatorio."),
				}),
				manufacturer: yup.string().required("Este campo es obligatorio."),
				manufacture_year: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(1000, "Debe ser un numero minimo de 4 digitos.")
					.max(9999, "Debe ser un numero maximo de 4 digitos.")
					.integer("Debe ser un numero entero."),
				burden: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero."),
				transformation_relationship: yup
					.string()
					.required("Este campo es obligatorio."),
				is_exterior: yup.string().required("Este campo es obligatorio."),
				primary_nominal_current: yup.array(
					yup.object({
						value: yup
							.number()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(1, "Debe ser un numero mayor o igual a uno."),
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
				),
				secundary_nominal_current: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero."),
				calibration_certificate: yup.object().shape({
					first_relation_calibration_stamp: yup.object().shape({
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
					second_relation_calibration_stamp: yup.object().shape({
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
					issue_date: yup
						.string()
						.nullable()
						.required("Este campo es obligatorio."),
					issuer: yup.string().required("Este campo es obligatorio."),
				}),
				percentage_exactitude_class: yup
					.string()
					.required("Este campo es obligatorio."),
				seals: yup
					.array()
					.nullable()
					.of(
						yup.object().shape({
							serial_number: yup.object().shape({
								value: yup.string().required("Este campo es obligatorio."),
								picture_url: yup
									.string()
									.required("Este campo es obligatorio."),
							}),
							type_: yup.string().required("Este campo es obligatorio."),
							color: yup.string().required("Este campo es obligatorio."),
							ownership: yup.string().required("Este campo es obligatorio."),
							action: yup.string().required("Este campo es obligatorio."),
						}),
					),
			}),
		s_phase: yup
			.object()
			.nullable()
			.shape({
				serial_number: yup.object().shape({
					value: yup.string().nullable().required("Este campo es obligatorio."),
					picture_url: yup.string().required("Este campo es obligatorio."),
				}),
				manufacturer: yup.string().required("Este campo es obligatorio."),
				manufacture_year: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(1000, "Debe ser un numero minimo de 4 digitos.")
					.max(9999, "Debe ser un numero maximo de 4 digitos.")
					.integer("Debe ser un numero entero."),
				burden: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero."),
				transformation_relationship: yup
					.string()
					.required("Este campo es obligatorio."),
				is_exterior: yup.string().required("Este campo es obligatorio."),
				primary_nominal_current: yup.array(
					yup.object({
						value: yup
							.number()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(1, "Debe ser un numero mayor o igual a uno."),
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
				),
				secundary_nominal_current: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero."),
				calibration_certificate: yup.object().shape({
					first_relation_calibration_stamp: yup.object().shape({
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
					second_relation_calibration_stamp: yup.object().shape({
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
					issue_date: yup
						.string()
						.nullable()
						.required("Este campo es obligatorio."),
					issuer: yup.string().required("Este campo es obligatorio."),
				}),
				percentage_exactitude_class: yup
					.string()
					.required("Este campo es obligatorio."),
				seals: yup
					.array()
					.nullable()
					.of(
						yup.object().shape({
							serial_number: yup.object().shape({
								value: yup.string().required("Este campo es obligatorio."),
								picture_url: yup
									.string()
									.required("Este campo es obligatorio."),
							}),
							type_: yup.string().required("Este campo es obligatorio."),
							color: yup.string().required("Este campo es obligatorio."),
							ownership: yup.string().required("Este campo es obligatorio."),
							action: yup.string().required("Este campo es obligatorio."),
						}),
					),
			}),
		t_phase: yup
			.object()
			.nullable()
			.shape({
				serial_number: yup.object().shape({
					value: yup.string().nullable().required("Este campo es obligatorio."),
					picture_url: yup.string().required("Este campo es obligatorio."),
				}),
				manufacturer: yup.string().required("Este campo es obligatorio."),
				manufacture_year: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(1000, "Debe ser un numero minimo de 4 digitos.")
					.max(9999, "Debe ser un numero maximo de 4 digitos.")
					.integer("Debe ser un numero entero."),
				burden: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero."),
				transformation_relationship: yup
					.string()
					.required("Este campo es obligatorio."),
				is_exterior: yup.string().required("Este campo es obligatorio."),
				primary_nominal_current: yup.array(
					yup.object({
						value: yup
							.number()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(1, "Debe ser un numero mayor o igual a uno."),
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
				),
				secundary_nominal_current: yup
					.number()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero."),
				calibration_certificate: yup.object().shape({
					first_relation_calibration_stamp: yup.object().shape({
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
					second_relation_calibration_stamp: yup.object().shape({
						picture_url: yup.string().required("Este campo es obligatorio."),
					}),
					issue_date: yup
						.string()
						.nullable()
						.required("Este campo es obligatorio."),
					issuer: yup.string().required("Este campo es obligatorio."),
				}),
				percentage_exactitude_class: yup
					.string()
					.required("Este campo es obligatorio."),
				seals: yup
					.array()
					.nullable()
					.of(
						yup.object().shape({
							serial_number: yup.object().shape({
								value: yup.string().required("Este campo es obligatorio."),
								picture_url: yup
									.string()
									.required("Este campo es obligatorio."),
							}),
							type_: yup.string().required("Este campo es obligatorio."),
							color: yup.string().required("Este campo es obligatorio."),
							ownership: yup.string().required("Este campo es obligatorio."),
							action: yup.string().required("Este campo es obligatorio."),
						}),
					),
			}),
	});
