import {
	TransformerLocationNames,
	booleanOptionsInstalled,
	enumOptions,
} from "../../../../helpers/edition/services";
import type { StepperFormData } from "../../../../models";

export const dataVoltageTransformerInstalled: StepperFormData = {
	name: "voltage_transformer_installed",
	title: "Transformadores de tensión(TT) encontrados/instalados",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "is_installed",
			inputType: "select",
			label: "El transformador es:",
			options: booleanOptionsInstalled(),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "location.value",
			inputType: "select",
			label: "Ubicación de los transformadores de tensión",
			options: enumOptions(TransformerLocationNames),
		},
		{
			xs: 12,
			fieldName: "location.picture_url",
			inputType: "file-image",
			label: "Ubicación del transformador pt",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "voltage_external_liquidation_factor",
			inputType: "number",
			label: "Factor de liquidación externo tensión",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "r_phase",
			inputType: "switch-phase-voltage-form",
			label: "¿Existe/Se instalará transformador en la fase R?",
			subLabel: "Fase R",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "s_phase",
			inputType: "switch-phase-voltage-form",
			label: "¿Existe/Se instalará transformador en la fase S?",
			subLabel: "Fase S",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "t_phase",
			inputType: "switch-phase-voltage-form",
			label: "¿Existe/Se instalará transformador en la fase T?",
			subLabel: "Fase T",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "voltage_transformer_retired",
			inputType: "switch-step",
			label: "¿Se retirará transformador de tensión?",
		},
	],
};
