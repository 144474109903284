import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataActivitiesForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import type { IActivity } from "../../../../../models/infoCertifications";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const ActivitiesForm = ({ xs }: DynamicFieldData) => {
	const { control, watch, setValue } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: "activities",
	});

	const addActivities = () => {
		append({
			name: "",
			charge: 0,
		});
	};

	const [openDeleteActivity, setOpenDeleteActivity] = useState(false);
	const handleOpenDeleteActivity = () => setOpenDeleteActivity(true);
	const handleCloseDeleteActivity = () => setOpenDeleteActivity(false);
	const removeActivity = (index: number) => {
		remove(index);
	};

	const updateActivitiesCharge = useCallback(
		(activities: IActivity[]) => {
			const activities_charge = activities.reduce(
				(acumulador, actual) => acumulador + actual.charge,
				0,
			);
			setValue("summary.activities_charge", activities_charge, {
				shouldValidate: true,
			});
		},
		[setValue],
	);

	useEffect(() => {
		const subscription = watch((_value, { name }) => {
			if (name?.includes(".charge") && name.includes("activities.")) {
				const activities = watch("activities");
				updateActivitiesCharge(activities);
			} else if (name === "activities") {
				if (watch(name).length === 0) {
					setValue("summary.activities_charge", 0, { shouldValidate: true });
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, updateActivitiesCharge]);

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldsActivity = dataActivitiesForm(`activities.${index}`);

				return (
					<Grid item xs={12} key={item.id}>
						<Box sx={{ borderRadius: "16px", border: "1px solid #53358E" }}>
							<Grid item width="100%">
								<Box
									sx={{
										backgroundColor: "#53358E",
										borderRadius: "16px 16px 0px 0px",
										px: 4,
										py: 2,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography sx={{ fontWeight: "bold", color: "white" }}>
										Actividad {index + 1}
									</Typography>
									<Button
										variant="contained"
										className="delete-button"
										onClick={handleOpenDeleteActivity}
									>
										<DeleteIcon />
									</Button>
									<DeleteItemModal
										open={openDeleteActivity}
										handleClose={handleCloseDeleteActivity}
										deleteItem={removeActivity}
										index={index}
										name="la actividad"
									/>
								</Box>
							</Grid>
							<Grid
								item
								container
								xs={xs}
								rowSpacing={3}
								columnSpacing={5}
								p={4}
							>
								{fieldsActivity.map((d, i) => {
									return <DynamicControl {...d} key={i} />;
								})}
							</Grid>
						</Box>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={xs}>
				<Button
					onClick={addActivities}
					variant="outlined"
					startIcon={<AddIcon />}
				>
					Agregar actividades
				</Button>
			</Grid>
		</Grid>
	);
};
