/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
import * as yup from "yup";

export const schemaVoltageTransformerRetired = yup
	.object()
	.nullable()
	.shape({
		r_phase: yup
			.object()
			.nullable()
			.shape({
				serial_number: yup.object().shape({
					value: yup
						.string()
						.nullable()
						.test(
							"requiredIf",
							"Este campo es obligatorio si otro campo de la fase existe.",
							function (value) {
								const isValidate =
									this.parent.picture_url || this.options.context?.manufacturer;
								if (isValidate) {
									return !!value;
								}
								return true;
							},
						),
					picture_url: yup
						.string()
						.test(
							"requiredIf",
							"Este campo es obligatorio si otro campo de la fase existe.",
							function (value) {
								const isValidate =
									this.parent.value || this.options.context?.manufacturer;

								if (isValidate) {
									return !!value;
								}
								return true;
							},
						),
				}),
				manufacturer: yup
					.string()
					.nullable()
					.test(
						"requiredIf",
						"Este campo es obligatorio si otro campo de la fase existe.",
						function (value) {
							const isValidate =
								this.parent.serial_number.value ||
								this.parent.serial_number.picture_url;
							if (isValidate) {
								return !!value;
							}
							return true;
						},
					),
			}),
		s_phase: yup
			.object()
			.nullable()
			.shape({
				serial_number: yup.object().shape({
					value: yup
						.string()
						.nullable()
						.test(
							"requiredIf",
							"Este campo es obligatorio si otro campo de la fase existe.",
							function (value) {
								const isValidate =
									this.parent.picture_url || this.options.context?.manufacturer;
								if (isValidate) {
									return !!value;
								}
								return true;
							},
						),
					picture_url: yup
						.string()
						.test(
							"requiredIf",
							"Este campo es obligatorio si otro campo de la fase existe.",
							function (value) {
								const isValidate =
									this.parent.value || this.options.context?.manufacturer;

								if (isValidate) {
									return !!value;
								}
								return true;
							},
						),
				}),
				manufacturer: yup
					.string()
					.nullable()
					.test(
						"requiredIf",
						"Este campo es obligatorio si otro campo de la fase existe.",
						function (value) {
							const isValidate =
								this.parent.serial_number.value ||
								this.parent.serial_number.picture_url;
							if (isValidate) {
								return !!value;
							}
							return true;
						},
					),
			}),
		t_phase: yup
			.object()
			.nullable()
			.shape({
				serial_number: yup.object().shape({
					value: yup
						.string()
						.nullable()
						.test(
							"requiredIf",
							"Este campo es obligatorio si otro campo de la fase existe.",
							function (value) {
								const isValidate =
									this.parent.picture_url || this.options.context?.manufacturer;
								if (isValidate) {
									return !!value;
								}
								return true;
							},
						),
					picture_url: yup
						.string()
						.test(
							"requiredIf",
							"Este campo es obligatorio si otro campo de la fase existe.",
							function (value) {
								const isValidate =
									this.parent.value || this.options.context?.manufacturer;

								if (isValidate) {
									return !!value;
								}
								return true;
							},
						),
				}),
				manufacturer: yup
					.string()
					.nullable()
					.test(
						"requiredIf",
						"Este campo es obligatorio si otro campo de la fase existe.",
						function (value) {
							const isValidate =
								this.parent.serial_number.value ||
								this.parent.serial_number.picture_url;
							if (isValidate) {
								return !!value;
							}
							return true;
						},
					),
			}),
	});
