import {
	CarrierOperatorItems,
	booleanOptionsInstalled,
	enumOptions,
} from "../../../../helpers/edition/services";
import type { StepperFormData } from "../../../../models";

export const dataOptionalMeterComunicationsInstalled: StepperFormData = {
	name: "optional_meter_comunications_installed",
	title: "Comunicaciones medidor de respaldo/otro medidor encontrada/instalada",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "is_installed",
			inputType: "select",
			label: "El medidor es:",
			options: booleanOptionsInstalled(),
		},
		{
			md: 6,
			xs: 12,
			fieldName: "serial_number.value",
			inputType: "number",
			label: "Serie del módem",
			required: true,
			disabled: true,
		},
		{
			xs: 12,
			fieldName: "serial_number.picture_url",
			inputType: "file-image",
			label: "Serie del módem",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "brand",
			inputType: "text",
			label: "Marca módem",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "imei",
			inputType: "text",
			label: "IMEI",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "operator",
			inputType: "select",
			label: "Operador",
			options: enumOptions(CarrierOperatorItems),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "network",
			inputType: "text",
			label: "Medio de comunicación",
			required: true,
		},
	],
};
