import * as yup from "yup";

export const schemaOptionalMeterComunicationsRetired = yup
	.object()
	.nullable()
	.shape({
		serial_number: yup
			.object()
			.nullable()
			.shape({
				value: yup.string().nullable().required("Este campo es obligatorio."),
				picture_url: yup
					.string()
					.nullable()
					.required("Este campo es obligatorio."),
			}),
		brand: yup.string().nullable().required("Este campo es obligatorio."),
	});
