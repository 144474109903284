import { Grid } from "@enerbit/base";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
	type KeyOfSuspension,
	dataForUsForm,
	suspensionMechanismOption,
} from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { useAppSelector } from "../../../../../store";
import { DynamicControl } from "../DynamicControl";

export const ForUsForm = ({ lg, md, xs, fieldName }: DynamicFieldData) => {
	const { watch } = useFormContext();

	const [forUsItems, setForUsItems] = useState<DynamicFieldData[]>([]);
	const { certificationType, currentMeter } = useAppSelector(
		(state) => state.editInstallation,
	);

	const isArray = Array.isArray(watch(`${fieldName}.suspension_mechanism`));
	const inputType = isArray ? "checkbox-group" : "text";

	const getCurrentMeterOptions = (meterType: string) => {
		return suspensionMechanismOption[meterType as KeyOfSuspension] || [];
	};

	const currentMeterOptions = getCurrentMeterOptions(currentMeter ?? "");

	useEffect(() => {
		setForUsItems(dataForUsForm(fieldName, certificationType?.code ?? ""));
	}, []);

	return (
		<Grid
			container
			item
			md={md}
			xs={xs}
			lg={lg}
			rowSpacing={3}
			columnSpacing={5}
		>
			{/*For us*/}
			<Grid item md={md} xs={xs} lg={lg}>
				<DynamicControl
					xs={xs}
					fieldName={`${fieldName}.other`}
					inputType={"text-area"}
					label={"Observaciones para nosotros"}
				/>
			</Grid>
			<Grid container item rowSpacing={3} columnSpacing={5}>
				<DynamicControl
					xs={12}
					fieldName={`${fieldName}.suspension_mechanism`}
					inputType={inputType}
					label={"Mecanismo de suspensión"}
					options={currentMeterOptions}
				/>
				{forUsItems.map((d, i) => {
					return <DynamicControl {...d} key={i} />;
				})}
			</Grid>
		</Grid>
	);
};
