import { Grid, InputLabel, TextField } from "@enerbit/base";
import { get, useFormContext } from "react-hook-form";
import type { DynamicFieldData } from "../../../../../models";

export const ItemInput = ({
	xl,
	lg,
	xs,
	md,
	inputType,
	fieldName,
	required,
	step,
	label,
	config = {},
	disabled,
}: DynamicFieldData) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	return (
		<Grid item md={md} xs={xs} lg={lg} xl={xl}>
			<InputLabel className="title-input-edition" required={required}>
				{label}
			</InputLabel>
			<TextField
				disabled={disabled}
				fullWidth
				{...register(fieldName, config)}
				sx={{ "& fieldset": { borderRadius: "14px" } }}
				variant="outlined"
				type={inputType}
				inputProps={{
					step: step ? `${step}` : "1",
				}}
				error={!!get(errors, fieldName)}
				helperText={!!get(errors, fieldName) && get(errors, fieldName).message}
			/>
		</Grid>
	);
};
