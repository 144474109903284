import {
	FormControlLabel,
	Grid,
	Switch,
	TextField,
	Typography,
} from "@enerbit/base";
import { type ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Asterisk } from "../../../../../common";
import type { DynamicFieldData } from "../../../../../models";

export const ItemSwitchValidationTesting = ({
	xs,
	inputType,
	fieldName,
	required,
	label,
	subLabel,
	config = {},
}: DynamicFieldData) => {
	const { register, setValue, getValues } = useFormContext();

	const isApprovedFieldName = `${fieldName}.is_approved`;
	const observationFieldName = `${fieldName}.observation`;

	const [checked, setChecked] = useState(false);

	useEffect(() => {
		setChecked(getValues(isApprovedFieldName));
	}, [getValues, isApprovedFieldName]);

	const switchHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		setChecked(isChecked);
		setValue(isApprovedFieldName, isChecked, { shouldValidate: true });
		if (isChecked) {
			setValue(observationFieldName, null);
		} else {
			setValue(observationFieldName, "");
		}
	};

	return (
		<Grid item xs={xs}>
			<Typography className="title-input-edition">{label}</Typography>
			<FormControlLabel
				control={
					<Switch
						color="secondary"
						checked={checked}
						onChange={switchHandler}
					/>
				}
				label={checked ? "Si" : "No"}
			/>
			{!checked && (
				<>
					<Typography className="title-input-edition">
						{subLabel}
						{required && <Asterisk />}
					</Typography>
					<TextField
						fullWidth
						{...register(observationFieldName, config)}
						sx={{ "& fieldset": { borderRadius: "14px" } }}
						variant="outlined"
						type={inputType}
					/>
				</>
			)}
		</Grid>
	);
};
