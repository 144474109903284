import type { DynamicFieldData } from "../../../models";
import { TerminalType, TerminalUsage } from "./common";
import { booleanOptionsInstalled, enumOptions } from "./optionsForm";

export const dataTerminalsForm = (newFieldName: string): DynamicFieldData[] => {
	return [
		{
			...(["terminals_installed"].some((name) =>
				newFieldName.includes(name),
			) && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.is_installed`,
				inputType: "select",
				label: "La bornera es:",
				options: booleanOptionsInstalled(),
				required: true,
			}),
		} as DynamicFieldData,
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.usage`,
			inputType: "select",
			label: "En que elemento fue instalada la bornera",
			options: enumOptions(TerminalUsage),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.type_`,
			inputType: "select",
			label: "Tipo bornera",
			options: enumOptions(TerminalType),
			required: true,
		},
	];
};
