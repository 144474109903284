import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
	enerbitColors,
} from "@enerbit/base";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataPrimaryNominal } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const PrimaryNominalForm = ({ xs, fieldName }: DynamicFieldData) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: fieldName,
	});

	const addPrimaryNominal = () => {
		append({
			value: 0,
			picture_url: "",
		});
	};

	const [openDeletePrimaryNominal, setOpenDeletePrimaryNominal] =
		useState(false);
	const handleOpenDeletePrimaryNominal = () =>
		setOpenDeletePrimaryNominal(true);
	const handleCloseDeletePrimaryNominal = () =>
		setOpenDeletePrimaryNominal(false);
	const removePrimaryNominal = (index: number) => {
		remove(index);
	};

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldsPrimaryNominal = dataPrimaryNominal(
					`${fieldName}.${index}`,
				);

				return (
					<Grid key={item.id} item container xs={xs}>
						<Box
							width="100%"
							sx={{
								borderRadius: "16px",
								border: `1px solid ${enerbitColors.primary.main}`,
							}}
							p={4}
						>
							<Grid item width="100%">
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography sx={{ fontWeight: "bold", color: "#53358E" }}>
										{fieldName.includes("current_transformer_installed")
											? "Corriente primaria nominal (A)"
											: "Tensión primaria nominal (A)"}
										{" - "}
										{`(${index + 1})`}
									</Typography>
									<Button
										variant="contained"
										className="delete-button"
										onClick={handleOpenDeletePrimaryNominal}
									>
										<DeleteIcon />
									</Button>
									<DeleteItemModal
										open={openDeletePrimaryNominal}
										handleClose={handleCloseDeletePrimaryNominal}
										deleteItem={removePrimaryNominal}
										index={index}
										name={
											fieldName.includes("current_transformer_installed")
												? "la corriente primaria nominal"
												: "la tensión primaria nominal"
										}
									/>
								</Box>
							</Grid>
							<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
								{fieldsPrimaryNominal.map((d, i) => {
									return <DynamicControl {...d} key={i.toString()} />;
								})}
							</Grid>
						</Box>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={xs}>
				<Button
					onClick={addPrimaryNominal}
					variant="outlined"
					startIcon={<AddIcon />}
				>
					{fieldName.includes("current_transformer_installed")
						? "Corriente primaria nominal"
						: "Tensión primaria nominal"}
				</Button>
			</Grid>
		</Grid>
	);
};
