import {
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@enerbit/base";
import { Controller, get, useFormContext } from "react-hook-form";
import type { DynamicFieldData } from "../../../../../models";

export const ItemSelect = ({
	lg,
	md,
	xs,
	fieldName,
	label,
	required,
	options = [],
	config = {},
	disabled,
}: DynamicFieldData) => {
	const {
		control,
		getValues,
		formState: { errors },
	} = useFormContext();

	return (
		<Grid item md={md} xs={xs} lg={lg}>
			<InputLabel className="title-input-edition" required={required}>
				{label}
			</InputLabel>
			<FormControl fullWidth>
				<Controller
					render={({ field }) => (
						<>
							<Select
								{...field}
								error={!!get(errors, fieldName)}
								disabled={disabled}
								sx={{ "& fieldset": { borderRadius: "14px" } }}
							>
								{options.map(({ label, value }, index) => (
									<MenuItem key={index} value={value as string | number}>
										{" "}
										{label}
									</MenuItem>
								))}
							</Select>
							{!!get(errors, fieldName) && (
								<FormHelperText error>
									{get(errors, fieldName).message}
								</FormHelperText>
							)}
						</>
					)}
					defaultValue={getValues(fieldName)}
					name={fieldName}
					control={control}
					rules={config}
				/>
			</FormControl>
		</Grid>
	);
};
