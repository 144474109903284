import type { StepperFormData } from "../../../../models";

export const dataCurrentTransformerRetired: StepperFormData = {
	name: "current_transformer_retired",
	title: "Transformadores de corriente(CT) retirados",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "r_phase",
			inputType: "phase-form",
			label: "Fase R",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "s_phase",
			inputType: "phase-form",
			label: "Fase S",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "t_phase",
			inputType: "phase-form",
			label: "Fase T",
		},
	],
};
