import { Box, Grid, Typography } from "@enerbit/base";
import { useEffect, useState } from "react";
import { dataPhasesTransformerForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DynamicControl } from "../DynamicControl";

export const PhasesTransformerForm = ({
	xs,
	fieldName,
	label,
}: DynamicFieldData) => {
	const [fieldsPhase, setFieldsPhase] = useState<DynamicFieldData[]>([]);

	useEffect(() => {
		setFieldsPhase(dataPhasesTransformerForm(fieldName));
	}, []);

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			<Grid item xs={12}>
				<Box width="100%" className="box-certification-edit" p={4}>
					<Grid item xs={12}>
						<Typography sx={{ fontWeight: "bold" }}>{label}</Typography>
					</Grid>
					<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
						{fieldsPhase.map((d, i) => {
							return <DynamicControl {...d} key={i} />;
						})}
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
};
