import type { StepperFormData } from "../../../../models";

export const dataElectricalBoxesRetired: StepperFormData = {
	name: "electrical_boxes_retired",
	title: "Panel o caja de seguridad retiradas",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "",
			inputType: "boxes-form",
			label: "Paneles o caja de seguridad",
		},
	],
};
