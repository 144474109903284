import { configureStore } from "@reduxjs/toolkit";
import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from "react-redux";
import { editInstallationSlice } from "./slices/editInstallation";

export const store = (id: string) => {
	return configureStore({
		reducer: {
			editInstallation: editInstallationSlice.reducer,
		},
	});
};

// Actualizando los tipos para usar el store dinámico
export type RootState = ReturnType<ReturnType<typeof store>["getState"]>;
export type AppDispatch = ReturnType<typeof store>["dispatch"];

// Hooks actualizados para usar el store dinámico
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
