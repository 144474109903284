import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
} from "@enerbit/base";
import type { FC } from "react";
import { StyledDialog, StyledDialogTitle } from "../../../../common";

interface Props {
	open: boolean;
	handleClose: () => void;
	deleteItem: (index: number) => void;
	name: string;
	index: number;
}

export const DeleteItemModal: FC<Props> = ({
	open,
	handleClose,
	deleteItem,
	name,
	index,
}) => {
	const handleCancel = () => {
		handleClose();
	};

	const handleAccept = () => {
		deleteItem(index);
		handleClose();
	};

	return (
		<StyledDialog
			open={open}
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			maxWidth="xs"
		>
			<StyledDialogTitle id="customized-dialog-title" onClose={handleCancel}>
				{`¿Está seguro que desea eliminar ${name}?`}
			</StyledDialogTitle>
			<DialogContent>
				<DialogContentText textAlign="center">
					Recuerde que una vez lo elimine, no podrá ser recuperado.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Volver</Button>
				<Button
					type="button"
					onClick={handleAccept}
					autoFocus
					variant="contained"
					className="orange-button"
				>
					Aceptar
				</Button>
			</DialogActions>
		</StyledDialog>
	);
};
