import { Tab, styled } from "@enerbit/base";

interface StyledTabProps {
	label: string | JSX.Element;
}

export const StyledTab = styled((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	color: "#344054",
	fontWeight: "400",
	textTransform: "capitalize",
	marginRight: theme.spacing(1),
	"&.Mui-selected": {
		color: "#101828",
		fontWeight: "700",
	},
	div: {
		height: "40px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "0px 12px",
		gap: "8px",
	},
	"&.Mui-selected div": {
		height: "40px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "0px 12px",
		gap: "8px",
		background: "rgba(27, 27, 30, 0.08)",
		borderRadius: "8px",
	},
}));
