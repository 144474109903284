export interface ConsultState {
	files: FilesModel[];
	leadId: string;
	consultForm: ConsultForm;
	isLoadingCertificates: boolean;
	error: boolean;
	empty: boolean;
	notFound: boolean;
	totalPages: number;
	currentPage: number;
}

export enum ConsultType {
	CLIENT = "Cliente",
	LEAD = "Lead",
}

export interface AdressModel {
	id: string;
	address: string;
	clientName: string;
}

export interface ConsultForm {
	type?: ConsultType;
	email?: string;
	address?: string;
	certificateType?: string;
}

export interface FilesModel {
	url: string;
	creationDate: string;
	hour: string;
	downloaded: boolean;
}

export enum CertificateEnum {
	INSTALLATION = "INST",
	NORMALIZATION = "NORM",
	TECH_RECEIPT = "TECH_RECEIPT",
}

export const MENU_ITEMS = [
	CertificateEnum.INSTALLATION,
	CertificateEnum.NORMALIZATION,
	CertificateEnum.TECH_RECEIPT,
];

export const CONSULT_TYPES = [ConsultType.LEAD, ConsultType.CLIENT];

export interface RequestCertificatesModel {
	id: string;
}

export interface CertificatesModel {
	document_url: string;
	created_at: string;
}

export interface ClientAddressModel {
	electricity_supply_services: ElectricitySupplyModel[];
}

export interface ElectricitySupplyModel {
	creg_subscriber: { frontier_id: string };
	estate: { address: string };
}

export interface LeadAddressesModel {
	id: string;
	address: string;
	display_name: string;
}
