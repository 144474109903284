import { Box, Grid, Typography } from "@enerbit/base";
import { useFormContext } from "react-hook-form";
import type { DynamicFieldData } from "../../../../../models";

export const ItemGeo = ({ lg, md, xs, fieldName }: DynamicFieldData) => {
	const { getValues } = useFormContext();

	return (
		<Grid item md={md} xs={xs} lg={lg}>
			<Box width="100%" className="box-certification-edit" p={4}>
				<Typography className="item-box-certification-edit" pb={1}>
					Coordenadas
				</Typography>
				{getValues(fieldName)?.altitude && (
					<Typography color="primary">
						<b>Altitud: </b>
						{getValues(fieldName)?.altitude}
					</Typography>
				)}
				<Typography color="primary">
					<b>Latitud: </b>
					{getValues(fieldName)?.latitude}
				</Typography>
				<Typography color="primary">
					<b>Longitud: </b>
					{getValues(fieldName)?.longitude}
				</Typography>
			</Box>
		</Grid>
	);
};
