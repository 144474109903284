import type { StepperFormData } from "../../../../models/dynamic-control-types";

export const dataMaterials: StepperFormData = {
	name: "materials",
	title: "Materiales ejecutados",
	items: [
		{
			xs: 12,
			md: 6,
			fieldName: "materials",
			inputType: "material-form",
			label: "Materiales",
		},
	],
};
