import type { DynamicFieldData } from "../../../models";

export const dataPrimaryNominal = (
	newFieldName: string,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.value`,
			inputType: "number",
			label: newFieldName.includes("current_transformer_installed")
				? "Corriente primaria nominal (A)"
				: "Tensión primaria nominal (A)",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.picture_url`,
			inputType: "file-image",
			label: newFieldName.includes("current_transformer_installed")
				? "Corriente primaria nominal (A)"
				: "Tensión primaria nominal (A)",
			required: true,
		},
	];
};
