import {
	Tooltip,
	type TooltipProps,
	styled,
	tooltipClasses,
} from "@enerbit/base";

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#53358E",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#53358E",
	},
}));
