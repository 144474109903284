import {
	Box,
	Button,
	CheckCircleIcon,
	CheckCircleOutlineIcon,
	ErrorIcon,
	Grid,
	Step,
	StepButton,
	StepContent,
	StepLabel,
	Typography,
} from "@enerbit/base";
import { type FC, useRef, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import { CertificateEnum } from "../../../../models/consult";
import type { StepperFormData } from "../../../../models/dynamic-control-types";
import { handleStepper } from "../../../../store/slices/editInstallation";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { DynamicControl } from "../SteppersContent";

interface Props {
	index: number;
	stepperFormItem: StepperFormData;
}

export const StepItem: FC<Props> = ({
	index,
	stepperFormItem: { name, title, items },
}) => {
	const {
		formState: { errors },
		trigger,
		watch,
	} = useFormContext();

	const { certificationType } = useAppSelector(
		(state) => state.editInstallation,
	);
	const dispatch = useAppDispatch();

	const [completed, setCompleted] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const validateSection = async (name: string, index: number) => {
		await trigger(name);
		if (!get(errors, name)) {
			if (ref.current) {
				ref.current.scrollIntoView({ block: "center" });
			}
			setCompleted(true);
			dispatch(handleStepper(index));
		} else {
			setCompleted(false);
		}
	};

	return (
		<Step index={index}>
			<StepLabel
				ref={ref}
				color="primary"
				onClick={() => dispatch(handleStepper(index))}
			>
				<StepButton
					icon={
						get(errors, name) ? (
							<ErrorIcon color="error" />
						) : completed ? (
							<CheckCircleIcon color="primary" />
						) : (
							<CheckCircleOutlineIcon color="primary" />
						)
					}
				>
					<Typography
						sx={{
							fontWeight: "bold",
							color: get(errors, name) ? "error.main" : "primary.main",
						}}
					>
						{title}
					</Typography>
				</StepButton>
			</StepLabel>
			<StepContent
				sx={{ pt: 3 }}
				TransitionProps={{
					unmountOnExit:
						certificationType?.code === CertificateEnum.INSTALLATION
							? !!watch(name)
							: true,
				}}
			>
				<Grid container rowSpacing={3} columnSpacing={5}>
					{items.map((d, i) => {
						const aux = { ...d, fieldName: `${name}.${d.fieldName}` };

						return <DynamicControl {...aux} key={i.toString()} />;
					})}
				</Grid>
				<Box pt={1}>
					<Button
						variant="contained"
						className="orange-button"
						onClick={() => validateSection(name, index)}
					>
						Continuar
					</Button>
				</Box>
			</StepContent>
		</Step>
	);
};
