import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
} from "@enerbit/base";
import type { FC } from "react";
import { StyledDialog, StyledDialogTitle } from "../../../../common";

interface Props {
	open: boolean;
	handleClose: () => void;
	deleteImage: () => void;
}

export const DeleteImageModal: FC<Props> = ({
	open,
	handleClose,
	deleteImage,
}) => {
	return (
		<StyledDialog
			open={open}
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			maxWidth="xs"
		>
			<StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
				{"¿Está seguro que desea eliminar la imagen?"}
			</StyledDialogTitle>
			<DialogContent>
				<DialogContentText textAlign="center" sx={{ fontSize: "15px" }}>
					Recuerde que una vez lo elimine, no podrá recuperarlo.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Volver</Button>
				<Button
					onClick={deleteImage}
					autoFocus
					variant="contained"
					className="orange-button"
				>
					Aceptar
				</Button>
			</DialogActions>
		</StyledDialog>
	);
};
