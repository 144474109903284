import type { DynamicFieldData } from "../../../models";

export const dataListCabinetForm = (
	newFieldName: string,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.cabinet_id`,
			inputType: "text",
			label: "Serie gabinete",
			required: true,
			disabled: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.meters_quantity`,
			inputType: "number",
			label: "Cantidad de medidores",
			required: true,
			disabled: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.flat`,
			inputType: "number",
			label: "Piso",
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.observation`,
			inputType: "text-area",
			label: "Observaciones",
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.cabinet_picture`,
			inputType: "seal-image",
			label: "Foto de gabinete",
			required: true,
		},
	];
};
