import {
	Box,
	Button,
	CircularProgress,
	Grid,
	RemoveRedEyeOutlinedIcon,
	Typography,
} from "@enerbit/base";
import { type FC, useEffect } from "react";
import type {
	ItemGetOrders,
	OrderEntityGetOrders,
} from "../../../../models/orders";
import {
	getDocument,
	setOrderType,
	useAppDispatch,
	useAppSelector,
} from "../../../../store";
import { StepperEdit } from "../ContentEditTab";
import { StepperSkeleton } from "../Stepper";

interface Props {
	order: ItemGetOrders;
	mainLead: OrderEntityGetOrders;
}

export const CertificationsMain: FC<Props> = ({ order, mainLead }) => {
	const { order_type } = order;

	const {
		loadingFormData,
		infoFormData,
		loadingDocument,
		assignee_id,
		order_id,
	} = useAppSelector((store) => store.editInstallation);
	const dispatch = useAppDispatch();

	const downloadDocument = async () => {
		const actionResult = await dispatch(
			getDocument({
				id: assignee_id,
				order_id: order_id,
			}),
		);

		const documentUrl = actionResult.payload as string; // Assuming the payload contains the URL

		if (documentUrl) {
			const link = document.createElement("a");
			link.href = documentUrl;
			link.target = "_blank"; // Open in new tab
			link.rel = "noopener noreferrer"; // Security reasons
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	useEffect(() => {
		dispatch(setOrderType(order_type));
	}, []);

	return (
		<>
			<Grid item xs={12}>
				{mainLead && mainLead.pii && (
					<Box className="box-certification-edit" p={4}>
						<Grid container rowSpacing={2} columnSpacing={4}>
							<Grid item xs={6}>
								<Typography fontWeight={"light"}>Cliente:</Typography>
								<Typography fontWeight={"bold"}>
									{mainLead.pii?.full_name}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography fontWeight={"light"}>Predio:</Typography>
								<Typography fontWeight={"bold"}>
									{mainLead.pii?.address}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Box display="flex" justifyContent="space-between">
									<Grid container columnSpacing={2}>
										<Grid item>
											<Box className="pdf-edit">PDF</Box>
										</Grid>
										<Grid item pt={1}>
											<Typography
												className="item-box-certification-edit"
												sx={{ fontSize: "16px" }}
											>
												{order_type.name}
											</Typography>
										</Grid>
									</Grid>
									<Box>
										<Button
											variant="contained"
											color="primary"
											onClick={downloadDocument}
											disabled={loadingDocument}
										>
											{loadingDocument ? (
												<CircularProgress size={20} color="inherit" />
											) : (
												<RemoveRedEyeOutlinedIcon />
											)}
										</Button>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				)}
			</Grid>
			{loadingFormData && order.order_type.code !== "AIV" ? (
				<StepperSkeleton />
			) : infoFormData && order.order_type.code !== "AIV" ? (
				<StepperEdit />
			) : (
				<></>
			)}
		</>
	);
};
