import type { StepperFormData } from "../../../../models/dynamic-control-types";

export const dataActivities: StepperFormData = {
	name: "activities",
	title: "Actividades ejecutadas",
	items: [
		{
			xs: 12,
			md: 6,
			fieldName: "activities",
			inputType: "activity-form",
			label: "Actividades",
		},
	],
};
