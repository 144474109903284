import type { StepperFormData } from "../../../../models";

export const dataObservations: StepperFormData = {
	name: "observations",
	title: "Observaciones",
	items: [
		{
			xs: 12,
			fieldName: "for_customer",
			inputType: "text-area",
			label:
				"Resumen de lo ejecutado, hallazgos relevantes,información sobre material retirado, etc",
			required: true,
		},
		{
			xs: 12,
			fieldName: "for_us",
			inputType: "for-us-form",
			label:
				"Comentarios de los técnicos que no se comparten con clientes y otros agentes. Por ejemplo: hablar de conexiones irregurales,tiempo estimado del cambio de comercializador cuando es una inspección previa, indicar si la siguiente actividad requiere o no suspensión del servicio, si se requiere acompañamiento del OR para una siguiente visita, etc",
		},
	],
};
