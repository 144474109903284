import type { StepperFormData } from "../../../../models";

export const dataTerminalsRetired: StepperFormData = {
	name: "terminals_retired",
	title: "Bornera de prueba o elemento similar principal/otra retirada",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "terminals_retired",
			inputType: "terminal-form",
			label: "Bornera de prueba o elemento similar principal/otra retirada",
		},
	],
};
