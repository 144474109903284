import { FormControlLabel, Grid, Switch, Typography } from "@enerbit/base";
import { type ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import type { DynamicFieldData } from "../../../../../models";
import {
	showHideBoxesRetired,
	showHideConductorRetired,
	showHideCurrentTransformerRetired,
	showHideMainMeterComunicationsRetired,
	showHideOptionalMeter,
	showHideTerminalRetired,
	showHideTransformersTechnicalTest,
	showHideVoltageTransformerRetired,
	showMainMeterRetired,
} from "../../../../../store/slices/editInstallation";

export const SwitchStep = ({ xs, fieldName, label }: DynamicFieldData) => {
	fieldName = fieldName.split(".").pop() || "";

	const dispatch = useDispatch();

	const { watch, setValue } = useFormContext();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const actions: any = {
		electrical_conductors_retired: showHideConductorRetired,
		terminals_retired: showHideTerminalRetired,
		main_meter_retired: showMainMeterRetired,
		optional_meter_installed: showHideOptionalMeter,
		electrical_boxes_retired: showHideBoxesRetired,
		main_meter_comunications_retired: showHideMainMeterComunicationsRetired,
		current_transformer_retired: showHideCurrentTransformerRetired,
		transformers_technical_test: showHideTransformersTechnicalTest,
		voltage_transformer_retired: showHideVoltageTransformerRetired,
	};
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		const fieldValue = !!watch(fieldName);
		setChecked(fieldValue);
	}, []);

	const switchHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		const action = actions[fieldName];
		if (action) {
			dispatch(action(event.target.checked));
			if (!event.target.checked) {
				setValue(fieldName, null, { shouldValidate: true });

				if (fieldName === "optional_meter_installed") {
					setValue("optional_meter_retired", null, { shouldValidate: true });
					setValue("optional_meter_comunications_installed", null, {
						shouldValidate: true,
					});
					setValue("optional_meter_comunications_retired", null, {
						shouldValidate: true,
					});
				}
			}
		}
	};

	return (
		<Grid item xs={xs}>
			<Typography className="title-input-edition">{label}</Typography>
			<FormControlLabel
				control={
					<Switch
						color="secondary"
						checked={checked}
						onChange={switchHandler}
					/>
				}
				label={checked ? "Si" : "No"}
			/>
		</Grid>
	);
};
