import { Grid, Typography } from "@enerbit/base";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { dataTransformer } from "../../../../../helpers/edition/services/dataTransformer";
import type { DynamicFieldData } from "../../../../../models";
import type { RootState } from "../../../../../store";
import { DynamicControl } from "../DynamicControl";

export const TransformerForm = ({ xs, fieldName, label }: DynamicFieldData) => {
	const { setValue } = useFormContext();
	const [fieldsTransformer, setFieldsTransformer] = useState<
		DynamicFieldData[]
	>([]);
	const { r_phase, s_phase, t_phase } = useSelector(
		(state: RootState) => state.editInstallation,
	);

	useEffect(() => {
		setFieldsTransformer(dataTransformer(fieldName, r_phase, s_phase, t_phase));
		if (!r_phase) {
			setValue(`${fieldName}.r_phase`, null, { shouldValidate: true });
		}
		if (!s_phase) {
			setValue(`${fieldName}.s_phase`, null, { shouldValidate: true });
		}
		if (!t_phase) {
			setValue(`${fieldName}.t_phase`, null, { shouldValidate: true });
		}
	}, [r_phase, s_phase, t_phase, fieldName]);

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			<Grid item xs={xs}>
				<Typography component="h4" variant="h4" textAlign="center">
					{label}
				</Typography>
			</Grid>
			<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
				{fieldsTransformer.map((d, i) => {
					return <DynamicControl {...d} key={i} />;
				})}
			</Grid>
		</Grid>
	);
};
