export const selectOptions = ["Lead", "Cliente"];

export enum NCMeasurementAccordance {
	Meter = "Medidor",
	CurrentTransformer = "Transformador_corriente",
	VoltageTransformer = "Transformador_tension",
	Conductors = "Conductores",
	BoxPanels = "Paneles_cajas",
	Terminal = "Bornera",
	Smartbit = "Smartbit",
}

export enum MeterSealLocations {
	MainCap = "Tapa principal",
	ConnectionTerminal = "Bornera de conexión",
	MaximumDemand = "Demanda máxima",
}

export enum MeterSealActions {
	Found = "Encontrado",
	Installed = "Instalado",
	Retired = "Retirado",
}

export enum Ownership {
	RF = "RF",
	OR = "OR",
	Lab = "Laboratorio",
}

export enum Type {
	Rotoseal = "Rotoseal",
	Anchor = "Ancla",
	Adhesive = "Adhesivo",
}

export enum ElectricalBoxUsage {
	MainMeterBox = "Celda o caja de medidor principal",
	BackupMeterCell = "Celda o caja de medidor respaldo/testigo",
	InstrumentTransformers = "Tranformadores de medida",
	OtherCells = "Otras celdas del sistema de medida",
}

export enum ElectricalBoxStates {
	Good = "Bueno",
	Bad = "Malo",
}

export enum Connection {
	SinglePhase = "Monofásica",
	Biphase = "Bifásica",
	ThreePhase = "Trifásica",
}

export enum PowerTransformerlocation {
	Sump = "Cárcamo",
	Cell = "Celda",
	Courtyard = "Patio",
	Pole = "Poste",
}

export enum PowerTransformerUsage {
	Shared = "Compartido",
	Exclusive = "Exclusivo",
}

export enum Change {
	TimeUpdate = "Actualización hora",
	BackupMeterChange = "Cambio de medidor de respaldo",
	MainMeterChange = "Cambio de medidor principal",
	ModemChange = "Cambio de  modem",
	CurrentTransformerChange = "Cambio de transformador de corriente",
	VoltageTransformerChange = "Cambio de transformador de tensión",
	initialResumeVerification = "Hoja de vida inicial-Verificación Inicial",
	OnsiteReading = "Lectura en sitio",
	ProgrammedMaintenance = "Mantenimiento programado",
	MeterParameterization = "Parametrización de medidor",
	VerificationVisit = "Visita de verificación",
	Other = "Otra",
}

export enum CarrierOperatorItems {
	Claro = "Claro",
	Movistar = "Movistar",
	Tigo = "Tigo",
	Other = "Otro",
}

export enum SocketOptions {
	SocketPhaseOne = "Toma fase 1",
	SocketPhaseTwo = "Toma fase 2",
	SocketPhaseThree = "Toma fase 3",
	NoSocket = "Sin toma",
}

export enum UsedElementOptions {
	One = "1",
	Two = "2",
	Three = "3",
	OneTwo = "1 y 2",
	TwoThree = "2 y 3",
	OneThree = "1 y 3",
	OneTwoThree = "1,2 y 3",
}

export enum ConnectionCorrespondenceOptions {
	Symmetric = "Simétrica",
	Asymmetric = "Asimétrica",
}

export enum MeterOwnershipOptions {
	User = "Usuario",
	RF = "RF",
	OR = "OR",
	OtherRF = "Otro RF",
}

export enum PowerClassIndexPercentage {
	One = "1",
	Two = "2",
	Three = "3",
	ZeroTow = "0,2S",
	ZeroFive = "0,5S",
}

export enum NumberMeterElements {
	OneE = "1E",
	TwoE = "2E",
	ThreeE = "3E",
}

export enum MeterLocation {
	ExternalSingleBox = "Caja individual externa",
	InternalSingleBox = "Caja individual interna",
	ExternalSharedCell = "Celda compartida externa",
	InternalSingleCell = "Celda individual interna",
	InternalSharedCabinet = "Gabinete compartido interno",
	ExternalSharedCabinet = "Gabinete compartido externo",
	Substation = "Subestación",
}

export enum Measurement_Type {
	Direct = "Directa",
	Indirect = "Indirecta",
	SemiDirect = "Semidirecta",
}

export enum NumberConnectionElements {
	OneE = "1E",
	OneOneTwoE = "1 1/2E",
	TwoE = "2E",
	TwoOneTwoE = "2 1/2E",
	TwoAron = "2 ARON",
	ThreeE = "3E",
}

export enum TransformerLocationNames {
	ExternalBox = "Caja externa",
	InternalBox = "Caja interna",
	ExternalCell = "Celda externa",
	InternalCell = "Celda interna",
	Facade = "Fachada",
	InternalCabinet = "Gabinete interno",
	ExternalCabinet = "Gabinete externo",
	NoPoleBox = "Sin caja poste",
	Substation = "Subestación",
}

export enum ConductorGaugeValues {
	Eight = "8",
	Ten = "10",
	Twelve = "12",
	Fourteen = "14",
}

export enum ConductorCounts {
	Three = "3",
	Six = "6",
	Seven = "7",
	Eight = "8",
	Nine = "9",
	Ten = "10",
}

export enum ConductorMaterials {
	Copper = "Cobre",
	Aluminum = "Alumnio",
}

export enum TerminalUsage {
	Main = "Principal",
	Witness = "Respaldo/testigo",
	Other = "Otras celdas",
}

export enum TerminalType {
	Blades = "Cuchillas",
	Bridge = "Puente",
	Other = "Otro",
	BridgeBaldes = "Cuchillas-puente",
}

export enum OptionalMeterUsage {
	Backup = "Respaldo",
	Witness = "Testigo",
}

export enum VoltageExactitudeClassOptions {
	ZeroTwo = "0,2",
	ZeroFive = "0,5",
	ZeroThree = "0,3",
}

export enum CurrentExactitudeClassOptions {
	ZeroTwoS = "0,2 S",
	ZeroThree = "0,3",
	ZeroFiveS = "0,5 S",
	ZeroFive = "0,5",
}

export enum MaterialUnit {
	Meter = "Metros",
	Unit = "Unidad",
	Roll = "Rollo",
	Game = "Juego",
	Pair = "Par",
	Kilos = "Kilos",
}

export enum ConductorDenominations {
	AWG = "AWG",
	MM2 = "mm2",
}

export enum CertificateType {
	INSTALLATION = "Acta de instalación",
	NORMALIZATION = "Acta de normalización",
}

export const suspensionMechanismOption = {
	directa: [
		{
			label: "Suspensión MT- Maniobra con tubos portafusibles",
			value: "suspension_mt_tubos_portafusibles",
		},
		{ label: "Suspensión remota", value: "suspension_remota" },
		{
			label: "Suspensión en bornera del medidor",
			value: "suspension_bornera_medidor",
		},
		{
			label: "Suspensión en punto de conexión de acometida - Red área",
			value: "suspension_conexion_acometida_red_area",
		},
		{
			label: "Suspensión en punto de conexión de acometida - Red subterránea",
			value: "suspension_conexion_acometida_red_subterranea",
		},
	],
	semidirecta: [
		{
			label: "Suspensión MT- Maniobra con tubos portafusiles",
			value: "suspension_mt_tubos_portafusibles",
		},
		{
			label: "Suspensión en elemento de corte - Totalizador eléctrico",
			value: "suspension_elemento_corte_totalizador_electrico",
		},
		{
			label: "Suspensión en punto de conexión de acometida - Red área",
			value: "suspension_conexion_acometida_red_area",
		},
		{
			label: "Suspensión en punto de conexión de acometida - Red subterránea",
			value: "suspension_conexion_acometida_red_subterranea",
		},
		{
			label: "Maniobra en seccionador MT - Celda de transformación",
			value: "maniobra_seccionador_mt_celda_transformacion",
		},
	],
	indirecta: [
		{
			label: "Suspensión MT- Maniobra con tubo portafusible",
			value: "suspension_mt_tubo_portafusible",
		},
		{
			label: "Maniobra en seccionador MT - Celda de transformación",
			value: "maniobra_seccionador_mt_celda_transformacion",
		},
		{
			label: "Apertura de puentes primarios",
			value: "apertura_puentes_primarios",
		},
		{ label: "Retiro de puentes primarios", value: "retiro_puentes_primarios" },
	],
};

export interface ISuspensionMechanism {
	directa: { label: string; value: string }[];
	semidirecta: { label: string; value: string }[];
	indirecta: { label: string; value: string }[];
}

export type KeyOfSuspension = keyof ISuspensionMechanism;
