import { Grid, Typography } from "@enerbit/base";
import { useEffect, useState } from "react";
import { dataConductorsForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DynamicControl } from "../DynamicControl";

export const ConductorsForm = ({ xs, fieldName, label }: DynamicFieldData) => {
	const [fieldsPhase, setFieldsPhase] = useState<DynamicFieldData[]>([]);

	useEffect(() => {
		setFieldsPhase(dataConductorsForm(fieldName));
	}, []);

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			<Grid item xs={xs}>
				<Typography component="h4" variant="h4" textAlign="center">
					{label}
				</Typography>
			</Grid>
			<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
				{fieldsPhase.map((d, i) => {
					return <DynamicControl {...d} key={i} />;
				})}
			</Grid>
		</Grid>
	);
};
