import { FormControlLabel, Grid, Switch, Typography } from "@enerbit/base";
import { Controller, useFormContext } from "react-hook-form";
import type { DynamicFieldData } from "../../../../../models";

export const ItemSwitch = ({
	lg,
	md,
	xs,
	fieldName,
	label,
}: DynamicFieldData) => {
	const { control } = useFormContext();

	return (
		<Grid item md={md} xs={xs} lg={lg}>
			<Typography className="title-input-edition">{label}</Typography>
			<Controller
				render={({ field: { onChange, value, ...field } }) => (
					<FormControlLabel
						control={
							<Switch
								{...field}
								color="secondary"
								onChange={onChange}
								checked={value}
							/>
						}
						label={value ? "Sí" : "No"}
					/>
				)}
				name={fieldName}
				control={control}
			/>
		</Grid>
	);
};
