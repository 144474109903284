import {
	Alert,
	Box,
	FormControlLabel,
	Grid,
	LoadingButton,
	Snackbar,
	Stepper,
	Switch,
	Typography,
} from "@enerbit/base";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import {
	Controller,
	FormProvider,
	type SubmitHandler,
	useForm,
} from "react-hook-form";
import { errorAlert } from "../../../../common";
import {
	schema,
	uploadFormData,
	uploadFormDataNormalization,
	uploadFormDataTechReceipt,
} from "../../../../helpers/edition/services";
import { schemaTechnicalReceipt } from "../../../../helpers/edition/services/schemas/schemaTechnicalReceipt";
import type { IFormData } from "../../../../models";
import { CertificateEnum } from "../../../../models/consult";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
	getCategoriesMaterial,
	loadForm,
} from "../../../../store/slices/editInstallation";
import { StepperContent } from "../SteppersContent";

export const StepperEdit = () => {
	const { activeStep, infoFormData, id, certificationType } = useAppSelector(
		(state) => state.editInstallation,
	);
	const dispatch = useAppDispatch();

	const formMethods = useForm({
		defaultValues: infoFormData,
		resolver: yupResolver(
			certificationType?.code !== CertificateEnum.TECH_RECEIPT
				? schema
				: schemaTechnicalReceipt,
		),
		mode: "onChange",
	});

	const {
		handleSubmit,
		getValues,
		control,
		formState: { errors },
	} = formMethods;
	const [loading, setLoading] = useState(false);

	console.log(errors)

	useEffect(() => {
		dispatch(loadForm(getValues("main_meter_installed.measurement_type")));
	}, []);

	// useEffect(() => {
	//   dispatch(getActivities(getValues("main_meter_installed.measurement_type")));
	// }, []);

	useEffect(() => {
		dispatch(getCategoriesMaterial());
	}, []);

	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (
		_event?: React.SyntheticEvent | Event,
		reason?: string,
	) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const onSubmit: SubmitHandler<IFormData> = (form) => {
		if (id) {
			setLoading(true);
			if (certificationType?.code === CertificateEnum.INSTALLATION) {
				uploadFormData(id, form)
					.then(() => {
						handleClick();
					})
					.catch(() => {
						errorAlert("No se pudo subir el formulario.");
					})
					.finally(() => {
						setLoading(false);
					});
			}
			if (certificationType?.code === CertificateEnum.NORMALIZATION) {
				uploadFormDataNormalization(id, form)
					.then(() => {
						handleClick();
					})
					.catch(() => {
						errorAlert("No se pudo subir el formulario.");
					})
					.finally(() => {
						setLoading(false);
					});
			}
			if (certificationType?.code === CertificateEnum.TECH_RECEIPT) {
				uploadFormDataTechReceipt(id, form)
					.then(() => {
						handleClick();
					})
					.catch(() => {
						errorAlert("No se pudo subir el formulario.");
					})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	};

	return (
		<FormProvider {...formMethods}>
			<Box pt={2} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<Stepper activeStep={activeStep} orientation="vertical">
					<StepperContent />
				</Stepper>
				{certificationType?.code !== CertificateEnum.TECH_RECEIPT && (
					<Grid container spacing={2} p={2}>
						<Grid item xs={6}>
							<Typography className="title-input-edition">
								¿Desea enviar copia al cliente?
							</Typography>
							<Controller
								render={({ field: { onChange, value, ...field } }) => (
									<FormControlLabel
										control={
											<Switch
												{...field}
												color="secondary"
												onChange={onChange}
												checked={value}
											/>
										}
										label={value ? "Sí" : "No"}
									/>
								)}
								name={"send_copy_customer"}
								control={control}
							/>
						</Grid>
						<Grid item xs={6}>
							<Typography className="title-input-edition">
								¿Desea enviar copia al OR?
							</Typography>
							<Controller
								render={({ field: { onChange, value, ...field } }) => (
									<FormControlLabel
										control={
											<Switch
												{...field}
												color="secondary"
												onChange={onChange}
												checked={value}
											/>
										}
										label={value ? "Sí" : "No"}
									/>
								)}
								name={"send_copy_OR"}
								control={control}
							/>
						</Grid>
					</Grid>
				)}

				<Box display="grid" pt={1} px={2}>
					<LoadingButton
						loading={loading}
						disabled={loading}
						type="submit"
						variant="contained"
						color="secondary"
					>
						Actualizar
					</LoadingButton>
					<Snackbar
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
						open={open}
						autoHideDuration={3000}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							variant="filled"
							severity="success"
							sx={{ width: "100%", color: "white" }}
						>
							Los cambios fueron guardados con éxito.
						</Alert>
					</Snackbar>
				</Box>
			</Box>
		</FormProvider>
	);
};
