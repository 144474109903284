/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	InputAdornment,
	MenuItem,
	Select,
	Typography,
	api,
} from "@enerbit/base";
import { useEffect, useRef, useState } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { Asterisk, errorAlert } from "../../../../../common";
import type {
	DynamicFieldData,
	ResponseMaterials,
} from "../../../../../models";
import { useAppSelector } from "../../../../../store";

export const ItemSelectMaterial = ({
	lg,
	md,
	xs,
	fieldName,
	required,
	label,
	parentFieldName,
}: DynamicFieldData) => {
	const {
		control,
		resetField,
		watch,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();
	const { categoriesMaterial, loadingMaterials } = useAppSelector(
		(state) => state.editInstallation,
	);
	const [materials, setMaterials] = useState<any[]>([
		{
			"Texto breve material": getValues(fieldName),
		},
	]);
	const [isLoading, setIsLoading] = useState(false);

	const isMounted = useRef(false);

	useEffect(() => {
		if (isMounted.current) {
			resetField(fieldName);
			if (parentFieldName && watch(parentFieldName)) {
				const timer = setTimeout(async () => {
					setIsLoading(true);
					try {
						const { data } = await api.get<ResponseMaterials>(
							`/inventory/inventories/?page=1&size=50&category=${watch(parentFieldName)}`,
						);
						setMaterials(data.items);
					} catch (error) {
						errorAlert("No se pudo encontrar los materiales.");
					}
					setIsLoading(false);
				}, 500);
				return () => clearTimeout(timer);
			}
		} else {
			isMounted.current = true;
		}
	}, [parentFieldName, watch(parentFieldName!)]);

	useEffect(() => {
		const subscription = watch((_value, { name }) => {
			if (name) {
				if (
					fieldName.includes(name) ||
					name.includes("quantity") ||
					name.includes("name")
				) {
					const split = fieldName.split(".");
					const getCharge =
						split.slice(0, split.length - 1).join(".") + ".charge";
					const getQuantity =
						split.slice(0, split.length - 1).join(".") + ".quantity";
					if (!watch(getCharge) || isNaN(watch(getCharge))) {
						setValue(getCharge, "0", { shouldValidate: true });
					} else if (watch(fieldName) && materials[0].valor_unitario_con_iva) {
						const valor_unitario_con_iva = materials[0].valor_unitario_con_iva;

						const charge = valor_unitario_con_iva * watch(getQuantity);
						setValue(getCharge, `${charge}`, { shouldValidate: true });
					}
					const total_charge =
						Math.floor(watch("summary.materials_charge")) +
						Math.floor(watch("summary.activities_charge"));
					setValue("summary.total_charge", total_charge, {
						shouldValidate: true,
					});
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, materials, getValues(fieldName)]);

	return (
		<Grid container item xs={xs} rowSpacing={1} columnSpacing={5}>
			<Grid item md={md} xs={xs} lg={lg}>
				<Typography className="title-input-edition">
					Categoría del material a utilizar
					{required && <Asterisk />}
				</Typography>
				<FormControl fullWidth>
					<Controller
						render={({ field }) => (
							<>
								<Select
									{...field}
									disabled={isLoading || loadingMaterials}
									error={!!get(errors, parentFieldName)}
									endAdornment={
										isLoading && (
											<InputAdornment position="end">
												<CircularProgress size={20} thickness={6} />
											</InputAdornment>
										)
									}
									sx={{ "& fieldset": { borderRadius: "14px" } }}
								>
									{categoriesMaterial.map((name, index) => (
										<MenuItem key={index} value={name}>
											{name}
										</MenuItem>
									))}
								</Select>
								{!!get(errors, parentFieldName) && (
									<FormHelperText error>
										{get(errors, parentFieldName).message}
									</FormHelperText>
								)}
							</>
						)}
						name={parentFieldName!}
						control={control}
					/>
				</FormControl>
			</Grid>
			<Grid item md={md} xs={xs} lg={lg}>
				<Typography className="title-input-edition">
					{label}
					{required && <Asterisk />}
				</Typography>
				<FormControl fullWidth>
					<Controller
						render={({ field }) => (
							<>
								<Select
									{...field}
									error={!!get(errors, fieldName)}
									disabled={isLoading || loadingMaterials}
									endAdornment={
										isLoading && (
											<InputAdornment position="end">
												<CircularProgress size={20} thickness={6} />
											</InputAdornment>
										)
									}
									sx={{ "& fieldset": { borderRadius: "14px" } }}
								>
									{materials.map((item, index) => {
										return (
											<MenuItem
												key={index}
												value={item["Texto breve material"]}
											>
												{item["Texto breve material"]}
											</MenuItem>
										);
									})}
								</Select>
								{!!get(errors, fieldName) && (
									<FormHelperText error>
										{get(errors, fieldName).message}
									</FormHelperText>
								)}
							</>
						)}
						name={fieldName}
						control={control}
					/>
				</FormControl>
			</Grid>
		</Grid>
	);
};
