import type { DynamicFieldData } from "../../../models";

export const dataSwitchCalibrationDate = (
	newFieldName: string,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.value`,
			inputType: "date",
			label: "Fecha de calibración",
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.picture_url`,
			inputType: "file-image",
			label: "Adhesivo de calibración del laboratorio",
			required: true,
		},
	];
};
