import type { StepperFormData } from "../../../../models";

export const dataTechnicalValidationTesting: StepperFormData = {
	name: "technical_validation_testing",
	title: "Validación técnica y pruebas",
	items: [
		{
			xs: 12,
			fieldName: "technical_verification_connection",
			inputType: "title",
			label: "¿Aprueba la verificación técnica de la acometida?",
			required: true,
		},
		{
			xs: 12,
			fieldName: "technical_verification_connection",
			inputType: "switch-validation-testing", // Nuevo tipo
			label: "Conforme",
			subLabel: "Observaciones",
			required: true,
		},
		{
			xs: 12,
			fieldName: "connection_equipment",
			inputType: "title",
			label: "¿Aprueba la verificación técnica de los equipos de conexión?",
			required: true,
		},
		{
			xs: 12,
			fieldName: "connection_equipment",
			inputType: "switch-validation-testing", // Nuevo tipo
			label: "Conforme",
			subLabel: "Observaciones",
			required: true,
		},
		{
			xs: 12,
			fieldName: "power_quality",
			inputType: "title",
			label: "¿Aprueba la verificación de la calidad de potencia?",
			required: true,
		},
		{
			xs: 12,
			fieldName: "power_quality",
			inputType: "switch-validation-testing", // Nuevo tipo
			label: "Conforme",
			subLabel: "Observaciones",
			required: true,
		},
		{
			xs: 12,
			fieldName: "required_maneuvers",
			inputType: "title",
			label: "¿Aprueba las pruebas y maniobras requeridas?",
			required: true,
		},
		{
			xs: 12,
			fieldName: "required_maneuvers",
			inputType: "switch-validation-testing", // Nuevo tipo
			label: "Conforme",
			subLabel: "Observaciones",
			required: true,
		},
		{
			xs: 12,
			fieldName: "homologation_test_protocols",
			inputType: "title",
			label: "¿Cumple homologación y/o protocolos de pruebas?",
			required: true,
		},
		{
			xs: 12,
			fieldName: "homologation_test_protocols",
			inputType: "switch-validation-testing", // Nuevo tipo
			label: "Conforme",
			subLabel: "Observaciones",
			required: true,
		},
		{
			xs: 12,
			fieldName: "connection",
			inputType: "title",
			label: "¿La conexión fue aprobada?",
			required: true,
		},
		{
			xs: 12,
			fieldName: "connection",
			inputType: "switch-validation-testing", // Nuevo tipo
			label: "Conforme",
			subLabel: "Observaciones",
			required: true,
		},
	],
};
