import { booleanOptionsInstalled } from "../../../../helpers/edition/services";
import type { DynamicFieldData, StepperFormData } from "../../../../models";

export const dataElectricalConductorsInstalled: (
	meter: string,
) => StepperFormData = (meter) => {
	return {
		name: "electrical_conductors_installed",
		title: "Conductores encontrados/instalado",
		items: [
			{
				md: 6,
				xs: 12,
				fieldName: "is_installed",
				inputType: "select",
				label: "El conductor es:",
				required: true,
				options: booleanOptionsInstalled(),
			},
			{
				md: 6,
				xs: 12,
				fieldName: `current_signal`,
				inputType: "conductor-form",
				label: "Conductores señal de corriente",
			},
			{
				...(meter === "indirecta" && {
					md: 6,
					xs: 12,
					fieldName: `voltage_signal`,
					inputType: "conductor-form",
					label: "Conductores señal de tensión",
				}),
			} as DynamicFieldData,
			{
				md: 6,
				xs: 12,
				fieldName: "electrical_conductors_retired",
				inputType: "switch-step",
				label: "¿Se retirará conductor?",
			},
		],
	};
};
