import type { StepperFormData } from "../../../../models";

export const dataOptionalMeterComunicationsRetired: StepperFormData = {
	name: "optional_meter_comunications_retired",
	title: "Comunicaciones medidor de respaldo/otro medidor retirada",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "serial_number.value",
			inputType: "text",
			label: "Serie medidor",
			required: true,
			disabled: true,
		},
		{
			xs: 12,
			fieldName: "serial_number.picture_url",
			inputType: "file-image",
			label: "Serie medidor",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "brand",
			inputType: "text",
			label: "Marca medidor",
			required: true,
		},
	],
};
