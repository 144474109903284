import type { DynamicFieldData } from "../../../models";
import { MaterialUnit } from "./common";
import { enumOptionsCapitalize } from "./optionsForm";

export const dataMaterialsForm = (newFieldName: string): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.name`,
			inputType: "select-material",
			label: "Descripción del material utilizado",
			required: true,
			parentFieldName: `${newFieldName}.category`,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.quantity`,
			inputType: "number",
			label: "Cantidad del material segun su unidad de medida",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.unit`,
			inputType: "select",
			label: "Unidad de medida del material utilizado",
			options: enumOptionsCapitalize(MaterialUnit),
			required: true,
		},
	];
};
