import type { DynamicFieldData } from "../../../models";
import { CurrentExactitudeClassOptions } from "./common";
import { booleanOptionsExterior, enumOptions } from "./optionsForm";

export const dataSwitchPhaseCurrentForm = (
	newFieldName: string,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.serial_number.value`,
			inputType: "text",
			label: "Serie del transformador",
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.serial_number.picture_url`,
			inputType: "file-image",
			label: "Serie del transformador de corriente",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.manufacturer`,
			inputType: "text",
			label: "Fabricante fase",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.manufacture_year`,
			inputType: "number",
			label: "Año de fabricación",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.burden`,
			inputType: "number",
			label: "Burden (VA) fase",
			step: 0.001,
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.percentage_exactitude_class`,
			inputType: "select",
			label: "Clase de exactitud (%)",
			options: enumOptions(CurrentExactitudeClassOptions),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.primary_nominal_current`,
			inputType: "primary-nominal",
			label: "Corriente primaria nominal (A)",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.secundary_nominal_current`,
			inputType: "number",
			label: "Corriente Secundaria nominal (A)",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.transformation_relationship`,
			inputType: "text",
			label: "Relación de transformación",
			required: true,
			disabled: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.is_exterior`,
			inputType: "select",
			label: "Tipo exterior o interior",
			options: booleanOptionsExterior(),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.calibration_certificate.issue_date`,
			inputType: "date",
			label: "Fecha de emisión certificado de calibración",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.calibration_certificate.issuer`,
			inputType: "text",
			label: "Ente emisor",
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.calibration_certificate.first_relation_calibration_stamp.picture_url`,
			inputType: "file-image",
			label: "Estampilla de calibración 1ra relación",
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.calibration_certificate.second_relation_calibration_stamp.picture_url`,
			inputType: "file-image",
			label: "Estampilla de calibración 2da relación",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.seals`,
			inputType: "seals-form",
			label: "Movimiento del sello de seguridad",
		},
	];
};
