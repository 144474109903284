import * as yup from "yup";

export const schemaOptionalMeterComunicationsInstalled = yup
	.object()
	.nullable()
	.shape({
		is_installed: yup.string().required("Este campo es obligatorio."),
		serial_number: yup
			.object()
			.nullable()
			.shape({
				value: yup.string().required("Este campo es obligatorio."),
				picture_url: yup.string().required("Este campo es obligatorio."),
			}),
		brand: yup.string().nullable().required("Este campo es obligatorio."),
		imei: yup.string().nullable().required("Este campo es obligatorio."),
		operator: yup.string().required("Este campo es obligatorio."),
		network: yup.string().nullable().required("Este campo es obligatorio."),
	});
