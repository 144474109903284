import { api } from "@enerbit/base";
import type { IFormData as apiData } from "../../../models";

export const loadImage = async (url_image: string) => {
	return await api
		.get<{ url: string }>(
			`/service-orders-certificates/authenticate-file?url_path=${url_image}`,
		)
		.then(({ data }) => data.url);
};

export const uploadImage = async (image: File) => {
	const bodyFormData = new FormData();
	bodyFormData.append("file", image);

	const { data } = await api.put<{ file_path: string }>(
		`/service-orders-certificates/upload-file`,
		bodyFormData,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
			transformRequest: () => {
				return bodyFormData;
			},
		},
	);

	return data.file_path;
};

export const uploadFormData = async (id: string, dataToUpdate: apiData) => {
	return await api
		.patch<apiData>(`/service-orders-certificates/initial-installation/${id}`, {
			form_data: dataToUpdate,
		})
		.then(({ data }) => data);
};

export const uploadFormDataNormalization = async (
	id: string,
	dataToUpdate: apiData,
) => {
	return await api
		.patch<apiData>(`service-orders-certificates/normalization/${id}`, {
			form_data: dataToUpdate,
		})
		.then(({ data }) => {
			return data;
		});
};

export const uploadFormDataTechReceipt = async (
	id: string,
	dataToUpdate: apiData,
) => {
	return await api
		.patch<apiData>(`service-orders-certificates/technical-receipt/${id}`, {
			form_data: dataToUpdate,
		})
		.then(({ data }) => {
			return data;
		});
};
