import type { StepperFormData } from "../../../../models";

export const dataPhotographicAnnexes: StepperFormData = {
	name: "photographic_annexes",
	title: "Anexos fotográficos",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "photographic_annexes",
			inputType: "annexes-form",
			label: "Anexos",
		},
	],
};
