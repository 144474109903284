import { Grid, InputLabel, TextField } from "@enerbit/base";
import { useFormContext } from "react-hook-form";
import type { DynamicFieldData } from "../../../../../models";

export const ItemTextArea = ({
	lg,
	md,
	xs,
	inputType,
	fieldName,
	label,
	required,
	config = {},
}: DynamicFieldData) => {
	const { register } = useFormContext();

	return (
		<Grid item md={md} xs={xs} lg={lg}>
			<InputLabel className="title-input-edition" required={required}>
				{label}
			</InputLabel>
			<TextField
				fullWidth
				rows={4}
				multiline
				{...register(fieldName, config)}
				sx={{ "& fieldset": { borderRadius: "14px" } }}
				variant="outlined"
				type={inputType}
			/>
		</Grid>
	);
};
