import type { DynamicFieldData } from "../../../models";

export const dataPhaseForm = (newFieldName: string): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.serial_number.value`,
			inputType: "text",
			label: "Serie del transformador",
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.manufacturer`,
			inputType: "text",
			label: "Fabricante fase",
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.serial_number.picture_url`,
			inputType: "file-image",
			label: "Serie del transformador de corriente",
		},
	];
};
