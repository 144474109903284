/* eslint-disable @typescript-eslint/no-explicit-any */
import type { fieldOptions } from "../../../models";

export const enumOptions: (options: any) => fieldOptions[] = (options: any) => {
	return Object.values(options).map(
		(value: any) =>
			({
				value: value.charAt(0).toLowerCase() + value.slice(1),
				label: value,
			}) as fieldOptions,
	);
};

export const enumOptionsCapitalize: (options: any) => fieldOptions[] = (
	options: any,
) => {
	return Object.values(options).map(
		(value: any) =>
			({
				value: value.charAt(0).toUpperCase() + value.slice(1),
				label: value,
			}) as fieldOptions,
	);
};

export const enumOptionsLowerCase: (options: any) => fieldOptions[] = (
	options: any,
) => {
	return Object.values(options).map(
		(value: any) =>
			({
				value: value.toLowerCase(),
				label: value,
			}) as fieldOptions,
	);
};

export const NumberOptions: (options: number) => fieldOptions[] = (
	options: number,
) => {
	return [...Array(options)].map((_value, index) => ({
		value: index + 1,
		label: `${index + 1}`,
	}));
};

export const booleanOptionsInstalled: () => fieldOptions[] = () => {
	return [
		{
			value: true,
			label: "Instalado",
		},
		{
			value: false,
			label: "Encontrado",
		},
	];
};

export const booleanOptionsExterior: () => fieldOptions[] = () => {
	return [
		{
			value: true,
			label: "Exterior",
		},
		{
			value: false,
			label: "Interior",
		},
	];
};
