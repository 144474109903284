import * as yup from "yup";

export const schemaElectricalBoxesRetired = yup
	.array()
	.nullable()
	.of(
		yup.object().shape({
			usage: yup.string().required("Este campo es obligatorio."),
			manufacturer: yup.object().shape({
				value: yup.string().nullable().required("Este campo es obligatorio."),
				picture_url: yup.string().required("Este campo es obligatorio."),
			}),
			state: yup.string().required("Este campo es obligatorio."),
		}),
	);
