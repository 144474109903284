import type { StepperFormData } from "../../../../models";

export const dataMeasurementSystemData: StepperFormData = {
	name: "measurement_system_data",
	title: "Información del sistema de medida",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "towers",
			inputType: "towers",
			label: "Torre",
			required: true,
		},
	],
};
