import type { StepperFormData } from "../../../../models";

export const dataMainMeterComunicationsRetired: StepperFormData = {
	name: "main_meter_comunications_retired",
	title: "Comunicaciones medidor principal retirada",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "serial_number.value",
			inputType: "text",
			label: "Serie del módem",
			required: true,
			disabled: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "brand",
			inputType: "text",
			label: "Marca del módem",
			required: true,
		},
		{
			xs: 12,
			fieldName: "serial_number.picture_url",
			inputType: "file-image",
			label: "Serie del módem",
			required: true,
		},
	],
};
