import {
	Box,
	Grid,
	Step,
	StepButton,
	StepContent,
	StepLabel,
	Stepper,
} from "@enerbit/base";
import Skeleton from "@mui/material/Skeleton";
import type React from "react";

const StepItemSkeleton: React.FC = () => {
	return (
		<Step>
			<StepLabel>
				<Box display="flex" alignItems="center" gap={2}>
					<Skeleton variant="circular" width={20} height={20} />
					<Skeleton variant="text" width={250} />
				</Box>
			</StepLabel>
			<StepContent>
				<Grid container rowSpacing={3} columnSpacing={5}>
					{[...Array(1)].map((_, i) => (
						<Skeleton variant="rectangular" width={300} height={50} key={i} />
					))}
				</Grid>
				<Box pt={1}>
					<Skeleton variant="rectangular" width={100} height={40} />
				</Box>
			</StepContent>
		</Step>
	);
};

const StepperSkeleton: React.FC = () => {
	return (
		<Stepper orientation="vertical">
			{[...Array(20)].map((_, i) => (
				<StepItemSkeleton key={i} />
			))}
		</Stepper>
	);
};

export default StepperSkeleton;
