import * as yup from "yup";

export const schemaTerminalsRetired = yup
	.array()
	.nullable()
	.of(
		yup.object().shape({
			usage: yup.string().required("Este campo es obligatorio."),
			type_: yup.string().required("Este campo es obligatorio."),
		}),
	);
