import { Box, FormControlLabel, Grid, Switch, Typography } from "@enerbit/base";
import { type ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { DynamicControl } from "..";
import { dataSwitchPhaseCurrentForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { handlePhases } from "../../../../../store/slices/editInstallation";

export const SwitchPhaseCurrentForm = ({
	xs,
	fieldName,
	label,
	subLabel,
}: DynamicFieldData) => {
	const { setValue, watch } = useFormContext();
	const dispatch = useDispatch();

	const [checked, setChecked] = useState(false);
	const [fieldsPhase, setFieldsPhase] = useState<DynamicFieldData[]>([]);

	useEffect(() => {
		const fieldValue = !!watch(fieldName);
		setChecked(fieldValue);
		dispatch(handlePhases({ name: fieldName, value: fieldValue }));
		setFieldsPhase(dataSwitchPhaseCurrentForm(fieldName));
	}, []);

	const switchHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		if (!event.target.checked) {
			setValue(fieldName, null, { shouldValidate: true });
			setValue(
				"transformers_technical_test.current_transformer." +
					fieldName.split(".").pop(),
				null,
				{ shouldValidate: true },
			);
		}
		dispatch(handlePhases({ name: fieldName, value: event.target.checked }));
	};

	return (
		<Grid container item xs={xs}>
			<Grid item xs={xs}>
				<Typography className="title-input-edition">{label}</Typography>
			</Grid>
			<Grid item xs={xs}>
				<FormControlLabel
					control={
						<Switch
							color="secondary"
							checked={checked}
							onChange={switchHandler}
						/>
					}
					label={checked ? "Si" : "No"}
				/>
			</Grid>
			{checked && (
				<Box sx={{ borderRadius: "16px", border: "1px solid #BA5803" }}>
					<Grid item xs={12}>
						<Box
							sx={{
								backgroundColor: "#FF7705",
								borderRadius: "16px 16px 0px 0px",
								px: 4,
								py: 2,
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography sx={{ fontWeight: "bold", color: "white" }}>
								{subLabel}
							</Typography>
						</Box>
					</Grid>
					<Grid item container xs={xs} rowSpacing={3} columnSpacing={5} p={4}>
						{fieldsPhase.map((d, i) => {
							return <DynamicControl {...d} key={i} />;
						})}
					</Grid>
				</Box>
			)}
		</Grid>
	);
};
