import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataAnnexesForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const AnnexesForm = ({ xs }: DynamicFieldData) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: "photographic_annexes",
	});

	const addAnnex = () => {
		append({
			picture_url: "",
			observation: "",
		});
	};

	const [openDeleteAnnex, setOpenDeleteAnnex] = useState(false);
	const handleOpenDeleteAnnex = () => setOpenDeleteAnnex(true);
	const handleCloseDeleteAnnex = () => setOpenDeleteAnnex(false);
	const removeAnnex = (index: number) => {
		remove(index);
	};

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldsAnnex = dataAnnexesForm(`photographic_annexes.${index}`);

				return (
					<Grid item xs={12} key={item.id}>
						<Box sx={{ borderRadius: "16px", border: "1px solid #53358E" }}>
							<Grid item width="100%">
								<Box
									sx={{
										backgroundColor: "#53358E",
										borderRadius: "16px 16px 0px 0px",
										px: 4,
										py: 2,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography sx={{ fontWeight: "bold", color: "white" }}>
										Anexo {index + 1}
									</Typography>
									<Button
										variant="contained"
										className="delete-button"
										onClick={handleOpenDeleteAnnex}
									>
										<DeleteIcon />
									</Button>
								</Box>
								<DeleteItemModal
									open={openDeleteAnnex}
									handleClose={handleCloseDeleteAnnex}
									deleteItem={removeAnnex}
									index={index}
									name="el anexo"
								/>
							</Grid>
							<Grid
								item
								container
								xs={xs}
								rowSpacing={3}
								columnSpacing={5}
								p={4}
							>
								{fieldsAnnex.map((d, i) => {
									return <DynamicControl {...d} key={i} />;
								})}
							</Grid>
						</Box>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={xs}>
				<Button onClick={addAnnex} variant="outlined" startIcon={<AddIcon />}>
					Agregar anexo
				</Button>
			</Grid>
		</Grid>
	);
};
