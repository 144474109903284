import {
	Measurement_Type,
	MeterOwnershipOptions,
	OptionalMeterUsage,
	enumOptions,
	enumOptionsLowerCase,
} from "../../../../helpers/edition/services";
import type { StepperFormData } from "../../../../models";

export const dataOptionalMeterRetired: StepperFormData = {
	name: "optional_meter_retired",
	title: "Medidor de energía activa/reactiva respaldo/otro medidor retirado",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "meter_serial.value",
			inputType: "number",
			label: "Serie medidor",
			required: true,
			disabled: true,
		},
		{
			xs: 12,
			fieldName: "meter_serial.picture_url",
			disabled: true,
			inputType: "file-image",
			label: "Serie medidor",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `usage`,
			inputType: "select",
			label: "Función del medidor",
			options: enumOptions(OptionalMeterUsage),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "ownership",
			inputType: "select",
			label: "Propiedad del medidor",
			options: enumOptionsLowerCase(MeterOwnershipOptions),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "meter_brand",
			inputType: "text",
			label: "Marca medidor",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "measurement_type",
			inputType: "select",
			label: "Tipo de medición",
			options: enumOptions(Measurement_Type),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "active_energy_imported.value",
			inputType: "number",
			label: "Lectura importación activa",
			required: true,
		},
		{
			xs: 12,
			fieldName: "active_energy_imported.picture_url",
			inputType: "file-image",
			label: "Lectura importación activa",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "active_energy_exported.value",
			inputType: "number",
			label: "Lectura exportación activa",
			required: true,
		},
		{
			xs: 12,
			fieldName: "active_energy_exported.picture_url",
			inputType: "file-image",
			label: "Lectura exportación activa",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "reactive_energy_imported.value",
			inputType: "number",
			label: "Lectura importación reactiva",
			required: true,
		},
		{
			xs: 12,
			fieldName: "reactive_energy_imported.picture_url",
			inputType: "file-image",
			label: "Lectura importación reactiva",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "reactive_energy_exported.value",
			inputType: "number",
			label: "Lectura exportación reactiva",
			required: true,
		},
		{
			xs: 12,
			fieldName: "reactive_energy_exported.picture_url",
			inputType: "file-image",
			label: "Lectura exportación reactiva",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "seals",
			inputType: "seals-form",
			label: "Movimiento del sello de seguridad",
		},
	],
};
