import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataMaterialsForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData, IMaterial } from "../../../../../models";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const MaterialsForm = ({ xs }: DynamicFieldData) => {
	const { control, watch, setValue } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: "materials",
	});

	const addMaterials = () => {
		append({
			name: "",
			quantity: undefined,
			unit: "",
			charge: "",
		});
	};

	const [openDeleteMaterial, setOpenDeleteMaterial] = useState(false);
	const handleOpenDeleteMaterial = () => setOpenDeleteMaterial(true);
	const handleCloseDeleteMaterial = () => setOpenDeleteMaterial(false);
	const removeMaterial = (index: number) => {
		remove(index);
	};

	const updateMaterialCharge = useCallback(
		(materials: IMaterial[]) => {
			const materials_charge = materials.reduce(
				(acumulador, actual) => acumulador + Number.parseInt(actual.charge),
				0,
			);
			setValue("summary.materials_charge", materials_charge, {
				shouldValidate: true,
			});
		},
		[setValue],
	);

	useEffect(() => {
		const subscription = watch((_value, { name }) => {
			if (
				name?.includes("materials.") &&
				(name?.includes(".charge") || name?.includes(".quantity"))
			) {
				const materials: IMaterial[] = watch("materials");
				updateMaterialCharge(materials);
			} else if (name === "materials") {
				if (watch(name).length === 0) {
					setValue("summary.materials_charge", 0, { shouldValidate: true });
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, updateMaterialCharge]);

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldsMaterial = dataMaterialsForm(`materials.${index}`);

				return (
					<Grid item xs={12} key={item.id}>
						<Box sx={{ borderRadius: "16px", border: "1px solid #53358E" }}>
							<Grid item width="100%">
								<Box
									sx={{
										backgroundColor: "#53358E",
										borderRadius: "16px 16px 0px 0px",
										px: 4,
										py: 2,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography sx={{ fontWeight: "bold", color: "white" }}>
										Material {index + 1}
									</Typography>
									<Button
										variant="contained"
										className="delete-button"
										onClick={handleOpenDeleteMaterial}
									>
										<DeleteIcon />
									</Button>
									<DeleteItemModal
										open={openDeleteMaterial}
										handleClose={handleCloseDeleteMaterial}
										deleteItem={removeMaterial}
										index={index}
										name="el material"
									/>
								</Box>
							</Grid>
							<Grid
								item
								container
								xs={xs}
								rowSpacing={3}
								columnSpacing={5}
								p={4}
							>
								{fieldsMaterial.map((d, i) => {
									return <DynamicControl {...d} key={i} />;
								})}
							</Grid>
						</Box>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={xs}>
				<Button
					onClick={addMaterials}
					variant="outlined"
					startIcon={<AddIcon />}
				>
					Agregar materiales
				</Button>
			</Grid>
		</Grid>
	);
};
