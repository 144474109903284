import {
	NotiProvider,
	PrivateRoute,
	ThemeConfig,
	initEnviroment,
} from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { IdProvider } from "./context";
import type { ItemGetOrders, OrderEntityGetOrders } from "./models/orders";
import { Certifications } from "./pages/certifications/Certifications";
import { store } from "./store/store";

initEnviroment({
	baseUrl: process.env.REACT_APP_BASE_URL,
});

interface Props {
	order: ItemGetOrders;
	mainLead: OrderEntityGetOrders;
}

export default hot(function Root(props: Props) {
	const { order, mainLead } = props;

	return (
		<PrivateRoute>
			<ThemeConfig>
				<NotiProvider>
					<IdProvider id={mainLead.id ?? ""}>
						<Provider store={store(mainLead.id ?? "")}>
							{mainLead.id && (
								<Certifications order={order} mainLead={mainLead} />
							)}
						</Provider>
					</IdProvider>
				</NotiProvider>
			</ThemeConfig>
		</PrivateRoute>
	);
});
