import type { DynamicFieldData } from "../../../models";

export const dataAnnexesForm = (newFieldName: string): DynamicFieldData[] => {
	return [
		{
			xs: 12,
			fieldName: `${newFieldName}.photographic_annex.picture_url`,
			inputType: "file-image",
			label: "Anexo",
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.photographic_annex.observation`,
			inputType: "text-area",
			label: "Para evidenciar algo relevante adicional",
		},
	];
};
