import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Switch,
	Typography,
} from "@enerbit/base";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { DynamicFieldData } from "../../../../../models";
import { useAppSelector } from "../../../../../store/store";

export const ItemSwicthCheckGroup = ({
	xs,
	label,
	subLabel,
}: DynamicFieldData) => {
	const { watch, setValue, control } = useFormContext();

	const { works } = useAppSelector((state) => state.editInstallation);

	const currentData = watch("order_activity_executed_correctly");

	const onChange = (value: string) => {
		if (currentData.includes(value)) {
			return setValue(
				"order_activity_executed_correctly",
				currentData.filter((s: string) => s !== value),
			);
		}
		return setValue("order_activity_executed_correctly", [
			...currentData,
			value,
		]);
	};

	useEffect(() => {
		if (watch("order_executed_correctly")) {
			setValue("order_activity_executed_correctly", []);
		}
	}, [watch("order_executed_correctly")]);

	return (
		<Grid item xs={xs}>
			<Typography className="title-input-edition">{label}</Typography>
			<Controller
				render={({ field: { onChange, value, ...field } }) => (
					<FormControlLabel
						control={
							<Switch
								{...field}
								color="secondary"
								onChange={onChange}
								checked={value}
							/>
						}
						label={value ? "Sí" : "No"}
					/>
				)}
				name={"order_executed_correctly"}
				control={control}
			/>
			{/* {
                !watch('order_executed_correctly')
                &&
                <Grid item xs={12}>
                    <Typography
                        className="title-input-edition"
                    >
                        {subLabel}
                    </Typography>
                    <FormGroup row>
                        {
                            works.map(({ name }, index) => (
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    lg={4}
                                    key={index}
                                >
                                    <FormControlLabel
                                        label={name}
                                        control={
                                            <Checkbox
                                                checked={watch('order_activity_executed_correctly').includes((name))}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: "#FF7705",
                                                    },
                                                }}
                                            />
                                        }
                                        onChange={() => onChange(name)}
                                    />
                                </Grid>
                            ))
                        }

                    </FormGroup>
                </Grid>
            } */}
		</Grid>
	);
};
