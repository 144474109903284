import * as yup from "yup";

export const ComercialDataSchema = yup.object().shape({
	feasibility_number: yup
		.number()
		.typeError("Este campo debe ser un número válido.")
		.required("Este campo es obligatorio"),
	municipality: yup.string().required("Este campo es obligatorio"),
	network_operator: yup.string().required("Este campo es obligatorio"),
	or_reference_number: yup
		.number()
		.typeError("Este campo debe ser un número válido")
		.required("Este campo es obligatorio"),
	project_name: yup.string().required("Este campo es obligatorio"),
});

export const ListCabinetSchema = yup.object().shape({
	cabinet_id: yup.string().required("El ID del gabinete es obligatorio"),
	cabinet_picture: yup.string().required("La foto del gabinete es obligatoria"),
	flat: yup
		.number()
		.typeError("El número de pisos debe ser un número válido.")
		.required("El número de pisos es obligatorio"),
	meters_quantity: yup
		.number()
		.typeError("La cantidad de metros debe ser un número válido.")
		.required("La cantidad de metros es obligatoria"),
	observation: yup.string().required("La observación es obligatoria"),
});

export const ObservationsSchema = yup.object().shape({
	observation: yup.string().required("La observación es obligatoria"),
});

export const CustomerSchema = yup.object().shape({
	email: yup
		.string()
		.email("Debe ser un correo electrónico válido.")
		.required("El correo electrónico es obligatorio"),
	fullname: yup.string().required("El nombre completo es obligatorio"),
	legal_id_code: yup
		.number()
		.typeError("El código de identificación legal debe ser un número válido.")
		.required("El código de identificación legal es obligatorio"),
	legal_id_type: yup
		.string()
		.required("El tipo de identificación legal es obligatorio"),
	signature: yup.string().required("La firma es obligatoria"),
});

export const ConnectionSchema = yup.object().shape({
	is_approved: yup.boolean().required("Este campo es obligatorio"),
	observation: yup
		.string()
		.nullable()
		.when("is_approved", {
			is: false,
			then: yup
				.string()
				.required("La observación es obligatoria cuando está aprobado."),
			otherwise: yup.string().nullable(),
		}),
});

export const TowerSchema = yup.object().shape({
	list_cabinet: yup.array(ListCabinetSchema),
	name: yup.string().required("El nombre de la torre es obligatorio"),
});

export const SignaturesSchema = yup.object().shape({
	customer: CustomerSchema.required(),
	dso: CustomerSchema.required(),
	technician: CustomerSchema.required(),
});

export const TechnicalValidationTestingSchema = yup.object().shape({
	connection: ConnectionSchema.required(),
	connection_equipment: ConnectionSchema.required(),
	homologation_test_protocols: ConnectionSchema.required(),
	power_quality: ConnectionSchema.required(),
	required_maneuvers: ConnectionSchema.required(),
	technical_verification_connection: ConnectionSchema.required(),
});

export const MeasurementSystemDataSchema = yup.object().shape({
	towers: yup.array(TowerSchema),
});

export const schemaTechnicalReceipt = yup.object().shape({
	comercial_data: ComercialDataSchema.required(),
	measurement_system_data: MeasurementSystemDataSchema.required(),
	observations: ObservationsSchema.required(),
	signatures: SignaturesSchema.required(),
	technical_validation_testing: TechnicalValidationTestingSchema.required(),
});
