import * as yup from "yup";

export const schemaTransformersTechnicalTest = yup
	.object()
	.nullable()
	.shape({
		current_transformer: yup
			.object()
			.nullable()
			.shape({
				transformation_relationship: yup
					.number()
					.nullable()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero."),
				r_phase: yup
					.object()
					.nullable()
					.shape({
						primary_current: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
						secundary_current: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
						transformation_relationship: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
					}),
				s_phase: yup
					.object()
					.nullable()
					.shape({
						primary_current: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
						secundary_current: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
						transformation_relationship: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
					}),
				t_phase: yup
					.object()
					.nullable()
					.shape({
						primary_current: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
						secundary_current: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
						transformation_relationship: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.required("Este campo es obligatorio.")
							.min(0, "Debe ser un numero mayor o igual a cero."),
					}),
			}),
		voltage_transformer: yup
			.object()
			.nullable()
			.shape({
				transformation_relationship: yup
					.number()
					.nullable()
					.transform((value) => (isNaN(value) ? undefined : value))
					.min(0, "Debe ser un numero mayor o igual a cero.")

					.required("Este campo es obligatorio."),
				r_phase: yup
					.object()
					.nullable()
					.shape({
						primary_voltage: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
						secundary_voltage: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
						transformation_relationship: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
					}),
				s_phase: yup
					.object()
					.nullable()
					.shape({
						primary_voltage: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
						secundary_voltage: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
						transformation_relationship: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
					}),
				t_phase: yup
					.object()
					.nullable()
					.shape({
						primary_voltage: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
						secundary_voltage: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
						transformation_relationship: yup
							.number()
							.nullable()
							.transform((value) => (isNaN(value) ? undefined : value))
							.min(0, "Debe ser un numero mayor o igual a cero.")

							.required("Este campo es obligatorio."),
					}),
			}),
		multiplier_factor: yup
			.number()
			.nullable()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Este campo es obligatorio.")
			.min(0, "Debe ser un numero mayor o igual a cero."),
		multiplier_factor_error: yup
			.number()
			.nullable()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Este campo es obligatorio.")
			.min(0, "Debe ser un numero mayor o igual a cero."),
	});
