import { Box, Grid, Typography } from "@enerbit/base";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { dataPhaseForm } from "../../../../../helpers/edition/services";
import type { DynamicFieldData } from "../../../../../models";
import { DynamicControl } from "../DynamicControl";

export const PhaseForm = ({ xs, fieldName, label }: DynamicFieldData) => {
	const [fieldsPhase, setFieldsPhase] = useState<DynamicFieldData[]>([]);

	const { setValue, getValues } = useFormContext();

	useEffect(() => {
		setFieldsPhase(dataPhaseForm(fieldName));
	}, []);

	useEffect(() => {
		if (
			!getValues(`${fieldName}.manufacturer`) &&
			!!getValues(`${fieldName}.serial_number.value`)
		) {
			setValue(fieldName, null);
		}
	}, []);

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			<Grid item xs={12}>
				<Box sx={{ borderRadius: "16px", border: "1px solid #BA5803" }}>
					<Grid item xs={12}>
						<Box
							sx={{
								backgroundColor: "#FF7705",
								borderRadius: "16px 16px 0px 0px",
								px: 4,
								py: 2,
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography sx={{ fontWeight: "bold", color: "white" }}>
								{label}
							</Typography>
						</Box>
					</Grid>
					<Grid item container xs={xs} rowSpacing={3} columnSpacing={5} p={4}>
						{fieldsPhase.map((d, i) => {
							return <DynamicControl {...d} key={i} />;
						})}
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
};
