import * as yup from "yup";

export const schemaElectricalConductorsRetired = yup
	.object()
	.nullable()
	.shape({
		current_signal: yup
			.object()
			.nullable()
			.shape({
				manufacturer: yup
					.string()
					.nullable()
					.required("Este campo es obligatorio."),
				gauge: yup
					.object()
					.nullable()
					.shape({
						value: yup
							.string()
							.nullable()
							.required("Este campo es obligatorio."),
						picture_url: yup
							.string()
							.nullable()
							.required("Este campo es obligatorio."),
					}),
				length: yup
					.number()
					.nullable()
					.transform((value) => (isNaN(value) ? undefined : value))
					.required("Este campo es obligatorio.")
					.min(0, "Debe ser un numero mayor o igual a cero.")
					.integer("Debe ser un numero entero."),
				count: yup.string().nullable().required("Este campo es obligatorio."),
				material: yup
					.string()
					.nullable()
					.required("Este campo es obligatorio."),
			}),
		voltage_signal: yup
			.object()
			.nullable()
			.shape({
				manufacturer: yup
					.string()
					.nullable()
					.required("Este campo es obligatorio."),
				gauge: yup
					.object()
					.nullable()
					.shape({
						value: yup
							.string()
							.nullable()
							.required("Este campo es obligatorio."),
						picture_url: yup
							.string()
							.nullable()
							.required("Este campo es obligatorio."),
					}),
				length: yup
					.number()
					.nullable()
					.transform((value) => (isNaN(value) ? undefined : value))
					.min(0, "Debe ser un numero mayor o igual a cero.")
					.integer("Debe ser un numero entero.")
					.required("Este campo es obligatorio."),
				count: yup.string().nullable().required("Este campo es obligatorio."),
				material: yup
					.string()
					.nullable()
					.required("Este campo es obligatorio."),
			}),
	});
