import {
	ConnectionCorrespondenceOptions,
	Measurement_Type,
	MeterLocation,
	MeterOwnershipOptions,
	NumberConnectionElements,
	NumberMeterElements,
	NumberOptions,
	OptionalMeterUsage,
	PowerClassIndexPercentage,
	SocketOptions,
	UsedElementOptions,
	booleanOptionsInstalled,
	enumOptions,
} from "../../../../helpers/edition/services";
import { enumOptionsLowerCase } from "../../../../helpers/edition/services/optionsForm";
import type { StepperFormData } from "../../../../models";

export const dataOptionalMeterInstalled: StepperFormData = {
	name: "optional_meter_installed",
	title:
		"Medidor de energía activa/reactiva respaldo/otro medidor encontrado/instalado",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "is_installed",
			inputType: "select",
			label: "El medidor es:",
			options: booleanOptionsInstalled(),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "meter_serial.value",
			inputType: "number",
			label: "Serie medidor",
			required: true,
			disabled: true,
		},
		{
			xs: 12,
			fieldName: "meter_serial.picture_url",
			inputType: "file-image",
			label: "Serie medidor",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `usage`,
			inputType: "select",
			label: "Función del medidor",
			options: enumOptions(OptionalMeterUsage),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "meter_location",
			inputType: "select",
			label: "Ubicación del medidor",
			options: enumOptions(MeterLocation),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "ownership",
			inputType: "select",
			label: "Propiedad del medidor",
			options: enumOptionsLowerCase(MeterOwnershipOptions),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "meter_brand",
			inputType: "text",
			label: "Marca medidor",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "meter_model",
			inputType: "text",
			label: "Modelo (Referencia)",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "manufacture_year",
			inputType: "number",
			label: "Año de fabricación",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "measurement_type",
			inputType: "select",
			label: "Tipo de medición",
			options: enumOptions(Measurement_Type),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "number_meter_elements",
			inputType: "select",
			label: "Número de elementos del medidor",
			options: enumOptions(NumberMeterElements),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "number_connection_elements",
			inputType: "select",
			label: "Número de elementos de la conexión",
			options: enumOptions(NumberConnectionElements),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "connection_correspondence",
			inputType: "select",
			label: "Correspondencia de la conexión",
			options: enumOptions(ConnectionCorrespondenceOptions),
		},
		{
			md: 6,
			xs: 12,
			fieldName: "used_elements",
			inputType: "select",
			label: "Elemento utilizado",
			options: enumOptions(UsedElementOptions),
		},
		{
			md: 6,
			xs: 12,
			fieldName: "socket",
			inputType: "select",
			label: "Tomacorriente",
			options: enumOptions(SocketOptions),
		},
		{
			md: 6,
			xs: 12,
			fieldName: "wires_count",
			inputType: "select",
			label: "Número de hilos",
			options: NumberOptions(4),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "connection_phases_count",
			inputType: "select",
			label: "Número de fases de la conexión del medidor",
			options: NumberOptions(3),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "active_class_index_percentage",
			inputType: "select",
			label: "Índice de clase activa (%)",
			options: enumOptions(PowerClassIndexPercentage),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "reactive_class_index_percentage",
			inputType: "select",
			label: "Índice de clase reactiva (%)",
			options: enumOptions(PowerClassIndexPercentage),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "active_energy_imported",
			inputType: "number",
			label: "Lectura importación activa",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "active_energy_exported",
			inputType: "number",
			label: "Lectura exportación activa",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "reactive_energy_imported",
			inputType: "number",
			label: "Lectura importación reactiva",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "reactive_energy_exported",
			inputType: "number",
			label: "Lectura exportación reactiva",
		},
		{
			xs: 12,
			fieldName: "calibration_date",
			inputType: "calibration-form",
			label: "¿Existe fecha de calibración?",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "seals",
			inputType: "seals-form",
			label: "Movimiento del sello de seguridad",
		},
	],
};
