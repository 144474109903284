import {
	ConductorCounts,
	ConductorDenominations,
	ConductorGaugeValues,
	ConductorMaterials,
	enumOptions,
} from ".";
import type { DynamicFieldData } from "../../../models";
import { enumOptionsCapitalize } from "./optionsForm";

export const dataConductorsForm = (
	newFieldName: string,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.gauge.value`,
			inputType: "select",
			label: "Calibre",
			options: enumOptions(ConductorGaugeValues),
			required: true,
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.gauge.picture_url`,
			inputType: "file-image",
			label: "Calibre, material y demás características del conductor",
			required: true,
		},
		{
			...(newFieldName.includes("electrical_conductors_installed") && {
				md: 6,
				xs: 12,
				fieldName: `${newFieldName}.denomination`,
				inputType: "select",
				label: "Denominación",
				required: true,
				options: enumOptionsCapitalize(ConductorDenominations),
			}),
		} as DynamicFieldData,
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.length`,
			inputType: "number",
			label: "Longitud (m) conductor señal de corriente",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.count`,
			inputType: "select",
			label: "N° de conductores",
			options: enumOptions(ConductorCounts),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.material`,
			inputType: "select",
			label: "Material conductor",
			options: enumOptions(ConductorMaterials),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.manufacturer`,
			inputType: "text",
			label: "Fabricante",
			required: true,
		},
	];
};
