import type { DynamicFieldData } from "../../../models";

export const dataTowersForm = (newFieldName: string): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.name`,
			inputType: "text",
			label: "Nombre",
			required: true,
		},
		{
			fieldName: `${newFieldName}.list_cabinet`,
			inputType: "list_cabinet",
			label: "Gabinete",
			required: true,
		},
	];
};
