import {
	TransformerLocationNames,
	booleanOptionsInstalled,
	enumOptions,
} from "../../../../helpers/edition/services";
import type { StepperFormData } from "../../../../models";

export const dataCurrentTransformerInstalled: StepperFormData = {
	name: "current_transformer_installed",
	title: "Transformadores de corriente(CT) encontrados/instalados",
	items: [
		{
			md: 6,
			xs: 12,
			fieldName: "is_installed",
			inputType: "select",
			label: "El transformador es:",
			options: booleanOptionsInstalled(),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "location.value",
			inputType: "select",
			label: "Ubicación de los transformadores de corriente",
			options: enumOptions(TransformerLocationNames),
			required: true,
		},
		{
			xs: 12,
			fieldName: "location.picture_url",
			inputType: "file-image",
			label: "Ubicación del transformador ct",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "r_phase",
			inputType: "switch-phase-current-form",
			label: "¿Se instalara transformador en la fase R?",
			subLabel: "Fase R",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "s_phase",
			inputType: "switch-phase-current-form",
			label: "¿Se instalara transformador en la fase S?",
			subLabel: "Fase S",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "t_phase",
			inputType: "switch-phase-current-form",
			label: "¿Se instalara transformador en la fase T?",
			subLabel: "Fase T",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "current_transformer_retired",
			inputType: "switch-step",
			label: "¿Se retirará transformador de corriente?",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "transformers_technical_test",
			inputType: "switch-step",
			label: "¿Se hará prueba de transformadores?",
		},
	],
};
