import type { DynamicFieldData } from "../../../models";

export const dataActivitiesForm = (
	newFieldName: string,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.name`,
			inputType: "select-activity",
			label: "Elemento al que corresponde",
			options: [
				{ label: "Adecuación de acometida en bornera", value: 31168 },
				{
					label: "Adecuación de acometida en red de baja tensión",
					value: 36363,
				},
				{ label: "Amarre de cliente a transformador", value: 31168 },
				{ label: "Asegurar caja de medidor", value: 31168 },
				{ label: "Atención de reclamo por siniestro", value: 51947 },
				{ label: "Bajada de datos", value: 41558 },
				{ label: "Cambio de acometida aérea", value: 67531 },
				{ label: "Cambio de acometida subterránea", value: 83115 },
				{ label: "Cambio de caja de medidor empotrada", value: 83115 },
				{ label: "Cambio de caja de medidor sobrepuesta", value: 41557 },
				{ label: "Cambio de canalización", value: 62337 },
				{ label: "Cambio de elemento de protección o corte", value: 36363 },
				{ label: "Cambio de medidor", value: 36363 },
				{ label: "Cambio de sistema de comunicación", value: 51947 },
				{
					label: "Cambio de tipo de medición de directa a semidirecta",
					value: 228568,
				},
				{
					label: "Diligenciamiento de formato de inconsistencias",
					value: 36363,
				},
				{ label: "Diligenciamiento de hoja de vida", value: 67531 },
				{ label: "Inspección", value: 25974 },
				{ label: "Inspección previa", value: 51947 },
				{ label: "Instalación completa del sistema de medida", value: 207788 },
				{ label: "Instalación de caja de medidor empotrada", value: 51947 },
				{ label: "Instalación de caja de medidor sobrepuesta", value: 31168 },
				{ label: "Instalación de canalización", value: 51947 },
				{ label: "Instalación de medidor", value: 36363 },
				{ label: "Instalación de puesta a tierra", value: 51947 },
				{ label: "Instalación de sistema de comunicación", value: 62337 },
				{
					label: "Instalación o cambio de la tapa de bornera del medidor",
					value: 25974,
				},
				{
					label: "Instalación o cambio de la tapa de la caja del medidor",
					value: 31168,
				},
				{ label: "Normalización de la telemedida", value: 41558 },
				{ label: "Reconexión en bornera", value: 31168 },
				{ label: "Reconexión en red de baja tensión", value: 41558 },
				{ label: "Retiro completo del sistema de medida", value: 41558 },
				{ label: "Retiro de acometida aérea", value: 36363 },
				{ label: "Retiro de acometida subterránea", value: 46752 },
				{ label: "Retiro de conexión directa en bornera", value: 31168 },
				{
					label: "Retiro de conexión directa en red de baja tensión",
					value: 36363,
				},
				{ label: "Reubicación de caja de medidor", value: 41558 },
				{ label: "Revisión con equipo verificador", value: 41558 },
				{ label: "Revisión de la suspensión", value: 25974 },
				{ label: "Suspensión en bornera", value: 31168 },
				{ label: "Suspensión en red de  tensión", value: 36363 },
			],
		},
	];
};
