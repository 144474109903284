/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	AdapterDayjs,
	DatePicker,
	type Dayjs,
	Grid,
	LocalizationProvider,
	Typography,
	dayjs,
} from "@enerbit/base";
import { Controller, get, useFormContext } from "react-hook-form";
import { Asterisk } from "../../../../../common";
import type { DynamicFieldData } from "../../../../../models";

export const ItemDatePicker = ({
	lg,
	md,
	xs,
	fieldName,
	required,
	label,
}: DynamicFieldData) => {
	const {
		control,
		formState: { errors },
		setValue,
	} = useFormContext();

	const handleDateChange = (date: Dayjs | null) => {
		const dateStr = date && date.isValid() ? date.format("YYYY-MM-DD") : null;
		setValue(fieldName!, dateStr);
	};

	return (
		<Grid item md={md} xs={xs} lg={lg}>
			<Typography className="title-input-edition">
				{label}
				{required && <Asterisk />}
			</Typography>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Controller
					name={fieldName}
					control={control}
					defaultValue={null}
					render={({ field: { value } }) => (
						<DatePicker
							value={value ? dayjs(value) : null}
							slotProps={{
								textField: {
									color: "primary",
									helperText: get(errors, fieldName)
										? get(errors, fieldName).message
										: "",
									error: !!get(errors, fieldName),
									sx: {
										"& fieldset": { borderRadius: "14px" },
										"& p": { color: "red" },
										width: "100%",
									},
								},
							}}
							format="YYYY-MM-DD"
							onChange={handleDateChange}
						/>
					)}
				/>
			</LocalizationProvider>
		</Grid>
	);
};
