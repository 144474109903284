import type { DynamicFieldData } from "../../../models";
import { CertificateEnum } from "../../../models/consult";

export const dataForUsForm = (
	newFieldName: string,
	certificationType: string,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.backup_plant`,
			inputType: "text",
			label: "Planta de respaldo",
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.service_used_commercial_purpose`,
			inputType: "switch-input",
			label: "¿Este sistema de medida corresponde a una zona común?",
			subLabel: "¿Este servicio es utilizado con algún fin comercial?",
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.how_long_customer_would_remain_without_power`,
			inputType: "switch-input",
			label:
				"¿Requiere apertura para la siguiente visita de instalación o normalización?",
			subLabel: "¿Cuánto tiempo se estima que el cliente quedaría sin energía?",
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.require_electrical_plant_execution_activities`,
			inputType: "switch",
			label: "¿Requiere planta eléctrica para la ejecución de las actividades?",
		},
		{
			xs: 12,
			fieldName: `${newFieldName}.requirements_specification`,
			inputType: "switch-input",
			label:
				"¿Se requiere equipo de trabajo o herramienta especial para la siguiente visita de instalación o normalización?",
			subLabel: "Especificar su requerimiento",
		},
		{
			...(certificationType === CertificateEnum.NORMALIZATION && {
				xs: 12,
				fieldName: "order_executed_correctly",
				inputType: "swicth-check-group",
				label: "¿La orden ejecutada fue generada correctamente?",
				subLabel:
					"¿A cuál de las siguiente lista de órdenes de servicio corresponde lo ejecutado?",
			}),
		} as DynamicFieldData,
	];
};
