import * as yup from "yup";

export const schemaMaterials = yup.array().of(
	yup.object().shape({
		name: yup.string().required("Este campo es obligatorio."),
		quantity: yup
			.number()
			.transform((value) => (isNaN(value) ? undefined : value))
			.required("Este campo es obligatorio.")
			.min(0, "Debe ser un numero mayor o igual a cero.")
			.integer("Debe ser un numero entero."),
		unit: yup.string().required("Este campo es obligatorio."),
		charge: yup.string().required("Este campo es obligatorio."),
	}),
);
