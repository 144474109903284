import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type {
	AddressEdition,
	editInstallationSliceState,
} from "../../../models";
import type {
	IFormData,
	IFormDataTechReceipt,
	ItemActivity,
} from "../../../models/infoCertifications";
import type { IOrderType } from "../../../models/orders";
import {
	getActivities,
	getCategoriesMaterial,
	getDocument,
	getFormData,
	getWorks,
} from "./thunk";

const initialState: editInstallationSliceState = {
	loadingDocument: false,
	assignee_id: "",
	order_id: "",
	document_url: null,
	activeStep: -1,
	currentMeter: "",
	r_phase: false,
	s_phase: false,
	t_phase: false,
	activities: [],
	loadingFormData: false,
	loadingActivities: false,
	loadingMaterials: false,
	categoriesMaterial: [
		"Bornera",
		"Caja",
		"Canalización",
		"Comunicación",
		"Conductor",
		"Cortacircuitos",
		"Materiales de consumo",
		"Medidor",
		"Otros materiales",
		"Sellos y bolsas",
		"Sim Card",
		"Transformador corriente",
		"Transformador de tensión",
	],
	consultType: "",
	certificationType: null,
	orderType: null,
	showFormData: false,
	infoFormData: undefined,
	notFoundEmail: false,
	notFoundAddress: false,
	showMainMeterRetired: false,
	showCurrentTransformerInstalled: false,
	showCurrentTransformerRetired: false,
	showVoltageTransformerInstalled: false,
	showVoltageTransformerRetired: false,
	showElectricalConductorsInstalled: false,
	showElectricalConductorsRetired: false,
	showOptionalMeterInstalled: false,
	showOptionalMeterRetired: false,
	showElectricalBoxesRetired: false,
	showTerminalsInstalled: false,
	showTerminalsRetired: false,
	showMainMeterComunicationsRetired: false,
	showMainMeterCommunicationsInstalled: false,
	showOptionalMeterComunicationsInstalled: false,
	showOptionalMeterComunicationsRetired: false,
	showTransformersTechnicalTest: false,
	loadingForm: false,
	works: [],
	loadingWorks: false,
};

export const editInstallationSlice = createSlice({
	name: "editInstallation",
	initialState,
	reducers: {
		resetEdit: () => {
			return initialState;
		},
		resetAll: (state) => {
			const { consultType, loadingFormData } = state;
			return {
				...initialState,
				consultType,
				loadingFormData,
			};
		},
		handleStepper: (state, action: PayloadAction<number>) => {
			state.activeStep =
				action.payload === state.activeStep ? -1 : action.payload;
		},
		handlePhases: (
			state,
			action: PayloadAction<{ name: string; value: boolean }>,
		) => {
			const { name, value } = action.payload;
			if (name.includes("r_phase")) {
				state.r_phase = value;
			} else if (name.includes("s_phase")) {
				state.s_phase = value;
			} else if (name.includes("t_phase")) {
				state.t_phase = value;
			}
		},
		loadActivities: (state, action: PayloadAction<ItemActivity[]>) => {
			state.activities = action.payload;
		},
		handleConsultType(state, action: PayloadAction<string>) {
			state.consultType = action.payload;
		},
		setOrderType(state, action: PayloadAction<IOrderType>) {
			state.orderType = action.payload;
		},
		loadInfo: (state, action: PayloadAction<AddressEdition>) => {
			state.infoCertificationsEdit = action.payload;
		},
		openFormData: (state) => {
			state.showFormData = true;
		},
		resetForm: (state) => {
			state.infoCertificationsEdit = undefined;
			state.showFormData = false;
			state.infoFormData = undefined;
			state.activeStep = -1;
		},
		loadForm: (state, action: PayloadAction<string>) => {
			state.currentMeter = action.payload;
			if (state.certificationType?.code !== "TECH_RECEIPT") {
				const formData = state.infoFormData as IFormData;
				const commonAssignments = () => {
					state.showMainMeterRetired = !!formData?.main_meter_retired;
					state.showOptionalMeterInstalled =
						!!formData?.optional_meter_installed;
					state.showOptionalMeterRetired = !!formData?.optional_meter_retired;
					state.showElectricalBoxesRetired =
						!!formData?.electrical_boxes_retired;
					state.showMainMeterCommunicationsInstalled =
						!!formData?.main_meter_comunications_installed;
					state.showMainMeterComunicationsRetired =
						!!formData?.main_meter_comunications_retired;
					state.showOptionalMeterComunicationsInstalled =
						!!formData?.optional_meter_comunications_installed;
					state.showOptionalMeterComunicationsRetired =
						!!formData?.optional_meter_comunications_retired;
				};

				switch (action.payload) {
					case "directa":
						commonAssignments();
						break;
					case "semidirecta":
						commonAssignments();
						state.showCurrentTransformerInstalled = true;
						state.showCurrentTransformerRetired =
							!!formData?.current_transformer_retired;
						state.showElectricalConductorsInstalled = true;
						state.showElectricalConductorsRetired =
							!!formData?.electrical_conductors_retired;
						state.showTerminalsInstalled = true;
						state.showTerminalsRetired = !!formData?.terminals_retired;
						state.showTransformersTechnicalTest =
							!!formData?.transformers_technical_test;
						break;
					case "indirecta":
						commonAssignments();
						state.showCurrentTransformerInstalled = true;
						state.showCurrentTransformerRetired =
							!!formData?.current_transformer_retired;
						state.showVoltageTransformerInstalled = true;
						state.showVoltageTransformerRetired =
							!!formData?.voltage_transformer_retired;
						state.showElectricalConductorsInstalled = true;
						state.showElectricalConductorsRetired =
							!!formData?.electrical_conductors_retired;
						state.showTerminalsInstalled = true;
						state.showTerminalsRetired = !!formData?.terminals_retired;
						state.showTransformersTechnicalTest =
							!!formData?.transformers_technical_test;
						break;
				}
			}
		},
		handleLoadingForm: (state, action: PayloadAction<boolean>) => {
			state.loadingForm = action.payload;
		},
		showHideOptionalMeter: (state, action: PayloadAction<boolean>) => {
			state.showOptionalMeterInstalled = action.payload;
			state.showOptionalMeterRetired = action.payload;
			state.showOptionalMeterComunicationsInstalled = action.payload;
			state.showOptionalMeterComunicationsRetired = action.payload;
		},
		showHideTransformersTechnicalTest: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.showTransformersTechnicalTest = action.payload;
		},
		showMainMeterRetired: (state, action: PayloadAction<boolean>) => {
			state.showMainMeterRetired = action.payload;
		},
		showHideConductorRetired: (state, action: PayloadAction<boolean>) => {
			state.showElectricalConductorsRetired = action.payload;
		},
		showHideTerminalRetired: (state, action: PayloadAction<boolean>) => {
			state.showTerminalsRetired = action.payload;
		},
		showHideBoxesRetired: (state, action: PayloadAction<boolean>) => {
			state.showElectricalBoxesRetired = !!action.payload;
		},
		showHideMainMeterComunicationsRetired: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.showMainMeterComunicationsRetired = action.payload;
		},
		showHideCurrentTransformerRetired: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.showCurrentTransformerRetired = action.payload;
		},
		showHideVoltageTransformerRetired: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.showVoltageTransformerRetired = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getActivities.pending, (state) => {
			state.loadingActivities = true;
		});
		builder.addCase(getActivities.fulfilled, (state, action) => {
			state.loadingActivities = false;
			state.activities = action.payload;
		});
		builder.addCase(getActivities.rejected, (state) => {
			state.loadingActivities = false;
		});
		builder.addCase(getWorks.pending, (state) => {
			state.loadingWorks = true;
		});
		builder.addCase(getWorks.fulfilled, (state, action) => {
			state.loadingWorks = false;
			state.works = action.payload;
		});
		builder.addCase(getWorks.rejected, (state) => {
			state.loadingWorks = false;
		});
		builder.addCase(getCategoriesMaterial.pending, (state) => {
			state.loadingMaterials = true;
		});
		builder.addCase(getCategoriesMaterial.fulfilled, (state, action) => {
			state.loadingMaterials = false;
			//state.categoriesMaterial = action.payload;
		});
		builder.addCase(getCategoriesMaterial.rejected, (state) => {
			state.loadingMaterials = false;
		});
		builder.addCase(getFormData.pending, (state) => {
			state.loadingFormData = true;
		});
		builder.addCase(getFormData.fulfilled, (state, { payload }) => {
			if (payload) {
				state.certificationType = payload.certificate_type;
				state.infoFormData = payload.form_data;
				state.id = payload.id;
				state.assignee_id = payload.assignee_id;
				state.order_id = payload.order_id;
			}
			state.loadingFormData = false;
		});
		builder.addCase(getFormData.rejected, (state) => {
			state.loadingFormData = false;
		});
		builder
			.addCase(getDocument.pending, (state) => {
				state.loadingDocument = true;
			})
			.addCase(getDocument.fulfilled, (state, { payload }) => {
				state.document_url = payload;
				state.loadingDocument = false;
			})
			.addCase(getDocument.rejected, (state) => {
				state.loadingDocument = false;
			});
	},
});

export const {
	loadForm,
	handleStepper,
	showMainMeterRetired,
	showHideConductorRetired,
	showHideTerminalRetired,
	showHideOptionalMeter,
	showHideBoxesRetired,
	showHideMainMeterComunicationsRetired,
	showHideCurrentTransformerRetired,
	showHideTransformersTechnicalTest,
	handlePhases,
	showHideVoltageTransformerRetired,
	loadActivities,
	handleConsultType,
	loadInfo,
	openFormData,
	resetForm,
	handleLoadingForm,
	resetAll,
	resetEdit,
	setOrderType,
} = editInstallationSlice.actions;
