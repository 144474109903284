import type { StepperFormData } from "../../../../models";

export const dataObservationsTR: StepperFormData = {
	name: "observations",
	title: "Observaciones",
	items: [
		{
			xs: 12,
			fieldName: "observation",
			inputType: "text-area",
			label: "Observación general",
			required: true,
		},
	],
};
