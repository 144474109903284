import * as yup from "yup";

export const schemaTerminalsInstalled = yup
	.array()
	.nullable()
	.of(
		yup.object().shape({
			is_installed: yup.string().required("Este campo es obligatorio."),
			usage: yup.string().required("Este campo es obligatorio."),
			type_: yup.string().required("Este campo es obligatorio."),
			seals: yup
				.array()
				.nullable()
				.of(
					yup.object().shape({
						serial_number: yup.object().shape({
							value: yup.string().required("Este campo es obligatorio."),
							picture_url: yup.string().required("Este campo es obligatorio."),
						}),
						type_: yup.string().required("Este campo es obligatorio."),
						color: yup.string().required("Este campo es obligatorio."),
						ownership: yup.string().required("Este campo es obligatorio."),
						action: yup.string().required("Este campo es obligatorio."),
					}),
				),
		}),
	);
