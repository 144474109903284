import type { DynamicFieldData, StepperFormData } from "../../../../models";

export const dataTransformersTechnicalTest: (meter: string) => StepperFormData =
	(meter) => {
		return {
			name: "transformers_technical_test",
			title: "Pruebas técnicas transformadores de medida",
			items: [
				{
					md: 6,
					xs: 12,
					fieldName: "current_transformer",
					inputType: "transformer-form",
					label: "Transformadores de corriente",
				},
				{
					...(meter === "indirecta" && {
						md: 6,
						xs: 12,
						fieldName: "voltage_transformer",
						inputType: "transformer-form",
						label: "Transformadores de tensión",
					}),
				} as DynamicFieldData,
				{
					md: 6,
					xs: 12,
					fieldName: "multiplier_factor",
					inputType: "number",
					label: "Factor liquidación externo obtenido en las pruebas",
					required: true,
				},
				{
					md: 6,
					xs: 12,
					fieldName: "multiplier_factor_error",
					inputType: "number",
					label: "Error del factor de liquidación externo",
					required: true,
				},
			],
		};
	};
