import {
	Change,
	Connection,
	NumberOptions,
	PowerTransformerUsage,
	PowerTransformerlocation,
	enumOptions,
	enumOptionsCapitalize,
} from "../../../../helpers";
import type { StepperFormData } from "../../../../models";

export const dataGeneralData: StepperFormData = {
	name: "general_data",
	title: "Información general",
	items: [
		{
			xs: 12,
			fieldName: "change",
			inputType: "checkbox-group",
			label: "Tipo de Novedad",
			options: enumOptionsCapitalize(Change),
		},
		{
			xs: 12,
			fieldName: "geolocalization",
			inputType: "geo",
			label: "Coordenadas",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "power_transformer_serial_number",
			inputType: "text",
			label: "Número de transformador de potencia",
		},
		{
			md: 6,
			xs: 12,
			fieldName: "voltage_level",
			inputType: "number",
			label: "Tensión de servicio (kV)",
			required: true,
			step: 0.001,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "power_capacity_contracted.value",
			inputType: "number",
			label: "Capacidad instalada (kVA)",
			required: true,
		},
		{
			xs: 12,
			fieldName: "power_capacity_contracted.picture_url",
			inputType: "file-image",
			label: "Capacidad instalada (kVA)",
			required: true,
		},
		{
			xl: 6,
			xs: 12,
			fieldName: "power_transformer_primary_voltage",
			inputType: "number",
			label: "Tensión primaria del transformador de potencia (kV)",
			required: true,
			step: 0.1,
		},
		{
			xl: 6,
			xs: 12,
			fieldName: "power_transformer_secundary_voltage",
			inputType: "number",
			label: "Tensión secundaria del transformador de potencia (V)",
			required: true,
			step: 0.001,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "power_transformer_phase_number",
			inputType: "select",
			label: "Número de fases del transformador de potencia",
			options: NumberOptions(3),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "power_transformer_usage",
			inputType: "select",
			label: "Uso del transformador de potencia",
			options: enumOptions(PowerTransformerUsage),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "power_transformer_node",
			inputType: "text",
			label: "Nodo transformador de potencia",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "power_transformer_location",
			inputType: "select",
			label: "Ubicación transformador de potencia",
			options: enumOptions(PowerTransformerlocation),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "connection",
			inputType: "select",
			label: "Conexión",
			options: enumOptions(Connection),
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "multiplier_factor",
			inputType: "number",
			label: "Factor liquidación externo",
			required: true,
			disabled: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: "capacitor_bank_capacity",
			inputType: "text",
			label: "Banco de condensadores",
		},
		{
			xs: 12,
			fieldName: "power_transformer_feature_plate_picture.picture_url",
			inputType: "file-image",
			label: "Placa de características del transformador de potencia",
			required: true,
		},
		{
			xs: 12,
			fieldName: "measurement_system_picture.picture_url",
			inputType: "file-image",
			label: "Panorámica del sistema de medida",
			required: true,
		},
	],
};
