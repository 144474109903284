import type { DynamicFieldData } from "../../../models";

export const dataPhasesTransformerForm = (
	newFieldName: string,
): DynamicFieldData[] => {
	return [
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.${newFieldName.includes("voltage") ? "primary_voltage" : "primary_current"}`,
			inputType: "number",
			label: "Corriente primaria (I)",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.${newFieldName.includes("voltage") ? "secundary_voltage" : "secundary_current"}`,
			inputType: "number",
			label: "Corriente secundaria (II)",
			required: true,
		},
		{
			md: 6,
			xs: 12,
			fieldName: `${newFieldName}.transformation_relationship`,
			inputType: "number",
			label: "Relación de transformación fase",
			required: true,
		},
	];
};
