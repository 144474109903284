import {
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	Select,
	Typography,
} from "@enerbit/base";
import { useEffect } from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { Asterisk } from "../../../../../common";
import type { DynamicFieldData } from "../../../../../models";
import { useAppSelector } from "../../../../../store";

export const ItemSelectActivity = ({
	lg,
	md,
	xs,
	fieldName,
	label,
	required,
	options,
	config = {},
}: DynamicFieldData) => {
	const {
		control,
		watch,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();
	const { loadingActivities } = useAppSelector(
		(state) => state.editInstallation,
	);

	useEffect(() => {
		const subscription = watch((_value, { name }) => {
			if (name) {
				if (fieldName.includes(name)) {
					const split = fieldName.split(".");
					const getCharge =
						split.slice(0, split.length - 1).join(".") + ".charge";
					if (name && options) {
						const value =
							options.find(({ label }) => label === watch(fieldName))?.value ||
							0;
						setValue(getCharge, value, { shouldValidate: true });
					}
					const total_charge =
						Math.floor(watch("summary.materials_charge")) +
						Math.floor(watch("summary.activities_charge"));
					setValue("summary.total_charge", total_charge, {
						shouldValidate: true,
					});
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, options, getValues(fieldName)]);

	return (
		<Grid item md={md} xs={xs} lg={lg}>
			<Typography className="title-input-edition">
				{label}
				{required && <Asterisk />}
			</Typography>
			<FormControl fullWidth>
				<Controller
					render={({ field }) => (
						<>
							<Select
								{...field}
								error={!!get(errors, fieldName)}
								disabled={loadingActivities}
								sx={{ "& fieldset": { borderRadius: "14px" } }}
							>
								{options &&
									options.map(({ label }, index) => (
										<MenuItem key={index} value={label}>
											{" "}
											{label}
										</MenuItem>
									))}
							</Select>
							{!!get(errors, fieldName) && (
								<FormHelperText error>
									{get(errors, fieldName).message}
								</FormHelperText>
							)}
						</>
					)}
					name={fieldName}
					control={control}
					rules={config}
				/>
			</FormControl>
		</Grid>
	);
};
