import * as yup from "yup";
import { schemaActivities } from "./schemaActivities";
import { schemaCurrentTransformerInstalled } from "./schemaCurrentTransformerInstalled";
import { schemaCurrentTransformerRetired } from "./schemaCurrentTransformerRetired";
import { schemaDiagrams } from "./schemaDiagrams";
import { schemaElectricalBoxesInstalled } from "./schemaElectricalBoxesInstalled";
import { schemaElectricalBoxesRetired } from "./schemaElectricalBoxesRetired";
import { schemaElectricalConductorsInstalled } from "./schemaElectricalConductorsInstalled";
import { schemaElectricalConductorsRetired } from "./schemaElectricalConductorsRetired";
import { schemaGeneralData } from "./schemaGeneralData";
import { schemaMainMeterComunicationsInstalled } from "./schemaMainMeterComunicationsInstalled";
import { schemaMainMeterComunicationsRetired } from "./schemaMainMeterComunicationsRetired";
import { schemaMainMeterInstalled } from "./schemaMainMeterInstalled";
import { schemaMainMeterRetired } from "./schemaMainMeterRetired";
import { schemaMaterials } from "./schemaMaterials";
import { schemaObservations } from "./schemaObservations";
import { schemaOptionalMeterComunicationsInstalled } from "./schemaOptionalMeterComunicationsInstalled";
import { schemaOptionalMeterComunicationsRetired } from "./schemaOptionalMeterComunicationsRetired";
import { schemaOptionalMeterIInstalled } from "./schemaOptionalMeterIInstalled";
import { schemaOptionalMeterRetired } from "./schemaOptionalMeterRetired";
import { schemaPhotographicAnnexes } from "./schemaPhotographicAnnexes";
import { schemaRequestTechnicalSupport } from "./schemaRequestTechnicalSupport";
import { schemaTerminalsInstalled } from "./schemaTerminalsInstalled";
import { schemaTerminalsRetired } from "./schemaTerminalsRetired";
import { schemaTransformersTechnicalTest } from "./schemaTransformersTechnicalTest";
import { schemaVoltageTransformerInstalled } from "./schemaVoltageTransformerInstalled";
import { schemaVoltageTransformerRetired } from "./schemaVoltageTransformerRetired";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const schema: any = yup.object().shape({
	request_technical_support: schemaRequestTechnicalSupport,
	general_data: schemaGeneralData,
	main_meter_installed: schemaMainMeterInstalled,
	main_meter_retired: schemaMainMeterRetired,
	optional_meter_installed: schemaOptionalMeterIInstalled,
	optional_meter_retired: schemaOptionalMeterRetired,
	electrical_boxes_installed: schemaElectricalBoxesInstalled,
	electrical_boxes_retired: schemaElectricalBoxesRetired,
	main_meter_comunications_installed: schemaMainMeterComunicationsInstalled,
	main_meter_comunications_retired: schemaMainMeterComunicationsRetired,
	optional_meter_comunications_installed:
		schemaOptionalMeterComunicationsInstalled,
	optional_meter_comunications_retired: schemaOptionalMeterComunicationsRetired,
	observations: schemaObservations,
	//meters_technical_test: schemaMetersTechnicalTest,
	activities: schemaActivities,
	materials: schemaMaterials,
	photographic_annexes: schemaPhotographicAnnexes,
	diagrams: schemaDiagrams,
	current_transformer_installed: schemaCurrentTransformerInstalled,
	current_transformer_retired: schemaCurrentTransformerRetired,
	transformers_technical_test: schemaTransformersTechnicalTest,
	electrical_conductors_installed: schemaElectricalConductorsInstalled,
	electrical_conductors_retired: schemaElectricalConductorsRetired,
	terminals_installed: schemaTerminalsInstalled,
	terminals_retired: schemaTerminalsRetired,
	voltage_transformer_installed: schemaVoltageTransformerInstalled,
	voltage_transformer_retired: schemaVoltageTransformerRetired,
});
