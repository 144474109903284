import {
	AddIcon,
	Box,
	Button,
	DeleteIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { dataTowersForm } from "../../../../../helpers/edition/services/dataTowersForm";
import type { DynamicFieldData } from "../../../../../models";
import { DeleteItemModal } from "../../Modals";
import { DynamicControl } from "../DynamicControl";

export const TowersForm = ({ xs, fieldName }: DynamicFieldData) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: fieldName,
	});

	const addTowers = () => {
		append({});
	};

	const [openDeleteTower, setOpenDeleteTower] = useState(false);
	const handleOpenDeleteTower = () => setOpenDeleteTower(true);
	const handleCloseDeleteTower = () => setOpenDeleteTower(false);
	const removeTower = (index: number) => {
		remove(index);
	};

	return (
		<Grid item container xs={xs} rowSpacing={3} columnSpacing={5}>
			{fields.map((item, index) => {
				const fieldsTower = dataTowersForm(`${fieldName}.${index}`);

				return (
					<Grid key={item.id} item container xs={12}>
						<Box sx={{ borderRadius: "16px", border: "1px solid #53358E" }}>
							<Grid item width="100%">
								<Box
									sx={{
										backgroundColor: "#53358E",
										borderRadius: "16px 16px 0px 0px",
										px: 4,
										py: 2,
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography sx={{ fontWeight: "bold", color: "white" }}>
										Torre {index + 1}
									</Typography>
									<Button
										variant="contained"
										color="error"
										onClick={handleOpenDeleteTower}
									>
										<DeleteIcon />
									</Button>
								</Box>
							</Grid>
							<Grid
								item
								container
								xs={xs}
								rowSpacing={3}
								columnSpacing={5}
								p={4}
							>
								{fieldsTower.map((d, i) => (
									<DynamicControl {...d} key={i.toString()} />
								))}
							</Grid>
						</Box>
						<DeleteItemModal
							open={openDeleteTower}
							handleClose={handleCloseDeleteTower}
							deleteItem={removeTower}
							index={index}
							name="la torre"
						/>
					</Grid>
				);
			})}
			<Grid display="grid" item xs={xs}>
				<Button onClick={addTowers} variant="outlined" startIcon={<AddIcon />}>
					Agregar torre
				</Button>
			</Grid>
		</Grid>
	);
};
